import { FormikProps } from "formik";
import { FC } from "react";
import { ITercero } from "../../../../interfaces/ITercero";
import { documentos, estadoCivil } from "../../datosFormularios/Informacion";
import { useInformacion } from "../../providers/InformacionProvider";
import { useTercero } from "../../../modules/Terceros/perfil/provider/TerceroProvider";
import { FormularioCampo } from "../../../../_metronic/partials/formulario/FormularioCampo";
import { Select } from "../../../../_metronic/partials/formulario/Select";
import { Input } from "../../../../_metronic/partials/formulario/Input";
import { DataList } from "../../../../_metronic/partials/formulario/DataList";
import { useAuth } from "../../../modules/Auth";
import { EnumRol } from "../../../../interfaces/enums/EnumRol";

interface TerceroInformacionPersonalProps {
    formik: FormikProps<ITercero>
}

export const TerceroInformacionPersonal: FC<TerceroInformacionPersonalProps> = ({
    formik
}) => {

    const { currentUser } = useAuth();
    const { actividadesEconomicas } = useInformacion();
    const { tercero } = useTercero();

    return (
        <div className='card mb-5'>
            <div
                className='card-header border-0 cursor-pointer m-0 d-flex justify-content-center align-items-center'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_crear_tercero_info_basica'
                aria-expanded='true'
                aria-controls='kt_crear_tercero_info_basica'
                style={{ minHeight: "50px" }}
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Datos personales</h3>
                </div>
            </div>
            <div id='kt_crear_tercero_info_basica' className='collapse show'>
                <div className='card-body border-top p-9'>
                    <div className="row row-cols-2">

                        <FormularioCampo nombre="Documento" requerido>
                            <div className="row row-cols-2 p-0">
                                <div className="col-3 p-0">
                                    <Select
                                        disabled={currentUser?.rolId != EnumRol.Administrador || (tercero) ? true : false}
                                        placeholder="Documento *"
                                        options={documentos}
                                        props={formik.getFieldProps('tipoDocumentoId')}
                                        validProp={formik.touched.tipoDocumentoId}
                                        error={formik.errors.tipoDocumentoId}
                                    />
                                </div>
                                <div className="col-9 p-0">
                                    <Input
                                        disabled={currentUser?.rolId != EnumRol.Administrador ||(tercero) ? true : false}
                                        placeholder={"Identificacion *"}
                                        props={formik.getFieldProps('identificacion')}
                                        validProp={formik.touched.identificacion}
                                        error={formik.errors.identificacion}
                                    />
                                </div>
                            </div>
                        </FormularioCampo>

                        <FormularioCampo nombre="Nombres" requerido>
                            <div className="row row-cols-2 p-0">
                                <div className="col p-0">
                                    <Input
                                        disabled={currentUser?.rolId != EnumRol.Administrador}
                                        autoCapitalLetter
                                        deleteSpaces
                                        placeholder={"Primer nombre *"}
                                        props={formik.getFieldProps('primerNombre')}
                                        validProp={formik.touched.primerNombre}
                                        error={formik.errors.primerNombre}
                                    />
                                </div>
                                <div className="col p-0">
                                    <Input
                                        disabled={currentUser?.rolId != EnumRol.Administrador}
                                        autoCapitalLetter
                                        placeholder={"Otros nombres"}
                                        props={formik.getFieldProps('otrosNombres')}
                                        validProp={formik.touched.otrosNombres}
                                        error={formik.errors.otrosNombres}
                                    />
                                </div>
                            </div>
                        </FormularioCampo>

                        <FormularioCampo nombre="Apellidos" requerido>
                            <div className="row row-cols-2 p-0">
                                <div className="col p-0">
                                    <Input
                                        disabled={currentUser?.rolId != EnumRol.Administrador}
                                        autoCapitalLetter
                                        placeholder={"Primer apellido *"}
                                        props={formik.getFieldProps('primerApellido')}
                                        validProp={formik.touched.primerApellido}
                                        error={formik.errors.primerApellido}
                                    />
                                </div>
                                <div className="col p-0">
                                    <Input
                                        disabled={currentUser?.rolId != EnumRol.Administrador}
                                        autoCapitalLetter
                                        placeholder={"Segundo apellido"}
                                        props={formik.getFieldProps('segundoApellido')}
                                        validProp={formik.touched.segundoApellido}
                                        error={formik.errors.segundoApellido}
                                    />
                                </div>
                            </div>
                        </FormularioCampo>

                        <FormularioCampo nombre="Nombre comercial">
                            <div className="row p-0">
                                <Input
                                    disabled={currentUser?.rolId != EnumRol.Administrador}
                                    autoCapitalLetter
                                    placeholder={"Nombre comercial"}
                                    props={formik.getFieldProps('nombreComercial')}
                                    validProp={formik.touched.nombreComercial}
                                    error={formik.errors.nombreComercial}
                                />
                            </div>
                        </FormularioCampo>

                        <FormularioCampo nombre="Profesión">
                            <div className="row row-cols-2 p-0">
                                <div className="col p-0">
                                    <Input
                                        disabled={currentUser?.rolId != EnumRol.Administrador}
                                        autoCapitalLetter
                                        placeholder={"Profesion"}
                                        props={formik.getFieldProps('profesion')}
                                        validProp={formik.touched.profesion}
                                        error={formik.errors.profesion}
                                    />
                                </div>
                                <div className="col p-0">
                                    <Input
                                        disabled={currentUser?.rolId != EnumRol.Administrador}
                                        autoCapitalLetter
                                        placeholder={"Empresa donde trabaja"}
                                        props={formik.getFieldProps('cargo')}
                                        validProp={formik.touched.cargo}
                                        error={formik.errors.cargo}
                                    />
                                </div>
                            </div>
                        </FormularioCampo>

                        <FormularioCampo nombre="Actividad económica">
                            <div className="row row-cols-2 p-0">
                                <DataList
                                    disabled={currentUser?.rolId != EnumRol.Administrador}
                                    placeholder="Actividad económica"
                                    defaultValue={tercero ? actividadesEconomicas.find(e => e.codigo == tercero.codigoActividad)?.descripcion : ""}
                                    options={actividadesEconomicas.map(a => ({
                                        nombre: a.descripcion,
                                        valor: a.codigo,
                                        opcion: `${a.codigo} - ${a.descripcion}`
                                    }))}
                                    setFieldValue={formik.setFieldValue}
                                    props={formik.getFieldProps('codigoActividad')}
                                    validProp={formik.touched.codigoActividad}
                                    error={formik.errors.codigoActividad}
                                />
                            </div>
                        </FormularioCampo>

                        <FormularioCampo nombre="Estado civil" requerido>
                            <div className="row row-cols-2 p-0">
                                <Select
                                    disabled={currentUser?.rolId != EnumRol.Administrador}
                                    placeholder="Estado civil *"
                                    options={estadoCivil}
                                    props={formik.getFieldProps('estadoCivilId')}
                                    validProp={formik.touched.estadoCivilId}
                                    error={formik.errors.estadoCivilId}
                                />
                            </div>
                        </FormularioCampo>

                    </div>
                </div>
            </div>
        </div>
    )
}