import { FC } from "react"

const btnStyle: React.CSSProperties = {
    width: "32px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    borderRadius: "5px 0px 0px 5px",
    //border: "1px solid green"
}

const inputStyle: React.CSSProperties = {
    border: "1px solid red",
    borderRadius: "5px"
}

interface InputInformativoProps {
    icon: string,
    nombre?: string
    text?: string | number,
    changed?: boolean
    disabled?: boolean
}

export const InputInformativo: FC<InputInformativoProps> = ({
    icon,
    text,
    changed,
    nombre,
    disabled = true
}) => {
    return (
        <div className='col'>
            {nombre && <label className='form-label fs-7 fw-bolder text-dark p-0 m-0'>{nombre}</label>}
            <div className="input-group mb-1" style={(changed) ? inputStyle : {}} >
                <div className="input-group-prepend">
                    <span style={btnStyle} className="input-group-text" id="basic-addon1">
                        <i className={`bi ${icon}`}></i>
                    </span>
                </div>
                <input 
                    disabled={disabled} 
                    style={{backgroundColor: "white", color: "gray"}}
                    value={text ?? ""} 
                    type="text" 
                    className="form-control form-control-sm bg-transparent" 
                    aria-label="Username" 
                    aria-describedby="basic-addon1" 
                />
            </div>
        </div>
    )
}