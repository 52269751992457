import { addressToPos } from "../addresToPos";

export const pos_nombre = "G5";
export const pos_documento = "G6";
export const pos_direccion = "G7";
export const pos_unidad = "G8";
export const pos_telefonos = "G9";
export const pos_correo = "G10";
export const pos_area_lote = "K5";
export const pos_interes_anual = "K6";
export const pos_interes_mora = "K7";
export const pos_cantidad_pagos = "K8";
export const pos_valor_a_financiar = "K9";

export const pos_pagina = addressToPos("D33");
export const pos_fecha = addressToPos("F33");
export const pos_hora = addressToPos("J33");
export const pos_reporte = addressToPos("L33");

export const por_valor_total = addressToPos("L27");
export const pos_valor_financiar = addressToPos("L28");
export const pos_total_recibido = addressToPos("L29");
export const pos_cuotas_mora = addressToPos("L30");
export const pos_saldo_final = addressToPos("L31");

export const pos_header = {
    from: {
        x: 1, y: 1
    },
    to: {
        x: 16, y: 11
    }
};

export const pos_footer = {
    from: {
        x: 1, y: 26
    },
    to: {
        x: 16, y: 34
    }
};

export const pos_pi_col = {
    from: {
        x: 1, y: 12
    },
    to: {
        x: 16, y: 13
    }
};

export const pos_pn_col = {
    from: {
        x: 1, y: 16
    },
    to: {
        x: 16, y: 17
    }
};

export const pos_mv_col = {
    from: {
        x: 1, y: 21
    },
    to: {
        x: 16, y: 22
    }
};

export const pos_pi_lista_1 = {
    from: {
        x: 1, y: 14
    },
    to: {
        x: 16, y: 14
    }
};

export const pos_pi_lista_2 = {
    from: {
        x: 1, y: 15
    },
    to: {
        x: 16, y: 15
    }
};

export const pos_pn_lista_1 = {
    from: {
        x: 1, y: 18
    },
    to: {
        x: 16, y: 18
    }
};

export const pos_pn_lista_2 = {
    from: {
        x: 1, y: 19
    },
    to: {
        x: 16, y: 19
    }
};

export const pos_mv_lista_1 = {
    from: {
        x: 1, y: 23
    },
    to: {
        x: 16, y: 23
    }
};

export const pos_mv_lista_2 = {
    from: {
        x: 1, y: 24
    },
    to: {
        x: 16, y: 24
    }
};

export const pos_pago_actual_lista = {
    from: {
        x: 1, y: 20
    },
    to: {
        x: 16, y: 20
    }
};

