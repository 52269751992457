import { FC, MouseEventHandler } from "react";
import { useLayout } from "../../../_metronic/layout/core";
import clsx from "clsx";

interface BotonFiltroProps {
    onClick?: MouseEventHandler<HTMLButtonElement>
}

export const BotonFiltro: FC<BotonFiltroProps> = ({
    onClick
}) => {
    const { config } = useLayout()
    const daterangepickerButtonClass = config.app?.toolbar?.fixed?.desktop
        ? 'btn-light'
        : 'bg-body btn-color-gray-700 btn-active-color-primary'
    return (
        <button
            className={clsx('btn btn-sm btn-flex fw-bold', daterangepickerButtonClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
        >
            <i className="fs-3 text-muted me-1 bi bi-funnel-fill"></i>
            Filtros
        </button>
    )
}
