import { useCallback, useEffect, useMemo, useState } from "react"
import { ICuota } from "../../../../../../../interfaces/cuotas/ICuota";
import * as alertify from "alertifyjs";
import date from "date-and-time";
import { Propiedad } from "../providers/PropiedadProvider";
import { daysBetween } from "../../../../../../../utils/dateDiff";
import { PeriodoEnum } from "../../../../../../shared/datosFormularios/Informacion";
import { CalcularCuota } from "../../../../../../../utils/calculos/CalcularCuota";
import { EstadoPagoEnum } from "../../../../../../../interfaces/enums/EnumEstadoPago";
import { EnumTipoPago } from "../../../../../../../interfaces/enums/EnumTipoPago";
import { IPago } from "../../../../../../../interfaces/pago/IPago";
import { CalcularMora } from "../../../../../../../utils/calculos/CalcularMora";

interface propiedadExtend extends Propiedad {
    periodoId: number
}

export interface IPagoColumna {
    cuotaId: string
    numeroCuota: number
    fecha: Date
    cuota: number
    interes: number
    capital: number
    abono: number
    mora: number
    saldo: number
    estadoPagoId: number
    tipoPagoId: number
    className?: string
    pagos: IPago[]
}

type usePagoProps = (
    propiedad: Propiedad,
    cuotasPagoNormal: ICuota[],
    cuotasPagadas: ICuota[],
    fechaInicioFinanciacion: string,
    periodoId: number,
    pagosAbonos: IPago[],
    setPeriodo: () => void
) => {
    cuotas: IPagoColumna[],
    actualizarFechaCuotaNormal: (numeroCuota: number, fecha: Date) => void
}

export const usePagos: usePagoProps = (
    propiedad,
    cuotasPagoNormal,
    cuotasPagadas,
    fechaInicioFinanciacion,
    periodoId,
    pagosAbonos,
    setPeriodo
) => {

    const [planPagoDb, setPlanPagoDb] = useState<IPagoColumna[]>([]);
    const [planPagos, setPlanPagos] = useState<IPagoColumna[]>([]);

    const fechaInicial = useMemo(() => 
        fechaInicioFinanciacion == "" 
        ? new Date 
        : date.parse(fechaInicioFinanciacion, "YYYY-MM-DD"), 
    [fechaInicioFinanciacion]);

    // Cantidad de dias del tipo de periodo
    const cantDiasPorPago: number = useMemo(() => {
        switch (periodoId) {
            case PeriodoEnum.Mensual:
                return 30;
            case PeriodoEnum.Bimensual:
                return (30 * 2);
            case PeriodoEnum.Trimestral:
                return (30 * 3);
            case PeriodoEnum.Cuatrimestral:
                return (30 * 4);
            case PeriodoEnum.Semestreal:
                return (30 * 6);
            default:
                return 30;
        }
    }, [periodoId]);

    const totalAbonos = useMemo(() => pagosAbonos.reduce((p, c) => p + c.valor, 0), [pagosAbonos])

    const proxCuota = useMemo(() => {
        let proxCuota: ICuota | null = null;
        for(let i=0;i<cuotasPagoNormal.length;i++){
            if(cuotasPagoNormal[i].estadoCuotaId == EstadoPagoEnum.pendiente){
                proxCuota = cuotasPagoNormal[i];
                break;
            }
        }
        return proxCuota;
    }, [cuotasPagoNormal]);

    const totalAbonos2 = useMemo(() => {
        if(cuotasPagadas.length == 0) return totalAbonos
        for(let i=0;i<cuotasPagoNormal.length;i++){
            if(cuotasPagoNormal[i].estadoCuotaId == EstadoPagoEnum.pendiente){
                if(cuotasPagoNormal[i-1]){
                    var abono = (cuotasPagoNormal[i-1].saldo - cuotasPagoNormal[i].capital) - cuotasPagoNormal[i].saldo;
                    return abono;
                }
            }
        }
        return 0;
    }, [totalAbonos, cuotasPagoNormal, cuotasPagadas]);

    // Cargar el pago inicial guardado en la db
    useEffect(() => {
        const planPagos: IPagoColumna[] = [];

        cuotasPagoNormal.forEach((cuota, i) => {
            planPagos.push({
                cuotaId:     cuota.id,
                numeroCuota:  i + 1,
                fecha:        cuota.fecha,
                cuota:        cuota.cuota,
                interes:      cuota.interes,
                capital:      cuota.capital,
                abono:        cuota.abono,
                mora:         cuota.mora,
                saldo:        cuota.saldo,
                estadoPagoId: cuota.estadoCuotaId,
                tipoPagoId:   cuota.tipoPagoId,
                pagos:        cuota.pagos ?? []
            });
        });

        if(planPagos[cuotasPagadas.length]){
            planPagos[cuotasPagadas.length].className = "table-primary";
        }
        
        setPlanPagos([...planPagos]);
        setPlanPagoDb([...planPagos]);
    }, [
        cuotasPagadas,
        cuotasPagoNormal
    ]);

    const [_propiedad, setPropiedad] = useState<propiedadExtend | null>(null);

    useEffect(() => {
        setPropiedad({...propiedad, periodoId});
    }, [propiedad, periodoId]);

    useEffect(() => {
        const {
            cantidadPagos,
            interesDiario,
            porcentajeMora,
            valorFinanciar
        } = propiedad;
        
        if(_propiedad?.periodoId != PeriodoEnum.Abierto && periodoId == PeriodoEnum.Abierto) return;
        if(planPagoDb.length == 0 && cantidadPagos == 0) return;
        if(JSON.stringify({..._propiedad, periodoId}) == JSON.stringify(propiedad)) return;

        const currentDate = date.parse(new Date().formato(), "YYYY-MM-DD");

        const planPagos: IPagoColumna[] = [];

        // Agregar cuotas pagadas
        cuotasPagadas.forEach((cuota, i) => {
            planPagos.push({
                cuotaId:     cuota.id,
                numeroCuota:  i + 1,
                fecha:        cuota.fecha,
                cuota:        cuota.cuota,
                interes:      cuota.interes,
                capital:      cuota.capital,
                abono:        cuota.abono,
                mora:         cuota.mora,
                saldo:        cuota.saldo,
                estadoPagoId: cuota.estadoCuotaId,
                tipoPagoId:   cuota.tipoPagoId,
                pagos:        cuota.pagos ?? []
            });
        });

        let fecha = planPagos[planPagos.length - 1]?.fecha ?? fechaInicial;
        
        let saldoAcumulado = (planPagos[planPagos.length - 1]?.saldo ?? valorFinanciar) - totalAbonos2;
        
        let numeroCuota = planPagos.length; 
        
        // Agregar cuota actual
        for(let i=cuotasPagadas.length; i < cantidadPagos;i++){
            const fechaAnterior = fecha;
            const fechaActual   = (periodoId == PeriodoEnum.Abierto && planPagoDb[i]) ? planPagoDb[i].fecha : fechaAnterior.addDays(cantDiasPorPago);

            const diasIntereses = daysBetween(fechaAnterior, fechaActual);
            
            let cuota         = CalcularCuota(saldoAcumulado, interesDiario * diasIntereses, cantidadPagos - i);
            let interes       = saldoAcumulado * (interesDiario * diasIntereses);
            let capital       = cuota - interes;
            /*
            if(i==cuotasPagadas.length && proxCuota){
                var abonos = (saldoAcumulado - capital) - proxCuota.saldo;
                if(abonos > 0){
                    saldoAcumulado -= abonos;
                    cuota         = CalcularCuota(saldoAcumulado, interesDiario * diasIntereses, cantidadPagos - i);
                    interes       = saldoAcumulado * (interesDiario * diasIntereses);
                    capital       = cuota - interes;
                }
            }
            */
            const diasMora      = Math.floor(daysBetween(currentDate, fechaActual));
            const mora          = CalcularMora(diasMora, capital, porcentajeMora);
            const pagos         = planPagoDb[i]?.pagos ?? [];

            saldoAcumulado -= capital;

            numeroCuota += 1;

            planPagos.push({
                cuotaId:      "",
                numeroCuota:  numeroCuota,
                fecha:        fechaActual,
                cuota:        cuota,
                interes:      interes,
                capital:      capital,
                abono:        0,
                mora:         mora,
                saldo:        saldoAcumulado,
                estadoPagoId: EstadoPagoEnum.pendiente,
                tipoPagoId:   EnumTipoPago.cuota,
                pagos:        pagos
            });
            fecha = fechaActual;
        }

        if(planPagos[cuotasPagadas.length]){
            planPagos[cuotasPagadas.length].className = "table-primary";
        }
        
        setPlanPagos([...planPagos]);
    }, [
        planPagoDb,
        totalAbonos2,
        fechaInicial,
        propiedad.valorFinanciar,
        propiedad.cantidadPagos,
        propiedad.interesDiario,
        propiedad.porcentajeMora,
        cantDiasPorPago,
        cuotasPagadas,
        periodoId,
        proxCuota
    ]);
    
    // Funcion para actualizar una fecha de las cuotas normales
    const actualizarFechaCuotaNormal = useCallback((numeroCuota: number, fecha: Date) => {
        
        const {
            interesDiario,
            valorFinanciar,
            cantidadPagos,
            porcentajeMora
        } = propiedad;

        const currentDate = date.parse(new Date().formato(), "YYYY-MM-DD");

        const cuotaActual    = planPagos[numeroCuota];
        const cuotaAnterior  = planPagos[numeroCuota - 1];
        const cuotaSiguiente = planPagos[numeroCuota + 1];

        let fechaAnterior  = cuotaAnterior?.fecha;
        let fechaSiguiente = cuotaSiguiente?.fecha;

        if(!cuotaActual){
            alertify.error(`La cuota no existe`);
            return;
        }
        
        if (fechaInicial.getTime() > fecha.getTime()){
            alertify.error(`La fecha no puede ser menor a la fecha de inicio de financiación`);
            fecha = fechaInicial.addDays(1);
        }

        if (fechaAnterior && fechaAnterior.getTime() > fecha.getTime()) {
            alertify.error(`La fecha de pago (#${numeroCuota + 1}) no puede ser menor a la fecha del pago anterior (#${numeroCuota + 2})`);
            fecha = fechaAnterior.addDays(1);
        }

        if (fechaSiguiente && fechaSiguiente.getTime() < fecha.getTime()) {
            alertify.error(`La fecha de pago (#${numeroCuota + 1}) no puede ser mayor a la fecha del pago siguiente (#${numeroCuota + 2})`);
            fecha = fechaSiguiente.restDays(1);
        }
        
        setPeriodo();

        fechaAnterior = fechaAnterior ?? fechaInicial;

        

        let saldoAcumulado  = cuotaAnterior ? cuotaAnterior.saldo : valorFinanciar;
        if(numeroCuota == cuotasPagadas.length) {
            saldoAcumulado -= totalAbonos2    
        }

        const diasIntereses = daysBetween(fechaAnterior, fecha);
        const cuota         = CalcularCuota(saldoAcumulado, interesDiario * diasIntereses, cantidadPagos - numeroCuota);
        const interes       = saldoAcumulado * (interesDiario * diasIntereses);
        const capital       = cuota - interes;

        const diasMora      = Math.floor(daysBetween(currentDate, fecha));
        const mora          = CalcularMora(diasMora, capital, porcentajeMora);
        saldoAcumulado      -= capital;

        cuotaActual.fecha   = fecha;
        cuotaActual.cuota   = cuota;
        cuotaActual.interes = interes;
        cuotaActual.capital = capital;
        cuotaActual.saldo   = saldoAcumulado;
        cuotaActual.mora    = mora;

        if(cuotaSiguiente){
            const diasIntereses = daysBetween(fecha, cuotaSiguiente.fecha);
            const cuota         = CalcularCuota(saldoAcumulado, interesDiario * diasIntereses, cantidadPagos - numeroCuota - 1);
            const interes       = saldoAcumulado * (interesDiario * diasIntereses);
            const capital       = cuota - interes;
            const diasMora      = Math.floor(daysBetween(currentDate, cuotaSiguiente.fecha));
            const mora          = CalcularMora(diasMora, capital, porcentajeMora);
            saldoAcumulado      -= capital;

            cuotaSiguiente.cuota   = cuota;
            cuotaSiguiente.interes = interes;
            cuotaSiguiente.capital = capital;
            cuotaSiguiente.saldo   = saldoAcumulado;
            cuotaSiguiente.mora    = mora;
        }

        setPlanPagos(planPagos => {
            if(planPagos[numeroCuota]) {
                planPagos[numeroCuota] = {...cuotaActual};
            }
            if(planPagos[numeroCuota + 1]){
                planPagos[numeroCuota + 1] = {...cuotaSiguiente};
            }
            return [...planPagos];
        });

    }, [
        cuotasPagadas,
        totalAbonos2,
        propiedad.valorFinanciar,
        propiedad.interesDiario,
        propiedad.cantidadPagos,
        propiedad.porcentajeMora,
        fechaInicial,
        planPagos,
        fechaInicioFinanciacion,
        periodoId
    ]);

    return {
        cuotas: planPagos,
        actualizarFechaCuotaNormal
    };
}