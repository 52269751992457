import { FC, useEffect, useState } from "react";
import { ListViewProvider } from "../../../shared/providers/ListView/ListView";
import { ITercero } from "../../../../interfaces/ITercero";
import { TercerosServicios } from "../../../../servicios/tercerosServicios";
import { TerceroManagamentBody } from "./components/body/TerceroManagamentBody";
import { TerceroManagamentHeader } from "./components/header/TerceroManagamentHeader";
import { KTCard } from "../../../../_metronic/helpers";
import { InformacionProvider } from "../../../shared/providers/InformacionProvider";

export const TerceroManagament: FC = () => {

    const [terceros, setTerceros] = useState<ITercero[]>([]);

    useEffect(() => {
        const obtenerTerceros = async () => {
            const _terceros = await TercerosServicios.ObtenerTodos();
            setTerceros(_terceros);
        }
        obtenerTerceros();
    }, []);

    return (
        <KTCard>
            <InformacionProvider>
                <ListViewProvider initialData={terceros} >
                    <TerceroManagamentHeader />
                    <TerceroManagamentBody />
                </ListViewProvider>
            </InformacionProvider>
        </KTCard>
    )
}
