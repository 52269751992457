import { FC, createContext, useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { WithChildren } from "../../../../../_metronic/helpers";
import { IATercero } from "../../../../../interfaces/auditorias/IATercero";
import { IAAnterior } from "../../../../../interfaces/auditorias/IAAnterior";
import { AuditoriaServicio } from "../../../../../servicios/auditoriaServicio";
import { InfoAuditoriaTercero } from "../utils/InfoTercero";
import { useNavigate } from "react-router-dom";

type showModalType = (show: boolean, idAutiroria: string) => void
interface ModalAuditoriaTPros {
    showModal: showModalType
}

const ModalAuditoriaTIncial: ModalAuditoriaTPros = {
    showModal: () => { }
}

const ModalAuditoriaTContext = createContext<ModalAuditoriaTPros>(ModalAuditoriaTIncial)

const ModalAuditoriaTProvider: FC<WithChildren> = ({
    children
}) => {
    const [show, setShow] = useState(false);
    const [aTercero, setATercero] = useState<IAAnterior<IATercero> | null>(null);

    const obtenerAuditoria = async (idAutiroria: string) => {
        const auditoria = await AuditoriaServicio.AuditoriaTerceroAnterior(idAutiroria);
        setATercero(auditoria);
    }

    const showModal: showModalType = (show, idAutiroria) => {
        setShow(show);
        obtenerAuditoria(idAutiroria);
    }

    return (
        <ModalAuditoriaTContext.Provider value={{
            showModal
        }}>
            {children}
            {
                show &&
                <Modal show={show} className={`fade modal ${aTercero?.actual?.aTipoAccion === "Insertar" ? "modal-lg" : "modal-xl"}`}>
                    <form
                        className="form"
                        noValidate
                    >
                        <Modal.Header className="p-4">
                            {aTercero === null ? (
                                <div data-kt-indicator='on'>
                                    <span className='indicator-progress'>
                                        <span className='spinner-border spinner-border-sm align-middle'></span>
                                    </span>
                                </div>
                            ) : (
                                <h2 className="modal-title">
                                    {aTercero?.actual?.primerNombre} {aTercero?.actual?.otrosNombres} {aTercero?.actual?.primerApellido} {aTercero?.actual?.segundoApellido}
                                </h2>
                            )}
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                aria-label="Close"
                                onClick={() => setShow(false)}
                            >
                                <i className="fs-2 bi bi-x-lg"/>
                            </div>
                        </Modal.Header>
                        <Modal.Body className="bg-gray-300 px-6 py-4">
                            {/* begin::modal */}
                            
                            <div className="row g-10">
                            {
                                    (aTercero?.actual?.aTipoAccion === "Insertar") ? (
                                        <div className="col-12">
                                            <div className="row p-4 row-cols-1 bg-light border rounded-3">
                                                <div className="col-12 p-0 px-5 rounded mb-2">
                                                    <h1 className="align-middle">Insertado</h1>
                                                </div>
                                                <div className="row row-cols-2">
                                                    <InfoAuditoriaTercero
                                                        main={aTercero?.actual}
                                                        comparar={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="col-6">
                                                <div className="row p-4 row-cols-1 bg-light border rounded-3">
                                                    <div className="col-12 p-0 px-5 rounded mb-2">
                                                        <h1 className="align-middle">Anterior</h1>
                                                    </div>
                                                    <div className="row row-cols-2">
                                                        <InfoAuditoriaTercero
                                                            main={aTercero?.anterior}
                                                            target={aTercero?.actual}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-6">
                                                <div className="row p-4 row-cols-1 bg-light border rounded-3">
                                                    <div className="col-12 p-0 px-5 rounded mb-2">
                                                        <h1 className="align-middle">Nuevo</h1>
                                                    </div>
                                                    <div className="row row-cols-2">
                                                        <InfoAuditoriaTercero
                                                            main={aTercero?.actual}
                                                            target={aTercero?.anterior}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>

                                    )
                                }
                            </div>
                            {/* end::modal */}
                        </Modal.Body>
                        <Modal.Footer className="p-4">

                        </Modal.Footer>
                    </form>
                </Modal >
            }
        </ModalAuditoriaTContext.Provider>
    )
}

const useModalAuditoriaT = () => useContext(ModalAuditoriaTContext);

export {
    ModalAuditoriaTProvider,
    useModalAuditoriaT
}
