import { FC } from "react";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import { IUsuario } from "../../../interfaces/IUsuario";
import { UsuarioServicios } from "../../../servicios/usuarioServicio";
import { UsuarioCrearSchema } from "../../../validators/usuarioValidator";
import * as alertify from 'alertifyjs';
import { roles } from "../datosFormularios/Informacion";
import { useListView } from "../providers/ListView/ListView";
import { Input } from "../../../_metronic/partials/formulario/Input";
import { Select } from "../../../_metronic/partials/formulario/Select";

interface UsuarioModeloCrear extends IUsuario {
    confirmarContrasena: string
}

const usuarioInitialData: Partial<UsuarioModeloCrear> = {
    identificacion: "",
    nombre: "",
    correo: "",
    rolId: 1,
    contrasena: "",
    confirmarContrasena: ""
}

interface ModalCrearUsuarioProps {
    show: boolean
    showModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const ModalCrearUsuario: FC<ModalCrearUsuarioProps> = ({
    show,
    showModal
}) => {

    const { setData } = useListView();

    const formik = useFormik({
        initialValues: usuarioInitialData,
        validationSchema: UsuarioCrearSchema,
        onSubmit: async (values, { setErrors }) => {
            try {
                if (values.contrasena != values.confirmarContrasena) {
                    return setErrors({ "confirmarContrasena": "Las contraseñas no coinciden" });
                }
                await UsuarioServicios.Crear({
                    nombre: values.nombre,
                    identificacion: values.identificacion,
                    correo: values.correo,
                    contrasena: values.contrasena,
                    rolId: values.rolId
                });
                const users = await UsuarioServicios.ObtenerTodos(); 
                setData([...users]);
                alertify.success("Usuario guardado");
                showModal(false);
            } catch (err) {
                alertify.error("No se pudo guardar el usuario");
            }
        }
    });

    return (
        <Modal show={show} className="fade modal">
            <form
                className="form"
                onSubmit={formik.handleSubmit}
                noValidate
            >
                <Modal.Header className="p-4">
                    <h2 className="modal-title">CREAR USUARIO</h2>
                    <div
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                        aria-label="Close"
                        onClick={() => showModal(false)}
                    >
                        <i className="fs-2 bi bi-x-lg" />
                    </div>
                </Modal.Header>
                <Modal.Body className="bg-gray-300 px-6 py-4">
                    {/* begin::modal */}

                    <div className="row p-4 row-cols-1 bg-light border rounded-3">
                        <div className="col-12 p-0 px-5 rounded mb-2">
                            <h1 className="align-middle">Datos Usuario</h1>
                        </div>
                        <Input
                            type="email"
                            nombre={"Identificacion"}
                            requerido
                            props={formik.getFieldProps('identificacion')}
                            validProp={formik.touched.identificacion}
                            error={formik.errors.identificacion}
                        />
                        <Input
                            nombre={"Nombres"}
                            requerido
                            props={formik.getFieldProps('nombre')}
                            validProp={formik.touched.nombre}
                            error={formik.errors.nombre}
                        />
                        <Input
                            nombre={"Correo"}
                            requerido
                            props={formik.getFieldProps('correo')}
                            validProp={formik.touched.correo}
                            error={formik.errors.correo}
                        />
                        <Select
                            options={roles}
                            nombre={"Rol"}
                            requerido
                            props={formik.getFieldProps('rolId')}
                            validProp={formik.touched.rolId}
                            error={formik.errors.rolId}
                        />
                        <Input
                            type="password"
                            nombre={"Contraseña"}
                            requerido
                            props={formik.getFieldProps('contrasena')}
                            validProp={formik.touched.contrasena}
                            error={formik.errors.contrasena}
                        />
                        <Input
                            type="password"
                            nombre={"Confirmar Contraseña"}
                            requerido
                            props={formik.getFieldProps('confirmarContrasena')}
                            validProp={formik.touched.confirmarContrasena}
                            error={formik.errors.confirmarContrasena}
                        />
                    </div>
                    {/* end::modal */}
                </Modal.Body>
                <Modal.Footer className="p-4">
                    <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => showModal(false)}
                    >
                        Cancelar
                    </button>
                    <button type="submit" className="btn btn-primary">
                        Crear Usuario
                    </button>
                </Modal.Footer>
            </form>
        </Modal >
    )
}