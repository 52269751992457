/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useMemo, useState } from 'react'
import { usePropiedad } from '../providers/PropiedadProvider'
import { useTercero } from '../../../provider/TerceroProvider'
import { useNavigate } from 'react-router-dom'
import date from "date-and-time"
import { EstadoPagoEnum } from '../../../../../../../interfaces/enums/EnumEstadoPago'
import { IPagoColumna } from '../hooks/usePagos'
import { useEscribiendo } from '../../../../../../shared/hooks/useEscribiendo'
import * as alertify from "alertifyjs";
import { useAuth } from '../../../../../Auth'
import { EnumRol } from '../../../../../../../interfaces/enums/EnumRol'
import { IPago } from '../../../../../../../interfaces/pago/IPago'

type Props = {
    className?: string
}

export const TerceroPagoLista: React.FC<Props> = ({
    className = ""
}) => {

    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const { tercero } = useTercero();
    const { formik, planPagos, propiedad } = usePropiedad();

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Plan de pagos</span>
                </h3>
                {
                    (currentUser?.rolId == EnumRol.Administrador) && (
                        <button
                            type='button'
                            className='btn btn-sm btn-light-primary'
                            style={{ maxHeight: "40px" }}
                            onClick={() => navigate(`/clientes/profile/pagos/?idCliente=${tercero?.id}&propiedadTerceroId=${formik.values.id}&pagar=1`)}
                        >
                            <i className="fs-2 bi bi-cash-coin"></i>
                            Agregar Pago
                        </button>
                    )
                }
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                        {/* begin::Table head */}
                        <thead>
                            <tr className='fw-bold text-muted'>
                                <th style={{ width: "10px" }}></th>
                                <th style={{ width: "10px" }}>No</th>
                                <th className='min-w-100px' style={{ textAlign: "center" }}>Fecha</th>
                                <th className='min-w-100px'>Cuota Mensual</th>
                                <th className='min-w-100px'>Interes</th>
                                <th className='min-w-100px'>Capital</th>
                                <th className='min-w-100px'>Abono</th>
                                <th className='min-w-100px'>Mora</th>
                                <th className='min-w-100px'>Saldo</th>
                            </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody style={{ fontSize: "12px" }}>
                            <tr>
                                <th></th>
                                <th>
                                    <span className='text-dark fw-bold text-hover-primary' style={{ cursor: "default" }}>
                                        0
                                    </span>
                                </th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>
                                    <span className='text-dark fw-bold text-hover-primary' style={{ cursor: "default" }}>
                                        {propiedad.valorFinanciar.formatoMoneda2(true)}
                                    </span>
                                </th>
                            </tr>
                            {
                                planPagos.map((pago, i) => <TerceroPagoRow key={i} pago={pago} i={i} />)
                            }
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
            </div>
            {/* begin::Body */}
        </div>
    )
}

interface TerceroPagoRowProp {
    pago: IPagoColumna,
    i: number
}

const TerceroPagoRow: FC<TerceroPagoRowProp> = ({
    pago, i
}) => {

    const [cambiar, setCambiar] = useState(false);
    const [dateCuota, setDateCuota] = useState<string>("");
    const { actualizarFechaCuotaNormal } = usePropiedad();


    useEffect(() => {
        setDateCuota(pago.fecha.formato());
    }, [pago.fecha]);

    const { inputRef, escribiendo } = useEscribiendo(1500);

    useEffect(() => {
        if (!inputRef.current) return;
        const current = inputRef.current;

        if (!escribiendo && cambiar) {
            const fecha = date.parse(current.value, "YYYY-MM-DD");
            if (isNaN(fecha.getTime()) || fecha.getFullYear() < 1000) {
                alertify.error(`La fecha de pago (# ${pago.numeroCuota}) es incorrecta`);
                return;
            }
            actualizarFechaCuotaNormal(i, fecha);
        }
    }, [inputRef, escribiendo]);

    const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setDateCuota(e.target.value);
        setCambiar(true);
    }

    const totalPagos = useMemo(() => {
        if (pago.estadoPagoId == EstadoPagoEnum.pendiente) return pago.pagos.reduce((p, c) => p + ((c.estadoPagoId == EstadoPagoEnum.pagado) ? c.valor : 0), 0);
        else return 0;
    }, [pago]);

    const [hiddenPagos, setHiddenPagos] = useState(true);

    return (
        <tr key={i} className={pago.className ?? ""}>
            <td></td>
            <td>
                <span className='text-dark fw-bold text-hover-primary' style={{ cursor: "default" }}>
                    {pago.numeroCuota}
                </span>
            </td>
            <td className='d-flex flex-column justify-content-center align-items-center' style={{ height: "42px" }}>
                {
                    (pago.estadoPagoId == EstadoPagoEnum.pendiente) ? (
                        <input
                            ref={inputRef}
                            style={{ maxWidth: "100px", fontSize: "10px" }}
                            className='form-control form-control-sm m-0'
                            type={"date"}
                            autoComplete='off'
                            placeholder={"Fecha"}
                            value={dateCuota}
                            onChange={onChange}
                        />
                    ) : (
                        <span className='text-dark fw-bold text-hover-primary' style={{ cursor: "default" }}>
                            {date.format(pago.fecha, "DD/MM/YYYY")}
                        </span>
                    )
                }
            </td>
            <td
                onMouseEnter={() => setHiddenPagos(false)}
                onMouseLeave={() => setHiddenPagos(true)}
                style={{ cursor: "default" }}
            >
                <span className={`${hiddenPagos ? "text-dark" : "text-primary"} fw-bold`}>
                    {(pago.cuota - totalPagos + pago.mora).formatoMoneda2(true)}
                </span>
            </td>
            <td>
                <span className='text-dark fw-bold text-hover-primary' style={{ cursor: "default" }}>
                    {pago.interes.formatoMoneda2(true)}
                </span>
            </td>
            <td>
                <span className='text-dark fw-bold text-hover-primary' style={{ cursor: "default" }}>
                    {pago.capital.formatoMoneda2(true)}
                </span>
            </td>
            <td>
                <span className='text-dark fw-bold text-hover-primary' style={{ cursor: "default" }}>
                    {pago.abono.formatoMoneda2(true)}
                </span>
            </td>
            <td>
                <span className={`${(pago.estadoPagoId != EstadoPagoEnum.pagado && pago.mora > 0) ? "text-danger" : "text-dark"} fw-bold text-hover-primary`} style={{ cursor: "default" }}>
                    {pago.mora.formatoMoneda2(true)}
                </span>
            </td>
            <td>
                <span className='text-dark fw-bold text-hover-primary' style={{ cursor: "default" }}>
                    {pago.saldo.formatoMoneda2(true)}
                </span>
            </td>
        </tr >
    )
}

type ListaPagosProps = {
    pagos: IPago[]
}

const ListaPagos: FC<ListaPagosProps> = ({
    pagos
}) => {

    return (
        <ul className="list-group options-list pago-ul hide-scroll border border-2 rounded">
            {
                pagos.map((pago, i) => (
                    <li
                        key={i}
                        className="list-group-item pe-auto pago-li"
                    >
                        <span
                            className='text-gray-500'
                        >
                            {pago.valor.formatoMoneda2(true)}
                        </span>
                    </li>
                ))
            }
        </ul>
    )
}

