import { FC } from "react";
import { KTIcon } from "../../../../_metronic/helpers"
import { useListView } from "./ListView";

interface BuscarComponentProps {
  placeholder: string
}

export const BuscarComponent: FC<BuscarComponentProps> = ({
  placeholder
}) => {

  const { textFilter, setTextFilter } = useListView();

  return (
    <div className='card-title'>
      <div className='d-flex align-items-center position-relative my-1'>
        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-250px ps-14'
          placeholder={placeholder}
          value={textFilter}
          onChange={(e) => setTextFilter(e.target.value)}
        />
      </div>
    </div>
  )
}