import { FC } from "react"
import { Navigate, Outlet, Route, Routes } from "react-router-dom"
import { TerceroCuentaHeader } from "./TerceroCuentaHeader"
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import { TerceroActualizarPagina } from "./paginas/perfil/TerceroActualizarPagina"
import { TerceroPagosPagina } from "./paginas/propiedad/TerceroPagosPagina"
import { Pagos } from "./paginas/pagos/Pagos"

const accountBreadCrumbs: Array<PageLink> = [
    {
        title: 'Perfil',
        path: '/clientes/profile/informacion',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

export const TerceroCuentaRouter: FC = () => {
    return (
        <Routes>
            <Route
                element={
                    <>
                        <TerceroCuentaHeader />
                        <Outlet />
                    </>
                }
            >
                <Route
                    path='pagos'
                    element={
                        <>
                            <PageTitle breadcrumbs={accountBreadCrumbs}>Pagos</PageTitle>
                            <Pagos />
                        </>
                    }
                />
                <Route
                    path='perfil'
                    element={
                        <>
                            <PageTitle breadcrumbs={accountBreadCrumbs}>Perfil</PageTitle>
                            <TerceroActualizarPagina />
                        </>
                    }
                />
                <Route
                    path='cartera'
                    element={
                        <>
                            <PageTitle breadcrumbs={accountBreadCrumbs}>Cartera</PageTitle>
                            <TerceroPagosPagina />
                        </>
                    }
                />
                <Route index element={<Navigate to='/clientes/profile/informacion' />} />
            </Route>
        </Routes>
    )
}