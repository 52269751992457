import { FC, useMemo, useState } from "react";
import { usePropiedad } from "../providers/PropiedadProvider";
import { StatisticsWidget5 } from "../../../../../../../_metronic/partials/widgets/statistics/StatisticsWidget5";
import { toAbsoluteUrl } from "../../../../../../../_metronic/helpers";
import FileSaver from "file-saver";
import { useTercero } from "../../../provider/TerceroProvider";
import { GenerarExcel } from "../../../../../../../utils/generarExcel/estadoCuenta/GenerarExcel";
import { InformeServicio } from "../../../../../../../servicios/informeServicio";
import { InformacionGeneral, Movimientos, PlanDePagos, TotalVenta } from "../../../../../../../utils/generarExcel/estadoCuenta/types";
import * as alertify from "alertifyjs";

import { EstadoPagoEnum } from "../../../../../../../interfaces/enums/EnumEstadoPago";
enum Estado {
    NONE,
    DESCARGANDO,
    DESCARGADO
}

export const TerceroPagoEstadisticas: FC = () => {

    const { tercero } = useTercero();
    const {
        formik,
        propiedad,
        saldoRestante,
        planPagos,
        planPagosInicial,
        pagos,
    } = usePropiedad();

    const [estadoExcel, setExtadoExcel] = useState<Estado>(Estado.NONE);

    const nombreCompleto = useMemo(() => {
        if(!tercero) return "";
        return `${tercero.primerNombre} ${tercero.otrosNombres} ${tercero.primerApellido} ${tercero.segundoApellido}`;
    }, [tercero?.primerNombre, tercero?.otrosNombres, tercero?.primerApellido, tercero?.segundoApellido])

    const totalPagos = useMemo(() => pagos.reduce((p, c) => (c.estadoPagoId == 2) ? p + c.valor : p, 0), [pagos]);

    const descargarExcel = async () => {
        if (!tercero) return;
        setExtadoExcel(Estado.DESCARGANDO);

        let saldoInicial = propiedad.valorTotalLote;

        var ini = false;
        const pagosIniciales: PlanDePagos[] = planPagosInicial.map((cuota, i) => {
            saldoInicial -= cuota.cuota;
            var pago = {
                no: i,
                fecha: cuota.fecha,
                cuota: cuota.cuota + cuota.mora,
                interes: 0,
                capital: cuota.cuota,
                mora: 0,
                saldo: saldoInicial,
                actual: (cuota.estadoPagoId == EstadoPagoEnum.pendiente && !ini)
            }
            if (pago.actual) ini = true;
            return pago;
        });

        var ini = false;
        const pagosNormales: PlanDePagos[] = planPagos.map((cuota, i) => {
            var pago = {
                no: i + 1,
                fecha: cuota.fecha,
                cuota: cuota.cuota + cuota.mora,
                interes: cuota.interes,
                capital: cuota.cuota,
                mora: cuota.mora,
                saldo: cuota.saldo,
                actual: (cuota.estadoPagoId == EstadoPagoEnum.pendiente && !ini)
            };
            if (pago.actual) ini = true;
            return pago;
        });

        pagosNormales.unshift({
            no: 0,
            saldo: propiedad.valorFinanciar
        });

        const movimientos: Movimientos[] = pagos.map((pago, i) => ({
            no: i + 1,
            referenciaPago: pago.referenciaPago,
            fecha: pago.fecha,
            pago: pago.valor,
            capital: pago.capital,
            interes: pago.interes,
            abono: pago.abono,
            mora: pago.mora
        }));

        const informacionGeneral: InformacionGeneral = {
            unidad: formik.values.descripcion,
            correo: tercero.correo,
            direccion: tercero.direccion,
            documento: tercero.identificacion,
            nombre: nombreCompleto,
            telefonos: `${tercero.contacto} - ${tercero.contactoWhatsapp}`,
            areaLote: formik.values.areaPropiedad,
            cantidadPagos: formik.values.cantidadPagos,
            interesAnual: formik.values.interesAnual,
            interesDeMora: formik.values.porcentajeMora,
            valorFinanciar: propiedad.valorFinanciar
        }

        const totalMora = planPagos.reduce((p, c) => p + c.mora, 0);
        const totalRecibido = pagos.reduce((p, c) => p + c.valor, 0);
        const abonosCapital = pagos.reduce((p, c) => p + c.abono + c.capital, 0);
        const saldoFinal = propiedad.valorTotalLote - abonosCapital;

        const totalVenta: TotalVenta = {
            valorTotal: propiedad.valorTotalLote,
            valorFinanciar: propiedad.valorFinanciar,
            totalRecibido: totalRecibido,
            cuotasMora: totalMora,
            saldoFinal: saldoFinal,
        }

        const buffer = await GenerarExcel(pagosIniciales, pagosNormales, movimientos, informacionGeneral, totalVenta);
        if (buffer) {
            FileSaver.saveAs(new Blob([buffer]), `PlanPago - ${nombreCompleto} - ${formik.values.descripcion}.xlsx`);
            setExtadoExcel(Estado.NONE);
        } else {
            alertify.error("No se pudo generar el excel.");
            setExtadoExcel(Estado.NONE);
        }
    }

    const [estadoPdf, setEstadoPdf] = useState<Estado>(Estado.NONE);

    const descargarPdf = async () => {
        setEstadoPdf(Estado.DESCARGANDO);
        try {
            const planPagoPdfB64 = await InformeServicio.InformePlanPagosPdf(formik.values.id);
            if (!planPagoPdfB64.descargarB64(`PlanPago - ${nombreCompleto}-${formik.values.descripcion}.pdf`)) throw new Error();
            setEstadoPdf(Estado.NONE);
        } catch (err) {
            alertify.error("No se pudo descargar el comprobante");
            setEstadoPdf(Estado.NONE);
            return;
        }
    }

    return (
        <div className='row'>

            <div className="col-6 mb-7">
                <button disabled={estadoPdf == Estado.DESCARGANDO} type="button" className="btn btn-light-info w-100" onClick={descargarPdf}>
                    {
                        (estadoPdf) ? (
                            <>
                                <span>Descargando </span>
                                <div className="spinner-border" role="status" style={{ height: "15px", width: "15px" }}>
                                    <span className="sr-only" />
                                </div>
                            </>
                        ) : (
                            <>
                                Descargar pdf
                            </>
                        )
                    }
                    <img style={{ maxWidth: "40px" }} src={toAbsoluteUrl("/media/svg/files/pdf.svg")} alt='' />
                </button>
            </div>

            <div className="col-6 mb-7">
                <button disabled={estadoExcel == Estado.DESCARGANDO} type="button" className="btn btn-light-info w-100" onClick={descargarExcel}>
                    {
                        (estadoExcel) ? (
                            <>
                                <span>Descargando </span>
                                <div className="spinner-border" role="status" style={{ height: "15px", width: "15px" }}>
                                    <span className="sr-only" />
                                </div>
                            </>
                        ) : (
                            <>
                                Descargar excel
                            </>
                        )
                    }
                    <img style={{ maxWidth: "40px" }} src={toAbsoluteUrl("/media/svg/files/excel.svg")} alt='' />
                </button>
            </div>

            <div className='col-12 col-sm-6 mb-7'>
                <StatisticsWidget5
                    svgIcon='briefcase'
                    color='primary'
                    iconColor='white'
                    title={saldoRestante.formatoMoneda2(true)}
                    titleColor='white'
                    description={`Saldo restante`}
                    //description={`Saldo del ${(100 - propiedad.porcentajePagoInicial).roundTo(2)}%`}
                    descriptionColor='white'
                />
            </div>

            <div className='col-12 col-sm-6 mb-7'>
                <StatisticsWidget5
                    svgIcon='cheque'
                    color='dark'
                    iconColor='white'
                    title={(propiedad.valorTotalLote).formatoMoneda2(true)}
                    titleColor='white'
                    description={`Valor total`}
                    //description={`Valor inicial ${propiedad.porcentajePagoInicial.roundTo(2)}%`}
                    descriptionColor='white'
                />
            </div>

            <div className='col-12 col-sm-6 mb-7'>
                <StatisticsWidget5
                    svgIcon='chart-simple'
                    color='white'
                    iconColor='primary'
                    title={propiedad.valorFinanciar.formatoMoneda2(true)}
                    description='Valor a financiar'
                />
            </div>

            <div className='col-12 col-sm-6'>
                <StatisticsWidget5
                    svgIcon='dollar'
                    color='info'
                    iconColor='white'
                    title={totalPagos.formatoMoneda2(true)}
                    titleColor='white'
                    description='Total recibido'
                    descriptionColor='white'
                />
            </div>

        </div>
    )
}