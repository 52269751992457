import { FC } from "react"
import { InputInformativo } from "../../../../shared/misc/InputInformativo"
import { documentos, estadoCivil } from "../../../../shared/datosFormularios/Informacion"
import { IATercero } from "../../../../../interfaces/auditorias/IATercero"
import { useInformacion } from "../../../../shared/providers/InformacionProvider"

interface InfoAuditoriaTerceroProps {
    main?: IATercero | null
    target?: IATercero | null
    comparar?: boolean
}

export const InfoAuditoriaTercero: FC<InfoAuditoriaTerceroProps> = ({
    main,
    target,
    comparar = true
}) => {
    const { municipiosObject, actividadesEconomicasObject } = useInformacion();

    return (
        <>
            <InputInformativo
                nombre="Tipo documento"
                icon="bi-credit-card"
                text={documentos.find(d => d.valor == main?.tipoDocumentoId)?.nombre}
                changed={(!comparar) ? false : main?.tipoDocumentoId !== target?.tipoDocumentoId}
            />
            <InputInformativo
                nombre="Documento"
                icon="bi-credit-card"
                text={main?.identificacion}
                changed={(!comparar) ? false : main?.identificacion !== target?.identificacion}
            />
            <InputInformativo
                nombre="Primer nombre"
                icon="bi-person-circle"
                text={main?.primerNombre}
                changed={(!comparar) ? false : main?.primerNombre !== target?.primerNombre}
            />
            <InputInformativo
                nombre="Otros nombres"
                icon="bi-person-circle"
                text={main?.otrosNombres}
                changed={(!comparar) ? false : main?.otrosNombres !== target?.otrosNombres}
            />
            <InputInformativo
                nombre="Primer apellido"
                icon="bi-person-circle"
                text={main?.primerApellido}
                changed={(!comparar) ? false : main?.primerApellido !== target?.primerApellido}
            />
            <InputInformativo
                nombre="Segundo apellido"
                icon="bi-person-circle"
                text={main?.segundoApellido}
                changed={(!comparar) ? false : main?.segundoApellido !== target?.segundoApellido}
            />
            <InputInformativo
                nombre="Nombre comercial"
                icon="bi-shop-window"
                text={main?.nombreComercial}
                changed={(!comparar) ? false : main?.nombreComercial !== target?.nombreComercial}
            />
            <InputInformativo
                nombre="Profesion"
                icon="bi-briefcase-fill"
                text={main?.profesion}
                changed={(!comparar) ? false : main?.profesion !== target?.profesion}
            />
            <InputInformativo
                nombre="Empresa donde trabaja"
                icon="bi-building-fill"
                text={main?.cargo}
                changed={(!comparar) ? false : main?.cargo !== target?.cargo}
            />
            <InputInformativo
                nombre="Estado civil"
                icon="bi-credit-card"
                text={estadoCivil.find(e => e.valor == main?.estadoCivilId)?.nombre}
                changed={(!comparar) ? false : main?.estadoCivilId !== target?.estadoCivilId}
            />
            <InputInformativo
                nombre="Actividad economica"
                icon="bi-cash-coin"
                text={
                    main?.codigoActividad 
                    ? actividadesEconomicasObject[main?.codigoActividad]?.descripcion
                    : ""
                }
                changed={(!comparar) ? false : main?.codigoActividad !== target?.codigoActividad}
            />
            <InputInformativo
                nombre="Municipio"
                icon="bi-geo-alt-fill"
                text={
                    main?.municipioId 
                    ? municipiosObject[main?.municipioId]?.nombreConjunto
                    : ""
                }
                changed={(!comparar) ? false : main?.municipioId !== target?.municipioId}
            />
            <InputInformativo
                nombre="Barrio"
                icon="bi-geo-alt-fill"
                text={main?.barrio}
                changed={(!comparar) ? false : main?.barrio !== target?.barrio}
            />
            <InputInformativo
                nombre="Direccion"
                icon="bi-geo-alt-fill"
                text={main?.direccion}
                changed={(!comparar) ? false : main?.direccion !== target?.direccion}
            />
            <InputInformativo
                nombre="alias"
                icon="bi-person-video2"
                text={main?.alias}
                changed={(!comparar) ? false : main?.alias !== target?.alias}
            />
            <InputInformativo
                nombre="Celular"
                icon="bi-telephone-fill"
                text={main?.contacto}
                changed={(!comparar) ? false : main?.contacto !== target?.contacto}
            />
            <InputInformativo
                nombre="Contacto Whatsapp"
                icon="bi-whatsapp"
                text={main?.contactoWhatsapp}
                changed={(!comparar) ? false : main?.contactoWhatsapp !== target?.contactoWhatsapp}
            />
            <InputInformativo
                nombre="Correo"
                icon="bi-envelope-fill"
                text={main?.correo}
                changed={(!comparar) ? false : main?.correo !== target?.correo}
            />
        </>
    )
}