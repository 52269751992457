import { useListView } from "../../../../shared/providers/ListView/ListView";
import { InformeServicio } from "../../../../../servicios/informeServicio";
import { useEffect, useMemo, useState } from "react";
import { IEInformacionGeneral, InformeEdades } from "../../../../../utils/generarExcel/InformeEdades/InformeEdadesTypes";
import { ITercero } from "../../../../../interfaces/ITercero";
import { TercerosServicios } from "../../../../../servicios/tercerosServicios";
import { Informacion, documentos, estadosCuota } from "../../../../shared/datosFormularios/Informacion";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { useFormik } from "formik";
import { GenerarInformeEdades } from "../../../../../utils/generarExcel/InformeEdades/InformeEdades";
import FileSaver from "file-saver";
import { DataList } from "../../../../../_metronic/partials/formulario/DataList";
import * as alertify from "alertifyjs";
import { Select } from "../../../../../_metronic/partials/formulario/Select";

interface InformeEdadesPost {
    terceroId: string,
    concepto: string,
    estadoPago: number
}

export const InformeEdadesHeaderToolbar = () => {

    const { data, setData, setCargandoDatos, addFilter, removeFilters } = useListView();
    const [terceros, setTerceros] = useState<ITercero[]>([]);

    useEffect(() => {
        TercerosServicios.ObtenerTodos()
            .then((terceros) => setTerceros(terceros));
    }, []);

    const formik = useFormik<InformeEdadesPost>({
        initialValues: {
            terceroId: "",
            concepto: "",
            estadoPago: 0
        },
        onSubmit: async () => { }
    });

    const obtenerInformeEdadesTercero = async (terceroId: string, estadoPago: number) => {
        if (terceroId == "") return setData([]);

        setCargandoDatos(true);
        const informe = await InformeServicio.InformeEdades(terceroId, estadoPago);

        const informeEdades: InformeEdades[] = informe.map(inf => ({
            codigo: inf.prefijo,
            fechaVec: inf.fecha,
            concepto: inf.descripcion,
            saldo: inf.cuota,
            diasVcto: inf.dias,
            estadoCuotaId: inf.estadoCuotaId,
            estadoCuota: inf.estadoCuota,
            valorPagado: inf.valorPagado
        }))
        setData(informeEdades);
    }

    useEffect(() => {
        obtenerInformeEdadesTercero(formik.values.terceroId, formik.values.estadoPago);
    }, [formik.values.terceroId, formik.values.estadoPago]);

    const optionsTercero: Informacion[] = useMemo(() => {
        return terceros.map(tercero => ({
            nombre: `${tercero.primerNombre} ${tercero.primerApellido} ${tercero.segundoApellido}`,
            valor: tercero.id
        }))
    }, [terceros]);

    const optionsPropiedades: Informacion[] = useMemo(() => {
        const informes: InformeEdades[] = data;

        const informesSet = new Set(informes.map(i => i.concepto));

        const informacion: Informacion[] = [];

        informesSet.forEach(i => informacion.push({
            nombre: i,
            valor: i
        }));

        return informacion;
    }, [data]);

    const tercero: ITercero | null = useMemo(() => {
        const tercero = terceros.find(t => t.id == formik.values.terceroId);
        return tercero ?? null;
    }, [formik.values.terceroId, terceros]);

    useEffect(() => {
        if (formik.values.concepto == "") return removeFilters("concepto");
        addFilter("concepto", formik.values.concepto);
    }, [formik.values.concepto]);

    const [descargando, setDescargando] = useState(false);
    const descargarInformeEdades = async () => {
        if (!formik.values.terceroId || !tercero || formik.values.terceroId == "") {
            alertify.error("Selecciona un cliente para poder ver el informe correspondiente");
            return;
        };
        setDescargando(true);
        const informeEdades: InformeEdades[] = data;

        const informacionGeneral: IEInformacionGeneral = {
            identificacion: tercero.identificacion,
            tipoIdentificacion: documentos.find(d => d.valor == tercero.tipoDocumentoId)?.nombre ?? "CC",
            nombreComercial: `${tercero.primerNombre} ${tercero.primerApellido}`
        }

        const buffer = await GenerarInformeEdades(informacionGeneral, informeEdades);
        if (buffer) {
            FileSaver.saveAs(new Blob([buffer]), "InformeEdades.xlsx");
        }
        setDescargando(false);
    }

    return (
        <div className="d-flex justify-content-end">
            <div className='row row-cols-sm-3 row-cols-1 p-0'>
                <DataList
                    options={optionsTercero.map(opt => ({ ...opt, opcion: opt.nombre }))}
                    nombre="Cliente"
                    placeholder="Cliente"
                    className="col"
                    props={formik.getFieldProps("terceroId")}
                    setFieldValue={formik.setFieldValue}
                />
                <DataList
                    options={optionsPropiedades.map(opt => ({ ...opt, opcion: opt.nombre }))}
                    nombre="Propiedades"
                    placeholder="Propiedades Cliente"
                    className="col"
                    props={formik.getFieldProps("concepto")}
                    setFieldValue={formik.setFieldValue}
                />
                <Select
                    options={[
                        { valor: 0, nombre: "Todos" },
                        ...estadosCuota
                    ]}
                    className="col"
                    nombre="Estado"
                    props={formik.getFieldProps("estadoPago")}
                    placeholder="Estado de pago"
                />
            </div>
            <div className="d-flex col-1">
                <button
                    type='button'
                    disabled={descargando}
                    className='h-50 btn btn-sm btn-primary d-flex align-items-center align-self-end ms-3 mb-3 p-4'
                    onClick={descargarInformeEdades}
                    data-tooltip-id="tooltip" data-tooltip-content="Descargar excel"
                >
                    {
                        (descargando) ? (
                            <div className="spinner-border" role="status" style={{ height: "15px", width: "15px" }}>
                                <span className="sr-only"></span>
                            </div>
                        ) : (
                            <img style={{ maxWidth: "20px" }} src={toAbsoluteUrl("/media/svg/files/excel.svg")} alt='' />
                        )
                    }
                </button>
            </div>

        </div>
    )
}