import { FC, useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { ITercero } from "../../../../../../interfaces/ITercero";
import { useListView } from "../../../../../shared/providers/ListView/ListView";
import { useThemeMode } from "../../../../../../_metronic/partials";
import { InformacionServicios } from "../../../../../../servicios/ubicacionServicios";
import { IUbicacion } from "../../../../../../interfaces/IUbicacion";
import { NoDataComponent } from "../../../../../shared/ListView/NoDataComponent";
import { BotonTerceroContacto } from "./BotonContacto";
import { Estado } from "../../../../../shared/misc/BotonesAttributos";

type TableColumnsType = (
  getMunicipio: (id: string) => IUbicacion | null
) => TableColumn<ITercero>[]

const TableColumns: TableColumnsType = (getMunicipio) => {
  return [
    {
      name: 'Identificacion',
      selector: row => row.identificacion,
      width: "110px"
    }, {
      name: 'Nombres',
      selector: row => row.nombreCompleto ?? "",
      sortable: true,
      wrap: true
    }, {
      name: 'Lotes',
      selector: row => row.lotes ?? "",
      sortable: true,
      wrap: true
    }, {
      name: 'Correo',
      selector: row => row.correo,
      sortable: true
    }, {
      name: 'Estado',
      cell: (row) => <Estado estadoId={row.estadoId} />,
      width: "110px",
      center: true
    }, {
      name: 'Acciones',
      cell: (row) => (
        <BotonTerceroContacto
          tercero={row}
          municipio={getMunicipio(row.municipioId)}
        />
      ),
      width: "140px",
      center: true
    }
  ]
}

export const ListaTerceros: FC = () => {

  const { filterData, cargandoDatos } = useListView();
  const { mode } = useThemeMode();

  const [municipiosObject, setMunicipiosObject] = useState<{ [key: string]: IUbicacion }>({});

  useEffect(() => {
    const obtenerInformacion = async () => {
      const municipios = await InformacionServicios.ObtenerUbicaciones();
      const _municipiosObject = {};
      municipios.forEach(municipio => {
        _municipiosObject[municipio.id] = municipio;
      });
      setMunicipiosObject(_municipiosObject);
    }
    obtenerInformacion();
  }, []);

  const municipioById = (id: string) => municipiosObject[id];

  return (
    <DataTable
      className="borderx"
      columns={TableColumns(municipioById)}
      data={filterData}
      progressPending={cargandoDatos}
      pagination
      persistTableHead
      noDataComponent={<NoDataComponent />}
      theme={mode == "dark" ? "dark" : "light"}
      paginationComponentOptions={{
        rowsPerPageText: "Filas por pagina:"
      }}
    />
  )
}

