import { FC } from "react"

export interface InputNoEditableProps {
    nombre?: string
    type?: React.HTMLInputTypeAttribute
    value: any
    disabled?: boolean
    className?: string
}

export const InputNoEditable: FC<InputNoEditableProps> = ({
    nombre,
    type,
    value,
    disabled = true,
    className = ""
}) => {
    return (
        <div className={`mb-2 p-1 ${className}`}>
            {nombre && <label className='form-label fs-6 fw-bolder text-dark'>{nombre}</label>}
            <input
                type={type}
                autoComplete='off'
                value={value ?? ""}
                disabled={disabled}
                style={{ color: "black" }}
                className="form-control form-control-sm bg-transparent"
            />
        </div>
    )
}