import { useListView } from "../../../../shared/providers/ListView/ListView";
import { InformePagosProps, InformeServicio } from "../../../../../servicios/informeServicio";
import { useEffect, useMemo, useState } from "react";
import { Informacion, estadosCuota, estadosPago } from "../../../../shared/datosFormularios/Informacion";
import { useFormik } from "formik";
import { Select } from "../../../../../_metronic/partials/formulario/Select";
import { Input } from "../../../../../_metronic/partials/formulario/Input";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import FileSaver from "file-saver";
import * as alertify from "alertifyjs";
import { GenerarInformePagos } from "../../../../../utils/generarExcel/InformePagos/InformeEstadoPagos";
import { IPInformacionGeneral } from "../../../../../utils/generarExcel/InformePagos/InformeEstadoPagosTypes";
import { useTercero } from "../../../Terceros/perfil/provider/TerceroProvider";
import { IPagoInforme } from "../../../../../interfaces/pago/IPagoInforme";
import { DataList } from "../../../../../_metronic/partials/formulario/DataList";
import { ITercero } from "../../../../../interfaces/ITercero";
import { TercerosServicios } from "../../../../../servicios/tercerosServicios";
import date from "date-and-time";
import { useEscribiendo } from "../../../../shared/hooks/useEscribiendo";

interface InformeCuentaPost {
    fechaFinal: string | null,
    estadoPago: number
}

export const InformePagosHeaderToolbar = () => {

    const { tercero } = useTercero();
    const { data, setData, setCargandoDatos } = useListView();
    const [terceros, setTerceros] = useState<ITercero[]>([]);

    useEffect(() => {
        TercerosServicios.ObtenerTodos()
            .then((terceros) => setTerceros(terceros));
    }, []);

    const formik = useFormik<InformePagosProps>({
        initialValues: {
            terceroId: null,
            fechaFinal: null,
            estadoPagoId: 0
        },
        onSubmit: async (values) => {
            obtenerInformeCuentaTercero(values);
        }
    });

    const obtenerInformeCuentaTercero = async (data: InformePagosProps) => {
        setCargandoDatos(true);
        const informe = await InformeServicio.InformePagos(data);
        setData([...informe]);
    }

    const optionsTercero: Informacion[] = useMemo(() => {
        return terceros.map(tercero => ({
            nombre: `${tercero.primerNombre} ${tercero.primerApellido} ${tercero.segundoApellido}`,
            valor: tercero.id
        }))
    }, [terceros]);

    const [descargando, setDescargando] = useState(false);
    const descargarInformePagos = async () => {
        setDescargando(true);
        const pagos: IPagoInforme[] = data;

        const informacionGeneral: IPInformacionGeneral = {
            nombreTercero: tercero?.nombreCompleto ?? "",
            identificacionTercero: tercero?.identificacion ?? ""
        }

        const buffer = await GenerarInformePagos(informacionGeneral, pagos);
        if (buffer) {
            FileSaver.saveAs(new Blob([buffer]), "InformePagos.xlsx");
        } else {
            alertify.error("No se pudo generar el excel");
        }
        setDescargando(false);
    }

    return (
        <div className='row flex-row-reverse row-cols-sm-4 row-cols-1 p-0'>
            <div className="d-flex">
                <button
                    type='button'
                    className='h-50 btn btn-sm btn-primary d-flex align-items-center align-self-end mb-3 p-4'
                    onClick={() => formik.handleSubmit()}
                >
                    <i className="fs-2 bi bi-search"></i>
                    Buscar
                </button>
                <button
                    type='button'
                    disabled={descargando}
                    className='h-50 btn btn-sm btn-primary d-flex align-items-center align-self-end ms-3 mb-3 p-4'
                    onClick={descargarInformePagos}
                    data-tooltip-id="tooltip" data-tooltip-content="Descargar excel"
                >
                    {
                        (descargando) ? (
                            <div className="spinner-border" role="status" style={{ height: "15px", width: "15px" }}>
                                <span className="sr-only"></span>
                            </div>
                        ) : (
                            <img style={{ maxWidth: "20px" }} src={toAbsoluteUrl("/media/svg/files/excel.svg")} alt='' />
                        )
                    }
                </button>
            </div>
            <Select
                options={[
                    { valor: 0, nombre: "Todos" },
                    ...estadosPago
                ]}
                className="col"
                nombre="Estado"
                props={formik.getFieldProps("estadoPagoId")}
                placeholder="Estado de pago"
            />
            <Input
                type="date"
                className="col"
                nombre="Fecha final"
                props={formik.getFieldProps('fechaFinal')}
            />
            <DataList
                options={optionsTercero.map(opt => ({ ...opt, opcion: opt.nombre }))}
                nombre="Cliente"
                placeholder="Cliente"
                className="col"
                props={formik.getFieldProps("terceroId")}
                setFieldValue={formik.setFieldValue}
            />
        </div>
    )
}