import { FC } from "react"
import { PageLink, PageTitle } from "../../_metronic/layout/core"
import { TerceroManagament } from "../modules/Terceros/Lista/TercerosLista"

const usersBreadcrumbs: PageLink[] = []

export const TercerosManagamentWrapper: FC = () => {

    return (
        <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>Mis clientes</PageTitle>
            <TerceroManagament />
        </>
    )
}