import { FC, MouseEventHandler } from "react"

interface BotonAtrasProps {
    onClick?: MouseEventHandler<HTMLButtonElement>
}

export const BotonAtras: FC<BotonAtrasProps> = ({
    onClick
}) => {

    const style: React.CSSProperties = {
        width: "auto",
        position: "fixed",
        bottom: "15px", right: "120px", zIndex: "100"
    }

    return (
        <button onClick={onClick} type="button" className="btn btn-primary btn-guardar" style={style}>
            <i className="fs-3 bi bi-arrow-left"></i>
        </button>
    )
} 
