import { Pos } from "./estadoCuenta/types";

export const addressToPos = (address: string): Pos => {
    for (let i = 0; i < address.length; i++) {
        if(address[i].isNumeric()){
            let letters = address.slice(0, i);
            let n = 0;
            for(let j = 0; j < letters.length; j++){
                n = letters.charCodeAt(j) - 64 + n * 26;
            }
            return {
                x: n,
                y: parseInt(address.slice(i))
            }
        }
    }
    return {
        x: 0, y: 0
    }
}