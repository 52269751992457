import { axios } from "./axiosInstance";
import { IUbicacion } from "../interfaces/IUbicacion";
import { IActividadEconomica } from "../interfaces/IActividadEconomica";

export class InformacionServicios {

    public static ObtenerUbicaciones = () : Promise<IUbicacion[]> => {
        return new Promise(async(res, rej)=>{
            try{
                const ubicacion = await axios.get<IUbicacion[]>("/ubicacion");
                res(ubicacion);
            }catch(err){
                res([]);
            }
        });
    }

    public static ObtenerActividadesEconmica = () : Promise<IActividadEconomica[]> => {
        return new Promise(async(res, rej)=>{
            try{
                const actividadesEconmicas = await axios.get<IActividadEconomica[]>("/actividad-economica");
                res(actividadesEconmicas);
            }catch(err){
                res([]);
            }
        });
    }

}


