import { Row } from "exceljs";

export const addRow = (row: Row, col_start: number, values: any[]) => {
    let master = false;

    for(let i=0,j=0;i<values.length && j<row.cellCount;j++){
        const cell = row.getCell(col_start + j);
        if(cell.model.master) {
            master = true;
            continue;
        }
        else master = false;
        cell.value = values[i];
        i++;
    }
}