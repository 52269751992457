import { FC, useMemo, useState } from "react"
import clsx from "clsx";
import { useLayout } from "../../../../../../../_metronic/layout/core";
import { useListView } from "../../../../../../shared/providers/ListView/ListView";
import { KTIcon } from "../../../../../../../_metronic/helpers";
import { useTercero } from "../../../provider/TerceroProvider";
import { Informacion, estadosPago, tiposDePago } from "../../../../../../shared/datosFormularios/Informacion";
import { Select } from "../../../../../../../_metronic/partials/formulario/Select";

export const PropiedadesDropDownFilter: FC = () => {

    const { addFilter, removeFilters } = useListView();

    const [propiedad, setPropiedad] = useState("");
    const [tipoPagoId, setTipoPagoId] = useState(0);
    const [estadoPagoId, setEstadoPagoId] = useState(0);

    const { pagos } = useTercero();

    const cambiarFiltros = () => {
        if (propiedad === "") removeFilters("propiedadTerceroId");
        else addFilter("propiedadTerceroId", propiedad);

        if (tipoPagoId == 0) removeFilters("tipoPagoId");
        else addFilter("tipoPagoId", tipoPagoId);

        if (estadoPagoId == 0) removeFilters("estadoPagoId");
        else addFilter("estadoPagoId", estadoPagoId);
    }

    const quitarFiltros = () => {
        setPropiedad("");
        setTipoPagoId(0);
        setEstadoPagoId(0);
    }

    const propiedades = useMemo<Informacion[]>(() => {
        const prop: { [key: string]: string } = {};
        pagos.forEach(p => {
            if (!prop[p.propiedadTerceroId]) {
                prop[p.propiedadTerceroId] = p.propiedad!;
            }
        });
        return Object.entries(prop).map(([k, v]) => ({ nombre: v, valor: k }));
    }, [pagos]);

    return (
        <div className='menu menu-sub menu-sub-dropdown w-200px w-md-250px' data-kt-menu='true'>
            <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Filtros</div>
            </div>

            <div className='separator border-gray-200'></div>

            <div className='px-7'>
                {
                    <Select
                        nombre="Tipo de pago"
                        className="col-12"
                        options={[{ nombre: "", opcion: "", valor: 0 }, ...tiposDePago].map(p => ({
                            nombre: p.nombre,
                            opcion: p.nombre,
                            valor: p.valor
                        }))}
                        props={{
                            name: "",
                            onBlur: () => { },
                            onChange: (e) => {
                                setTipoPagoId(parseInt(e.target.value))
                            },
                            value: tipoPagoId
                        }}
                        tooltip="Agregar nueva propiedad"
                    />
                }
            </div>
            <div className='px-7'>
                {
                    <Select
                        nombre="Estado"
                        options={[{ nombre: "", opcion: "", valor: 0 }, ...estadosPago].map(p => ({
                            nombre: p.nombre,
                            opcion: p.nombre,
                            valor: p.valor
                        }))}
                        props={{
                            name: "",
                            onBlur: () => { },
                            onChange: (e) => {
                                setEstadoPagoId(parseInt(e.target.value))
                            },
                            value: estadoPagoId
                        }}
                        tooltip="Agregar nueva propiedad"
                    />
                }
            </div>
            <div className='px-7'>
                {
                    <Select
                        nombre="Propiedad"
                        options={[{ nombre: "", opcion: "", valor: "" }, ...propiedades].map(p => ({
                            nombre: p.nombre,
                            opcion: p.nombre,
                            valor: p.valor
                        }))}
                        props={{
                            name: "",
                            onBlur: () => { },
                            onChange: (e) => {
                                setPropiedad(e.target.value)
                            },
                            value: propiedad
                        }}
                        tooltip="Agregar nueva propiedad"
                    />
                }
            </div>
            <div className='d-flex justify-content-end mb-5 px-8'>
                <button type='button' className={clsx('btn btn-sm btn-light btn-active-light-primary me-2')} onClick={quitarFiltros}>
                    <KTIcon iconName='arrow-circle-right' className='fs-1' />
                    Limpiar
                </button>
                <button
                    type='submit'
                    className='btn btn-sm btn-primary'
                    data-kt-menu-dismiss='true'
                    onClick={cambiarFiltros}
                >
                    Aplicar
                </button>
            </div>
        </div>
    )
}