export { }

declare global {
    interface Array<T> {
        sum(fn: (value: T) => number): number;
    }
}

Array.prototype.sum = function <T>(this: T[], fn: (value: T) => number): number {
    return this.reduce((p, c) => p + fn(c), 0)
}