import { FC } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { useListView } from "../../../../shared/providers/ListView/ListView";
import { useThemeMode } from "../../../../../_metronic/partials";
import { NoDataComponent } from "../../../../shared/ListView/NoDataComponent";
import { Accion, TipoPago } from "../../../../shared/misc/BotonesAttributos";
import date from "date-and-time"
import { IAPago } from "../../../../../interfaces/auditorias/IAPago";
import { BotonOpenModalPago } from "../utils/BotonOpenModalPago";

const tableColumns: TableColumn<IAPago>[] = [
  {
    name: 'Usuario',
    selector: row => row.aNombreUsuario ?? "",
    sortable: true,
    wrap: true
  }, {
    name: 'Fecha creacion',
    selector: pago => new Date(pago.aFecha).getTime(),
    cell: pago => date.format(new Date(pago.aFecha), "DD-MM-YYYY HH:mm"),
    sortable: true
  }, {
    name: 'Tipo de pago',
    selector: pago => pago.tipoPagoId,
    cell: pago => <TipoPago tipoPagoId={pago.tipoPagoId} />,
    center: true
  }, {
    name: 'Propiedad',
    selector: pago => pago.descripcionLote
  }, {
    name: 'Valor pago',
    selector: pago => pago.valor.formatoMoneda2(true)
  }, {
    name: 'IP',
    selector: row => row.aIp
  }, {
    name: 'Acción',
    center: true,
    cell: (row) => <Accion accion={{ nombre: row.aTipoAccion, valor: row.aTipoAccion }} />,
    width: "120px"
  }, {
    name: 'Acciones',
    cell: (row) => (
      <BotonOpenModalPago aPago={row} />
    ),
    width: "80px",
    center: true
  }
]

export const AuditoriaPagoLista: FC = () => {

  const { filterData, cargandoDatos } = useListView();
  const { mode } = useThemeMode();

  return (
    <div className="px-5">
      <DataTable
        columns={tableColumns}
        data={filterData}
        progressPending={cargandoDatos}
        pagination
        persistTableHead
        noDataComponent={<NoDataComponent />}
        theme={mode == "dark" ? "dark" : "light"}
        keyField="idAuditoria"
        paginationComponentOptions={{
          rowsPerPageText: "Filas por pagina:"
        }}
      />
    </div>
  )
}

