import { FC, createContext, useState, useContext, useEffect, useCallback } from "react"
import { WithChildren } from "../../../../../../../_metronic/helpers"
import { useFormik } from "formik"
import { crearPagoSchema } from "../../../../../../../validators/PagoValidator"
import { Modal } from "react-bootstrap"
import { useTercero } from "../../../provider/TerceroProvider"
import { tiposDePago } from "../../../../../../shared/datosFormularios/Informacion"
import { PagoServicio } from "../../../../../../../servicios/pagoServicio"
import * as alertify from "alertifyjs";
import { IPagoPost } from "../../../../../../../interfaces/pago/IPagoPost"
import { useListView } from "../../../../../../shared/providers/ListView/ListView"
import { EnumTipoPago } from "../../../../../../../interfaces/enums/EnumTipoPago"
import { useSearchParams } from "react-router-dom"
import { Select } from "../../../../../../../_metronic/partials/formulario/Select"
import { Input } from "../../../../../../../_metronic/partials/formulario/Input"
import { InputMonetario } from "../../../../../../../_metronic/partials/formulario/InputMonetario"
import { ICuota } from "../../../../../../../interfaces/cuotas/ICuota"
import { CuotaServicio } from "../../../../../../../servicios/cuotaServicio"
import { EstadoPagoEnum } from "../../../../../../../interfaces/enums/EnumEstadoPago"
import { InputFile } from "../../../../../../../_metronic/partials/formulario/InputFile"
import { Archivo } from "../../../../../../../utils/Files"


interface ModalCrearPagoPros {
    showModal: (show: boolean) => void
}

const ModalCrearPagoIncial: ModalCrearPagoPros = {
    showModal: (show: boolean) => { }
}

const ModalCrearPagoContext = createContext<ModalCrearPagoPros>(ModalCrearPagoIncial)

const ModalCrearPagoProvider: FC<WithChildren> = ({
    children
}) => {
    const [getQP, setQP] = useSearchParams();

    const [pagando, setPagando] = useState(false);

    const [show, setShow] = useState(false);

    const { tercero, propiedades, setPagos } = useTercero();

    const { setData } = useListView();

    const [soportePago, setSoportePago] = useState<Archivo | null>(null);

    // Funcion para mostrar el modal
    const showModal = (show: boolean) => {
        if (!show) {
            formik.resetForm();
            obtenerQp();
        }
        setShow(show);
    }

    const getCuotas = useCallback(async (propiedadTerceroId: string, tipoPagoId: number) => {
        // Si no existe busca las cuotas de la propiedad
        let cuotas = await CuotaServicio.obtenerCuotas(propiedadTerceroId);
        cuotas = cuotas.filter(c => c.tipoPagoId == tipoPagoId);
        
        var proxCuota: ICuota | null = null;

        for (let i = 0; i < cuotas.length; i++) {
            if (cuotas[i].estadoCuotaId == EstadoPagoEnum.pagado) continue;

            // Busca la proxima cuota, dependiendo de los pagos realizados a la cuota
            if (cuotas[i].pagos) {
                var valorPago = cuotas[i].pagos!.reduce((p, c) => p + ((c.estadoPagoId == EstadoPagoEnum.pagado) ? c.valor : 0), 0);

                if (valorPago != cuotas[i].cuota + cuotas[i].mora) {
                    proxCuota = cuotas[i];
                    //proxCuota.cuota -= valorPago;
                    proxCuota.cuota += proxCuota.mora;
                    break;
                }
            } else {
                proxCuota = cuotas[i];
                proxCuota.cuota += proxCuota.mora;
                break;
            }
        }

        // Verifica si no hay proxima cuota
        if (proxCuota == null) {
            formik.setFieldValue("cuotaId", "");
            formik.setFieldValue("valor", 0);
            return;
        }
        
        // Actualiza los campos
        formik.setFieldValue("valor", proxCuota!.cuota);
        formik.setFieldValue("cuotaId", proxCuota!.id);
    }, []);

    // Formulario
    const formik = useFormik<IPagoPost>({
        initialValues: {
            referenciaPago: "",
            fecha: new Date().formatoHora(),
            tipoPagoId: 1,
            valor: 0,
            propiedadTerceroId: "",
            cuotaId: ""
        },
        validationSchema: crearPagoSchema,
        onSubmit: async (pagoPost) => {
            try {
                if (!tercero?.id) {
                    alertify.error("No se puede realizar el pago");
                    return
                }
                if (!soportePago) {
                    alertify.error("El soporte de pago es requerido");
                    return
                }

                pagoPost.soporte = soportePago;
                setPagando(true);
                if (pagoPost.cuotaId == "") {
                    alertify.error("No se pudo completar el pago, verifica que la propiedad tenga cuotas asignadas.");
                    setPagando(false);
                    return;
                }

                pagoPost.valor = Math.ceil(pagoPost.valor);
                pagoPost.cuotaId = pagoPost.tipoPagoId == EnumTipoPago.abono ? null : pagoPost.cuotaId;
                await PagoServicio.CrearPago(pagoPost);

                const pagos = await PagoServicio.obtenerPagos(tercero.id);

                setPagos([...pagos]);
                setData([...pagos]);
                alertify.success("Pago creado");

                getQP.delete("pagar");
                setQP(getQP);

                showModal(false);
            } catch (err) { }
            setPagando(false);
        }
    });

    const obtenerQp = () => {
        const propiedadTerceroId = getQP.get("propiedadTerceroId");
        const tipoPagoId = getQP.get("tipoPagoId");
        if (propiedadTerceroId) {
            formik.setFieldValue("propiedadTerceroId", propiedadTerceroId);
        }
        if (tipoPagoId) {
            formik.setFieldValue("tipoPagoId", tipoPagoId);
        }
    }

    // Actualizar los datos del modal dependiendo de los query params
    useEffect(() => {
        obtenerQp();
        const pagar = getQP.get("pagar");
        if (pagar == "1") {
            showModal(true);
        }
    }, []);

    // Actualiza el valor del proximo pago dependiendo de la propiedad seleccionada (solo si es tipo cuota)
    useEffect(() => {
        var tipoPagoId = parseInt(formik.values.tipoPagoId.toString());
        if (
            formik.values.propiedadTerceroId == "" ||
            ![1, 2, 3].includes(tipoPagoId)
        ) return;
        
        switch (tipoPagoId) {
            case (EnumTipoPago.cuota):
            case (EnumTipoPago.inicial):
                getCuotas(formik.values.propiedadTerceroId, formik.values.tipoPagoId);
                break;
            case (EnumTipoPago.abono):
                break;
        }
    }, [
        formik.values.propiedadTerceroId,
        formik.values.tipoPagoId
    ]);

    useEffect(() => {
        if (tercero?.id) formik.setFieldValue("terceroId", tercero.id);
    }, [tercero?.id]);

    const close = () => {
        setShow(false);
        formik.resetForm();
    }

    return (
        <ModalCrearPagoContext.Provider value={{
            showModal
        }}>
            {children}
            {
                show &&
                <Modal show={show} className="fade modal">
                    <form
                        className="form"
                        onSubmit={formik.handleSubmit}
                        noValidate
                    >
                        <Modal.Header className="p-4">
                            <h2 className="modal-title">{`${tercero?.primerNombre} ${tercero?.otrosNombres} ${tercero?.primerApellido} ${tercero?.segundoApellido}`} - Agregar Pago</h2>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                aria-label="Close"
                                onClick={close}
                            >
                                <i className="bi bi-x-lg"></i>
                            </div>
                        </Modal.Header>
                        <Modal.Body className="bg-gray-300 px-6 py-4">
                            {/* begin::modal */}
                            <div className="row g-2">
                                <div className="col-12">
                                    <div className="row p-4 row-cols-1 bg-light border rounded-3">
                                        <Select
                                            nombre="Propiedad"
                                            options={propiedades.map(p => ({
                                                nombre: p.descripcion,
                                                valor: p.id
                                            }))}
                                            props={formik.getFieldProps('propiedadTerceroId')}
                                            validProp={formik.touched.propiedadTerceroId}
                                            error={formik.errors.propiedadTerceroId}
                                            placeholder="Propiedad"
                                        />
                                        <Input
                                            requerido
                                            type="datetime-local"
                                            nombre="Fecha de pago"
                                            props={formik.getFieldProps('fecha')}
                                            validProp={formik.touched.fecha as boolean}
                                            error={formik.errors.fecha as string}
                                        />
                                        <Input
                                            requerido
                                            nombre="Referencia de pago"
                                            placeholder={"Referencia de pago"}
                                            props={formik.getFieldProps('referenciaPago')}
                                            validProp={formik.touched.referenciaPago}
                                            error={formik.errors.referenciaPago}
                                        />
                                        <InputFile
                                            nombre="Soporte de pago"
                                            onChange={(archivo) => setSoportePago(archivo)}
                                            accept=".pdf, image/*"
                                        />
                                        <Select
                                            requerido
                                            placeholder="Tipo de pago"
                                            nombre="Tipo de pago"
                                            options={tiposDePago}
                                            props={formik.getFieldProps('tipoPagoId')}
                                            validProp={formik.touched.tipoPagoId}
                                            error={formik.errors.tipoPagoId}
                                        />
                                        <InputMonetario
                                            requerido
                                            nombre="Valor del pago"
                                            setFieldValue={formik.setFieldValue}
                                            placeholder={"Valor"}
                                            props={formik.getFieldProps('valor')}
                                            validProp={formik.touched.valor}
                                            error={formik.errors.valor}
                                            formato={1}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* end::modal */}
                        </Modal.Body>
                        <Modal.Footer className="p-4 d-flex">
                            <button
                                type="button"
                                className="btn btn-sm btn-light"
                                onClick={close}
                            >
                                Cancelar
                            </button>
                            <button disabled={pagando} type="submit" className="btn btn-sm btn-primary">
                                {
                                    pagando ? (

                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Pagando...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    ) : (
                                        "Agregar"
                                    )
                                }
                            </button>
                        </Modal.Footer>
                    </form>
                </Modal >
            }
        </ModalCrearPagoContext.Provider>
    )
}

const useModalCrearPago = () => useContext(ModalCrearPagoContext);

export {
    ModalCrearPagoProvider,
    useModalCrearPago
}
