
import normalizar from "normalize-strings";
import { createContext, useState, useEffect, useContext, ReactNode, ReactElement, useMemo } from "react";

export type DataFilter = { [key: string]: any }
export type DataFnFilter = { [key: string]: (data: any) => boolean }


// Datos del useContext
interface ListViewContextData {
    data: any[]
    filterData: any[]
    setData: React.Dispatch<React.SetStateAction<any[]>>
    cargandoDatos: boolean
    setCargandoDatos: React.Dispatch<React.SetStateAction<boolean>>
    textFilter: string
    setTextFilter: React.Dispatch<React.SetStateAction<string>>
    addFilter: (key: string, data: any) => void
    removeFilters: (key: string) => void
    addFnFilter: (key: string, data: (data: any) => boolean) => void
    removeFnFilters: (key: string) => void
}

// Datos iniciales del useContext
const initialListViewProvider: ListViewContextData = {
    data: [],
    filterData: [],
    setData: (d) => { },
    cargandoDatos: true,
    setCargandoDatos: () => { },
    textFilter: "",
    setTextFilter: () => { },
    addFilter: () => { },
    removeFilters: () => { },
    addFnFilter: () => { },
    removeFnFilters: () => { }
}

const ListViewProviderContext = createContext<ListViewContextData>(initialListViewProvider)


interface ListViewProviderProps {
    children: ReactNode
    initialData: any[]
}

type ListViewProviderType = (props: ListViewProviderProps) => ReactElement


// Provider que me permite 'obtener' y filtrar cualquer datos para los datos para una data table
const ListViewProvider: ListViewProviderType = ({
    children,
    initialData
}) => {

    // Filtro de texto
    const [textFilter, setTextFilter] = useState("");

    // Filtros
    const [dataFilter, setDataFilter] = useState<DataFilter>({});

    // Filtros
    const [dataFnFilter, setDataFnFilter] = useState<DataFnFilter>({});

    // Variable para saber si esta cargando la informacion
    const [cargandoDatos, setCargandoDatos] = useState(true);

    // Datos iniciales de la data table (sobre este estado se filtra)
    const [data, setData] = useState<any[]>([]);
    // Datos filtrados que se mostraran en la data table
    //const [filterData, setFilterData] = useState<any[]>([]);

    // Filtrar cada vez que se cambia el estado de textFilter
    const filterData = useMemo(() => {
        // Se normaliza el texto y se pasa a minusculas para un mejor filtrado
        const textNormalizado = normalizar(textFilter).toLowerCase();
        const filtered = data.filter((t) => {
            // Se filtra por todos los datos de la tabla
            let nombreNormalizado = normalizar(Object.values(t).join(" ")).toLowerCase();
            const math1 = nombreNormalizado.includes(textNormalizado);
            var math2 = true;
            var math3 = true;

            var _dataFilter = Object.entries(dataFilter);
            var _dataFnFilter = Object.entries(dataFnFilter);
            for (let i = 0; i < _dataFilter.length; i++) {
                const [key, data] = _dataFilter[i];
                if (t[key] != data) {
                    math2 = false;
                    break;
                }
            }
            for (let i = 0; i < _dataFnFilter.length; i++) {
                const [key, fn] = _dataFnFilter[i];
                if (!fn(t[key])) {
                    math3 = false;
                    break;
                }
            }

            setCargandoDatos(false);
            return math1 && math2 && math3;
        });
        return filtered;
    }, [data, textFilter, dataFilter, dataFnFilter]);

    useEffect(() => {
        setCargandoDatos(false);
    }, [data]);

    useEffect(() => {
        setData(initialData);
    }, [initialData]);

    const addFilter = (key: string, data: any) => {
        setDataFilter((f) => ({ ...f, [key]: data }));
    }

    const addFnFilter = (key: string, fn: (data: any) => boolean) => {
        setDataFnFilter((f) => ({ ...f, [key]: fn }));
    }

    const removeFilters = (key: string) => {
        setDataFilter((f) => {
            delete f[key];
            return { ...f };
        })
    }

    const removeFnFilters = (key: string) => {
        setDataFnFilter((f) => {
            delete f[key];
            return { ...f };
        })
    }

    return (
        <ListViewProviderContext.Provider value={{
            data,
            filterData,
            setData,
            cargandoDatos,
            setCargandoDatos,
            textFilter,
            setTextFilter,
            addFilter,
            removeFilters,
            addFnFilter,
            removeFnFilters
        }}>
            {children}
        </ListViewProviderContext.Provider>
    )
}

// custom hook para obtener los datos del contexto
const useListView = () => useContext(ListViewProviderContext);

export {
    ListViewProvider,
    useListView
}
