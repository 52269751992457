import { FC } from "react"
import { PageLink, PageTitle } from "../../_metronic/layout/core"
import { UsuariosManagament } from "../modules/usuarios/lista/UsuariosManagament"

const usersBreadcrumbs: PageLink[] = []

export const UsuariosManagamentWrapper: FC = () => {

    return (
        <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>Usuarios</PageTitle>
            <UsuariosManagament />
        </>
    )
}