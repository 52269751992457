import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Informacion } from "../../../app/shared/datosFormularios/Informacion";
import clsx from "clsx";
import { BotonIcono } from "../../../app/shared/misc/BotonIcono";
import { FieldInputProps } from "formik";

export interface DataListProps {
    options: {
        nombre: string
        valor: string | number
        opcion: string
    }[]
    defaultValue?: string
    nombre?: string
    props: FieldInputProps<string | number | readonly string[] | undefined>
    validProp?: boolean | undefined
    error?: string | undefined,
    placeholder?: string
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
    disabled?: boolean
    requerido?: boolean
    className?: string
}

export const DataList: FC<DataListProps> = ({
    options,
    nombre,
    props,
    validProp,
    error,
    setFieldValue,
    placeholder,
    defaultValue,
    disabled = false,
    requerido,
    className = ""
}) => {
    const [show, setShow] = useState(false);
    const [valor, setValor] = useState("");

    useEffect(() => {
        if (defaultValue && defaultValue != "") setValor(defaultValue)
    }, [defaultValue])

    const opcionesFiltradas = useMemo(() => {
        try {
            const valorNormalizado = valor.normalizar(true);
            const filtrado = options.filter(({ nombre, valor }) => (nombre + valor).normalizar(true).includes(valorNormalizado));
            return filtrado;
        } catch (err) {
            return []
        }
    }, [valor, options]);

    useEffect(() => {
        const valorNormalizado = valor.normalizar(true);
        const vv = opcionesFiltradas.find(e => e.nombre.normalizar(true) === valorNormalizado);
        if (vv) setFieldValue(props.name, vv.valor);
    }, [valor]);

    const handleOptionSelect = (opcion: Informacion) => {
        setFieldValue(props.name, opcion.valor);
        setValor(opcion.nombre)
    };

    const reset = () => {
        setFieldValue(props.name, "", false);
        setValor("");
    }

    const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
        setValor(e.target.value);
        if (e.target.value === "") props.onChange(e);
    }, []);

    const onBlur: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
        props.onBlur(e);
        setTimeout(() => setShow(false), 250);
    }, []);

    return (
        <div className={`mb-2 p-1 ${className}`}>
            {nombre && <label className='form-label fs-6 fw-bolder text-dark'>{nombre} {(requerido) ? <span className="text-danger">*</span> : ""}</label>}
            <input
                hidden
                name={props.name}
                onChange={() => { }}
                value={props.value ?? ""}
            />
            <div className="input-group">
                <input
                    disabled={disabled}
                    autoComplete="off"
                    id="select-datalist"
                    className={clsx(
                        'form-control form-control-sm bg-transparent',
                        { 'is-invalid': validProp && error },
                        {
                            'is-valid': validProp && !error,
                        }
                    )}
                    type="text"
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={() => setShow(true)}
                    name={props.name}
                    value={valor}
                    placeholder={placeholder}
                />
                <div className="input-group-append">
                    <BotonIcono icon="bi-trash3" disabled={disabled} onClick={reset} style={{ borderRadius: "0px 5px 5px 0px" }} />
                </div>
            </div>
            <ul className="list-group options-list datalist-ul hide-scroll border border-2 rounded">
                {(show) ? opcionesFiltradas.map((option, index) => (
                    <li
                        key={index}
                        className="list-group-item pe-auto datalist-li"
                        //style={current==index ? {backgroundColor: "gainsboro"} : {}}
                        onClick={() => handleOptionSelect(option)}
                    >
                        {option.opcion}
                    </li>
                )) : ""}
            </ul>
            {validProp && error && (
                <div className='fv-plugins-message-container'>
                    <span className='text-danger'>{error}</span>
                </div>
            )}
        </div>

    );
};