export { }

declare global {
    interface Number {
        formatoMoneda(currency?:boolean): string;
        formatoMoneda2(currency?:boolean): string;
        roundTo(places: number): number;
    }
}

Number.prototype.formatoMoneda = function (currency): string {
    const opciones = {
        maximumFractionDigits: 2,
    };
    return ((currency) ? "$" : "") + this.toLocaleString("en-US", opciones);
}

Number.prototype.formatoMoneda2 = function (currency): string {
    const opciones = {
        maximumFractionDigits: 0,
    };
    return ((currency) ? "$ " : "") + this.toLocaleString("es-ES", opciones);
} 

Number.prototype.roundTo = function (places: number) {
    return +(Math.round((this + "e+" + places) as unknown as number)  + "e-" + places);
}