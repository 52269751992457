import { FC } from "react"
import { IPropiedadCaracteristicas } from "../../../../interfaces/propiedades/IPropiedadCaracteristicas"
import { useModalPropiedad } from "../providers/ModalEditarPropiedad"
import { BotonIcon } from "../../../shared/misc/BotonOpenModal"

interface BotonEditarPropiedadProps {
    propiedad: IPropiedadCaracteristicas
}

export const BotonEditarPropiedad: FC<BotonEditarPropiedadProps> = ({
    propiedad
}) => {

    const { showModal } = useModalPropiedad();

    return <BotonIcon icon="bi-pencil-square" tooltip="Editar Propiedad" onClick={()=>showModal(true, {...propiedad})} />
} 
