import { useListView } from "../../../../shared/providers/ListView/ListView";
import { AuditoriaServicio } from "../../../../../servicios/auditoriaServicio";
import { BotonActualizar } from "../../../../shared/misc/BotonActualizar";
import { BotonFiltro } from "../../../../shared/misc/BotonFiltro";
import { AuditoriaPagoDropDownFilter } from "./AuditoriaPagoDropDownFilter";

export const AuditoriaPagoHeaderToolbar = () => {

    const { setData, setCargandoDatos } = useListView();

    
    const obtenerAuditoriaPagos = async () => {
        setCargandoDatos(true);
        const aPagos = await AuditoriaServicio.AuditoriaPagos();
        setData(aPagos);
    }

    return (
        <div className='d-flex justify-content-end align-items-center' data-kt-user-table-toolbar='base'>
            <div className='m-0'>
                <BotonActualizar onClick={obtenerAuditoriaPagos} />
            </div>
            <div className='m-0'>
                <BotonFiltro />
                <AuditoriaPagoDropDownFilter />
            </div>
        </div>
    )
}