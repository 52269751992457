import axios from "axios";
import excel, { ImageRange } from "exceljs";
import { copyCells } from "../copyCells";
import { ICInformacionGeneral } from "./InformeEdadesTypes";
import { IInformeCuenta } from "../../../interfaces/informes/IInformeCuenta";
import { ic_fecha_consulta, ic_ficha_hasta, ic_footer, ic_header, ic_lista_1, ic_lista_2, ic_total_cuota, ic_total_recibido } from "./posiciones";
import { addRow } from "../addRow";

export const GenerarInformeCartera = async (informacionGeneral: ICInformacionGeneral, informeCartera: IInformeCuenta[]): Promise<ArrayBuffer | null> => {
    try {

        const totalData = informeCartera.length - 3;

        const { data } = await axios.get<ArrayBuffer>(`${window.origin}/media/excel/excel-informe-cartera-base.xlsx`, { responseType: 'arraybuffer' });

        const wbBase = new excel.Workbook();
        await wbBase.xlsx.load(data);
        const wsBase = wbBase.getWorksheet("Base");

        const wb = new excel.Workbook();
        const ws = wb.addWorksheet("Informe Cartera");

        const images: Array<{
            type: 'image',
            imageId: string;
            range: ImageRange;
        }> = wsBase.getImages();

        for (const image of images) {
            const img = wbBase.getImage(+image.imageId);
            wb.addImage(img);
        }
        
        // Ancho de las columnas
        for (let i = 1; i <= wsBase.columnCount; i++) {
            const col_base = wsBase.getColumn(i);
            const col = ws.getColumn(i);
            col.width = col_base.width;
        }

        const addImages = (currentRow: number) => {
            wsBase.getImages().forEach(img => {
                img.range.tl.row += currentRow;
                ws.addImage(parseInt(img.imageId), {
                    tl: img.range.tl,
                    ext: {
                        height: 50, width: 50
                    }
                })
            });
        }

        // Header
        const addHeader = () => {
            copyCells(wsBase, ws, ic_header.from, ic_header.to, { x: 1, y: 1 });

            const hasta = ws.getCell(ic_ficha_hasta);

            hasta.value = informacionGeneral.hasta;
        }

        // Footer
        const addFooter = (currentRow: number) => {
            copyCells(wsBase, ws, ic_footer.from, ic_footer.to, { x: 1, y: currentRow });
            const fechaConsulta = ws.getCell(ic_fecha_consulta.y + totalData, ic_fecha_consulta.x);
            const totalCuota    = ws.getCell(ic_total_cuota.y + totalData, ic_total_cuota.x);
            const totalRecibido = ws.getCell(ic_total_recibido.y + totalData, ic_total_recibido.x);

            fechaConsulta.value = new Date();
            totalCuota.value = {
                formula: `SUM(H9:H${8+informeCartera.length})`,
                date1904: false
            }
            totalRecibido.value = {
                formula: `SUM(I9:I${8+informeCartera.length})`,
                date1904: false
            } 
        }

        // Informe edades
        const addPaginaInformeCartera = (currentRow: number) => {

            informeCartera.forEach((ie, i) => {
                const from = (i % 2 == 0) ? ic_lista_1.from : ic_lista_2.from;
                const to   = (i % 2 == 0) ? ic_lista_1.to   : ic_lista_2.to;

                copyCells(wsBase, ws, from, to, { x: 1, y: currentRow });

                const _row = ws.getRow(currentRow);
                addRow(_row, 2, [
                    ie.prefijo,
                    ie.fecha,
                    ie.nombre,
                    ie.lote,
                    ie.estadoCuota,
                    ie.cuota,
                    ie.valorPagado,
                    ie.estadoCuotaId == 2 ? "" : ie.dias
                ]);

                currentRow += 1;
            });
            return currentRow;
        }
        
        addImages(1);
        addHeader();
        
        let currentRow = 9;
        currentRow = addPaginaInformeCartera(currentRow);
        addFooter(currentRow);

        const buffer = await wb.xlsx.writeBuffer();
        return buffer;
    } catch (err) {
        return null;
    }
}