import { FC, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { BotonEditarUsuario } from "./BotonEditarUsuario";
import { useThemeMode } from "../../../../../../_metronic/partials";
import { IUsuario } from "../../../../../../interfaces/IUsuario";
import { usuarioInitialData, ModalEditUsuario } from "../../../../../shared/modals/modalEditarUsuario";
import { useListView } from "../../../../../shared/providers/ListView/ListView";
import { NoDataComponent } from "../../../../../shared/ListView/NoDataComponent";
import { Estado, Rol } from "../../../../../shared/misc/BotonesAttributos";

const TableColumns = (openModal: (usuario: Partial<IUsuario>) => void): TableColumn<IUsuario>[] => {
  return [
    {
      name: 'Identificacion',
      selector: row => row.identificacion,
      width: "150px"
    }, {
      name: 'Nombres',
      selector: row => row.nombre
    }, {
      name: 'Correo',
      selector: row => row.correo
    }, {
      name: 'Rol',
      selector: row => row.rolId,
      cell: (row) => <Rol rolId={row.rolId} />,
      width: "150px",
      center: true
    }, {
      name: 'Estado',
      selector: row => row.estadoId,
      cell: (row) => <Estado estadoId={row.estadoId} />,
      width: "110px",
      center: true
    }, {
      name: 'Acciones',
      cell: (row) => <BotonEditarUsuario usuario={row} openModal={openModal}/>,
      width: "140px",
      center: true
    }
  ]
}

export const ListaUsuarios: FC = () => {

  const [show, setShow] = useState(false);
  const [usuario, setUsuario] = useState(usuarioInitialData);

  const { data, setData, filterData, cargandoDatos } = useListView();
  const { mode } = useThemeMode();

  const openModal = (usuario: Partial<IUsuario>) => {
    setUsuario(usuario);
    setShow(true);
  }

  const onSave = (usuario: Partial<IUsuario>) => {
    const index = data.findIndex((u: Partial<IUsuario>) => u.id == usuario.id);
    if(index!=-1){
      setData((d) => {
        d[index] = usuario;
        return [...d]
      });
    }
  }

  return (
    <>
      <div className="px-5">
        <DataTable
          columns={TableColumns(openModal)}
          data={filterData}
          progressPending={cargandoDatos}
          pagination
          persistTableHead
          noDataComponent={<NoDataComponent />}
          theme={mode == "dark" ? "dark" : "light"}
          paginationComponentOptions={{
            rowsPerPageText: "Filas por pagina:"
          }}
        />
      </div>
      {show ? <ModalEditUsuario show={show} showModal={setShow} onSave={onSave} usuario={usuario}/> : <></>}
    </>
  )
}

