import { FC, useState } from "react";
import { IPago } from "../../../../../../../interfaces/pago/IPago";
import { BotonIcon } from "../../../../../../shared/misc/BotonOpenModal";
import { PagoServicio } from "../../../../../../../servicios/pagoServicio";
import { saveAs } from 'file-saver';
import { b64toBlob } from "../../../../../../../utils/Files";
import * as alertify from "alertifyjs";

interface BotonDescargarComrpobanteProps {
    pago: IPago
}

export const BotonDescargarComrpobante: FC<BotonDescargarComrpobanteProps> = ({
    pago
}) => {

    const [estado, setEstado] = useState(false);

    const download = async () => {
        setEstado(true);
        const comprobante = await PagoServicio.obtenerComprobante(pago.id);
        if (comprobante) {
            saveAs(b64toBlob(comprobante), `ComprobantePago-${pago.consecutivo}.pdf`);
        } else {
            alertify.error("No se pudo descargar el comprobante");
        }
        setEstado(false);
    }

    return (
        <div className="p-2">
            <BotonIcon
                tooltip="Descargar comprobante"
                flip={estado}
                icon={(estado) ? undefined : "bi-download"}
                disabled={estado}
                onClick={download}
            />
        </div>
    )
}