import { FC } from "react";
import clsx from "clsx";
import { FieldInputProps } from "formik";

export interface InputProps {
    nombre?: string
    placeholder?: string
    type?: React.HTMLInputTypeAttribute
    props: FieldInputProps<string | number | readonly string[] | undefined>
    validProp?: boolean | undefined
    error?: string | undefined
    requerido?: boolean
    title?: string
    disabled?: boolean
    validate?: boolean
    showError?: boolean
    autoCapitalLetter?: boolean
    deleteSpaces?: boolean
    min?: number | string
    max?: number | string
    className?: string
}

export const Input: FC<InputProps> = ({
    nombre,
    placeholder,
    type = "text",
    props,
    validProp,
    error,
    requerido = false,
    disabled = false,
    validate = true,
    autoCapitalLetter = false,
    deleteSpaces = false,
    min,
    max,
    className = ""
}) => {

    const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        if (deleteSpaces) e.target.value = e.target.value.replace(/\s/g, '');
        if (autoCapitalLetter) e.target.value = e.target.value.split(" ").map(p => p.length > 0 ? (p[0].toUpperCase() + p.slice(1)) : "").join(" ");
        props.onChange(e);
    }

    return (
        <div className={`mb-2 p-1 ${className}`}>
            {nombre && <label className='form-label fs-6 fw-bolder text-dark'>{nombre} {(requerido) ? <span className="text-danger">*</span> : ""}</label>}
            <input
                min={min}
                max={max}
                type={type}
                autoComplete='off'
                placeholder={placeholder}
                name={props.name}
                onBlur={props.onBlur}
                onChange={onChange}
                value={props.value ?? ""}
                className={clsx(
                    'form-control form-control-sm bg-transparent',
                    { 'is-invalid': validProp && error },
                    {
                        'is-valid': validate && validProp && !error,
                    }
                )}
                disabled={disabled}
            />
            {validProp && error && (
                <div className='fv-plugins-message-container'>
                    <span className="text-danger" role='alert'>{error}</span>
                </div>
            )}
        </div>
    )
}