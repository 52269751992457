import { useListView } from "../../../../shared/providers/ListView/ListView";
import { InformeServicio } from "../../../../../servicios/informeServicio";
import { useEffect, useState } from "react";
import { estadosCuota } from "../../../../shared/datosFormularios/Informacion";
import { useFormik } from "formik";
import { Select } from "../../../../../_metronic/partials/formulario/Select";
import { Input } from "../../../../../_metronic/partials/formulario/Input";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { IInformeCuenta } from "../../../../../interfaces/informes/IInformeCuenta";
import { ICInformacionGeneral } from "../../../../../utils/generarExcel/informeCuenta/InformeEdadesTypes";
import { GenerarInformeCartera } from "../../../../../utils/generarExcel/informeCuenta/InformeCartera";
import FileSaver from "file-saver";
import * as alertify from "alertifyjs";

interface InformeCuentaPost {
    fechaFinal: string | null,
    estadoPago: number
}

export const InformeCuentaHeaderToolbar = () => {

    const { data, setData, setCargandoDatos } = useListView();

    const formik = useFormik<InformeCuentaPost>({
        initialValues: {
            fechaFinal: null,
            estadoPago: 0
        },
        onSubmit: async (values) => {
            obtenerInformeCuentaTercero(values);
        }
    });

    const obtenerInformeCuentaTercero = async ({ fechaFinal, estadoPago }: InformeCuentaPost) => {
        setCargandoDatos(true);
        const informe = await InformeServicio.InformeCuenta(fechaFinal, estadoPago);
        setData([...informe]);
    }

    const [descargando, setDescargando] = useState(false);
    const descargarInformeCartera = async () => {
        setDescargando(true);
        const informeEdades: IInformeCuenta[] = data;

        const informacionGeneral: ICInformacionGeneral = {
            desde: new Date().formato(),
            hasta: formik.values.fechaFinal ?? ""
        }

        const buffer = await GenerarInformeCartera(informacionGeneral, informeEdades);
        if (buffer) {
            FileSaver.saveAs(new Blob([buffer]), "InformeCartera.xlsx");
        } else {
            alertify.error("No se pudo generar el excel");
        }
        setDescargando(false);
    }

    return (
        <div className='row flex-row-reverse row-cols-sm-3 row-cols-1 me-3 p-0'>
            <div className="d-flex">
                <button
                    type='button'
                    className='h-50 btn btn-sm btn-primary d-flex align-items-center align-self-end mb-3 p-4'
                    onClick={() => formik.handleSubmit()}
                >
                    <i className="fs-2 bi bi-search"></i>
                    Buscar
                </button>
                <button
                    type='button'
                    disabled={descargando}
                    className='h-50 btn btn-sm btn-primary d-flex align-items-center align-self-end ms-3 mb-3 p-4'
                    onClick={descargarInformeCartera}
                    data-tooltip-id="tooltip" data-tooltip-content="Descargar excel"
                >
                    {
                        (descargando) ? (
                            <div className="spinner-border" role="status" style={{ height: "15px", width: "15px" }}>
                                <span className="sr-only"></span>
                            </div>
                        ) : (
                            <img style={{ maxWidth: "20px" }} src={toAbsoluteUrl("/media/svg/files/excel.svg")} alt='' />
                        )
                    }
                </button>
            </div>
            <Select
                options={[
                    { valor: 0, nombre: "Todos" },
                    ...estadosCuota
                ]}
                className="col"
                nombre="Estado"
                props={formik.getFieldProps("estadoPago")}
                placeholder="Estado de pago"
            />
            <Input
                type="date"
                className="col"
                nombre="Fecha final"
                props={formik.getFieldProps('fechaFinal')}
            />
        </div>
    )
}