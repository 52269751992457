import { FC } from "react";
import { ListViewProvider } from "../../../shared/providers/ListView/ListView";
import { KTCard } from "../../../../_metronic/helpers";
import { InformeEdadesHeader } from "./components/InformeEdadesHeader";
import { InformeEdadesLista } from "./components/InformeEdadesLista";

export const InformeEdades: FC = () => {
    return (
        <KTCard>
            <ListViewProvider initialData={[]} >
                <InformeEdadesHeader />
                <InformeEdadesLista />
            </ListViewProvider>
        </KTCard>
    )
}
