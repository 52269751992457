
import { IAPropiedadCaracteristica } from "../interfaces/auditorias/IAPropiedadCaracteristica";
import { IAPropiedadTercero } from "../interfaces/auditorias/IAPropiedadTercero";
import { IASesion } from "../interfaces/auditorias/IASesion";
import { IATercero } from "../interfaces/auditorias/IATercero";
import { IAUsuario } from "../interfaces/auditorias/IAUsuario";
import { IAAnterior } from "../interfaces/auditorias/IAAnterior";
import { IAPago } from "../interfaces/auditorias/IAPago";
import { axios } from "./axiosInstance";

export class AuditoriaServicio{

    public static AuditoriaTerceros = () : Promise<IATercero[]> =>{
        return new Promise(async(res, rej)=>{
            try{
                const _res = await axios.get<IATercero[]>("/auditoria/terceros");
                _res.forEach((c, i, a) => {
                    a[i].aFecha = new Date(c.aFecha)
                })
                _res.sort((a, b) => new Date(b.aFecha).getTime() - new Date(a.aFecha).getTime());
                res(_res);
            }catch(err){
                res([]);
            }
        });
    }
    public static AuditoriaTerceroAnterior = (idAuditoria: string) : Promise<IAAnterior<IATercero>|null> =>{
        return new Promise(async(res, rej)=>{
            try{
                const _res = await axios.get<IAAnterior<IATercero>>(`/auditoria/terceros/anterior?IdAuditoria=${idAuditoria}`);
                res(_res);
            }catch(err){
                res(null);
            }
        });
    }
    public static AuditoriaUsuario = () : Promise<IAUsuario[]> =>{
        return new Promise(async(res, rej)=>{
            try{
                const _res = await axios.get<IAUsuario[]>("/auditoria/usuarios");
                _res.forEach((c, i, a) => {
                    a[i].aFecha = new Date(c.aFecha)
                })
                _res.sort((a, b) => b.aFecha.getTime() - a.aFecha.getTime());
                res(_res);
            }catch(err){
                res([]);
            }
        });
    }
    public static AuditoriaUsuarioAnterior = (idAuditoria: string) : Promise<IAAnterior<IAUsuario>|null> =>{
        return new Promise(async(res, rej)=>{
            try{
                const _res = await axios.get<IAAnterior<IAUsuario>>(`/auditoria/usuarios/anterior?IdAuditoria=${idAuditoria}`);
                res(_res);
            }catch(err){
                res(null);
            }
        });
    }
    public static AuditoriaSesion = () : Promise<IASesion[]> =>{
        return new Promise(async(res, rej)=>{
            try{
                const _res = await axios.get<IASesion[]>("/auditoria/sesiones");
                _res.forEach((c, i, a) => {
                    a[i].aFecha = new Date(c.aFecha)
                })
                _res.sort((a, b) => new Date(b.aFecha).getTime() - new Date(a.aFecha).getTime());
                res(_res);
            }catch(err){
                res([]);
            }
        });
    }
    public static AuditoriaPropiedadCaracteristica = () : Promise<IAPropiedadCaracteristica[]> =>{
        return new Promise(async(res, rej)=>{
            try{
                const _res = await axios.get<IAPropiedadCaracteristica[]>("/auditoria/propiedades");
                _res.forEach((c, i, a) => {
                    a[i].aFecha = new Date(c.aFecha)
                })
                _res.sort((a, b) => new Date(b.aFecha).getTime() - new Date(a.aFecha).getTime());
                res(_res);
            }catch(err){
                res([]);
            }
        });
    }
    public static AuditoriaPropiedadCaracteristicaAnterior = (idAuditoria: string) : Promise<IAAnterior<IAPropiedadCaracteristica>|null> =>{
        return new Promise(async(res, rej)=>{
            try{
                const _res = await axios.get<IAAnterior<IAPropiedadCaracteristica>>(`/auditoria/propiedades/anterior?IdAuditoria=${idAuditoria}`);
                res(_res);
            }catch(err){
                res(null);
            }
        });
    }
    public static AuditoriaPropiedadTercero = () : Promise<IAPropiedadTercero[]> =>{
        return new Promise(async(res, rej)=>{
            try{
                const _res = await axios.get<IAPropiedadTercero[]>("/auditoria/propiedades-terceros");
                _res.forEach((c, i, a) => {
                    a[i].aFecha = new Date(c.aFecha)
                })
                _res.sort((a, b) => new Date(b.aFecha).getTime() - new Date(a.aFecha).getTime());
                res(_res);
            }catch(err){
                res([]);
            }
        });
    }
    public static AuditoriaPropiedadTerceroAnterior = (idAuditoria: string) : Promise<IAAnterior<IAPropiedadTercero>|null> =>{
        return new Promise(async(res, rej)=>{
            try{
                const _res = await axios.get<IAAnterior<IAPropiedadTercero>>(`/auditoria/propiedades-terceros/anterior?IdAuditoria=${idAuditoria}`);
                res(_res);
            }catch(err){
                res(null);
            }
        });
    }
    public static AuditoriaPagos = () : Promise<IAPago[]> =>{
        return new Promise(async(res, rej)=>{
            try{
                const _res = await axios.get<IAPago[]>("/auditoria/pagos");
                _res.forEach((c, i, a) => {
                    a[i].fecha = new Date(c.fecha);
                    a[i].aFecha = new Date(c.aFecha);
                })
                _res.sort((a, b) => new Date(b.aFecha).getTime() - new Date(a.aFecha).getTime());
                res(_res);
            }catch(err){
                res([]);
            }
        });
    }
}