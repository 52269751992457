import { FC } from "react";
import { KTIcon } from "../../../../../_metronic/helpers";
import { useModalAuditoriaT } from "../providers/ModalAuditoriaT";
import { IATercero } from "../../../../../interfaces/auditorias/IATercero";
import { BotonIcon } from "../../../../shared/misc/BotonOpenModal";

interface BotonTerceroContactoProps {
    tercero: IATercero
}
export const BotonOpenModalT: FC<BotonTerceroContactoProps> = ({
    tercero
}) => {

    const { showModal } = useModalAuditoriaT();

    return <BotonIcon icon="bi-eye-fill" tooltip="Ver cambios" onClick={() => showModal(true, tercero.idAuditoria)} />
}