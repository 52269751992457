import { Dispatch, FC, SetStateAction, createContext, useContext, useEffect, useMemo, useState } from "react";
import { WithChildren } from "../../../../../_metronic/helpers";
import { ITercero } from "../../../../../interfaces/ITercero";
import { useNavigate, useSearchParams } from "react-router-dom"
import { TercerosServicios } from "../../../../../servicios/tercerosServicios";
import { IPropiedadesTercero } from "../../../../../interfaces/propiedades/IPropiedadesTercero";
import { IPago } from "../../../../../interfaces/pago/IPago";
import { PagoServicio } from "../../../../../servicios/pagoServicio";
import * as alertify from "alertifyjs"

interface TerceroProviderProps {
    pagos: IPago[] // Lista de todos los pagos del tercero
    setPagos: Dispatch<SetStateAction<IPago[]>> // funcion set para cambiar la lista de pagos
    tercero: ITercero | null // datos del tercero
    setTercero: Dispatch<SetStateAction<ITercero | null>>
    propiedades: IPropiedadesTercero[] // lista de todas las propiedades del tercero
    setPropiedades: Dispatch<SetStateAction<IPropiedadesTercero[]>> // funcion set para cambiar la lista de pagos
    estado: number // estado del tercero
    setEstado: React.Dispatch<React.SetStateAction<number>> // funcion set para cambiar el estadp del tercero
    pagosPorPropiedad: { [key: string]: IPago[] }
}

const initialTerceroProvider: TerceroProviderProps = {
    pagos: [],
    setPagos: () => { },
    tercero: null,
    setTercero: () => { },
    propiedades: [],
    setPropiedades: () => { },
    estado: 0,
    setEstado: () => { },
    pagosPorPropiedad: {}
}

const TerceroProviderContext = createContext<TerceroProviderProps>(initialTerceroProvider)

const TerceroProvider: FC<WithChildren> = ({ children }) => {

    const [get] = useSearchParams();
    const [tercero, setTercero] = useState<ITercero | null>(null);
    const [propiedades, setPropiedades] = useState<IPropiedadesTercero[]>([]);
    const [pagos, setPagos] = useState<IPago[]>([]);
    const [estado, setEstado] = useState(0);
    const navigate = useNavigate();

    const pagosPorPropiedad = useMemo<{ [key: string]: IPago[] }>(() => {
        const movs: { [key: string]: IPago[] } = {};
        pagos.forEach((mov) => {
            if (movs[mov.propiedadTerceroId]) movs[mov.propiedadTerceroId].push(mov);
            else movs[mov.propiedadTerceroId] = [mov]
        });
        return movs;
    }, [pagos]);

    const obtenerDatosTercero = async () => {
        const idCliente = get.get("idCliente");

        if (idCliente) {
            let tercero = await TercerosServicios.ObtenerPorID(idCliente);
            if (!tercero) return navigate("/clientes/lista");

            let propiedades = await TercerosServicios.ObtenerPropiedades(idCliente);

            let pagos = await PagoServicio.obtenerPagos(idCliente);

            setPropiedades(propiedades);
            setTercero(tercero);
            setPagos(pagos);
        } else {
            alertify.error("Cliente no encontrado");
            setTimeout(() => {
                navigate("/clientes/lista");
            }, 1000);
        }
    }

    useEffect(() => {
        obtenerDatosTercero();
    }, []);

    return (
        <TerceroProviderContext.Provider value={{
            pagos,
            setPagos,
            tercero,
            setTercero,
            propiedades,
            setPropiedades,
            estado,
            setEstado,
            pagosPorPropiedad
        }}>
            {children}
        </TerceroProviderContext.Provider>
    )
}

const useTercero = () => useContext(TerceroProviderContext);

export {
    TerceroProvider,
    useTercero
}
