import { IPropiedadesTercero } from "../interfaces/propiedades/IPropiedadesTercero";
import { ITercero } from "../interfaces/ITercero";
import { axios } from "./axiosInstance";
import 'alertifyjs/build/css/alertify.css';
import { ITerceroLista } from "../interfaces/ITerceroLista";

export class TercerosServicios {

    public static ObtenerPorID = (idTercero: string) : Promise<ITercero|null> => {
        return new Promise(async(res, rej)=>{
            try{
                const tercero = await axios.get<ITercero>(`/tercero/?id=${idTercero}`)
                tercero.hijos = tercero.hijos.map(h=>({
                    ...h,
                    fechaNacimiento: new Date(h.fechaNacimiento).toISOString().slice(0, 10)
                }));
                res(tercero);
            }catch(err){
                res(null);
            }
        });
    }

    public static Crear = (tercero: Partial<ITercero>) : Promise<void> => {
        return new Promise(async(res, rej)=>{
            try{
                await axios.post("/tercero", { ...tercero })
                res();
            }catch(err){
                rej(err);
            }
        });
    }

    public static Actualizar = (tercero: Partial<ITercero>) : Promise<boolean> => {
        return new Promise(async(res, rej)=>{
            try{
                await axios.put<ITercero[]>("/tercero", { ...tercero })
                res(true);
            }catch(err){
                res(false);
            }
        });
    }

    public static ObtenerTodos = () : Promise<ITercero[]> => {
        return new Promise(async(res, rej)=>{
            try{
                const terceros = await axios.get<ITercero[]>("/tercero/lista");
                res(terceros);
            }catch(err){
                res([]);
            }
        });
    }

    public static ObtenerLista = () : Promise<ITerceroLista[]> => {
        return new Promise(async(res, rej)=>{
            try{
                const terceros = await axios.get<ITerceroLista[]>("/tercero/select");
                res(terceros);
            }catch(err){
                res([]);
            }
        });
    }

    public static ObtenerPropiedades = (idTercero: string) : Promise<IPropiedadesTercero[]> => {
        return new Promise(async(res, rej)=>{
            try{
                const propiedad = await axios.get<IPropiedadesTercero[]>(`/propiedad-tercero-id?TerceroId=${idTercero}`);
                
                propiedad.forEach((c, i, a) => {
                    var fechaInicial = new Date(Date.parse(c.fechaLimitePagoInicial));
                    var fechaIniciaFinanciacion = new Date(Date.parse(c.fechaInicioFinanciacion));
                    a[i].fechaLimitePagoInicial = fechaInicial.formato();
                    a[i].fechaInicioFinanciacion = fechaIniciaFinanciacion.formato();
                });
                res(propiedad);
            }catch(err){
                res([]);
            }
        });
    }
}