import axios from "axios";
import excel, { ImageRange } from "exceljs";
import { copyCells } from "../copyCells";
import { ec_footer, ec_header, ec_lista_1, ec_lista_2, ec_nit, ec_nombre_comercial, ec_total_cuota } from "./posiciones";
import { IEInformacionGeneral, InformeEdades } from "./InformeEdadesTypes";
import { addRow } from "../addRow";

export const GenerarInformeEdades = async (informacionGeneral: IEInformacionGeneral, informeEdades: InformeEdades[]): Promise<ArrayBuffer | null> => {
    try {

        const { data } = await axios.get<ArrayBuffer>(`${window.origin}/media/excel/excel-informe-edades-base.xlsx`, { responseType: 'arraybuffer' });

        const wbBase = new excel.Workbook();
        await wbBase.xlsx.load(data);
        const wsBase = wbBase.getWorksheet("Base");

        const wb = new excel.Workbook();
        const ws = wb.addWorksheet("Informe Edades");

        const images: Array<{
            type: 'image',
            imageId: string;
            range: ImageRange;
        }> = wsBase.getImages();

        for (const image of images) {
            const img = wbBase.getImage(+image.imageId);
            wb.addImage(img);
        }
        

        // Ancho de las columnas
        for (let i = 1; i <= wsBase.columnCount; i++) {
            const col_base = wsBase.getColumn(i);
            const col = ws.getColumn(i);
            col.width = col_base.width;
        }

        const addImages = (currentRow: number) => {
            wsBase.getImages().forEach(img => {
                img.range.tl.row += currentRow;
                ws.addImage(parseInt(img.imageId), {
                    tl: img.range.tl,
                    //@ts-ignore
                    ext: img.range.ext
                })
            });
        }

        // Header
        const addInformacionGeneral = (informacionGeneral: IEInformacionGeneral) => {
            copyCells(wsBase, ws, ec_header.from, ec_header.to, { x: 1, y: 1 });

            const nombre = ws.getCell(ec_nombre_comercial);
            const identifiacion    = ws.getCell(ec_nit);

            nombre.value = informacionGeneral.nombreComercial;
            identifiacion.value    = `(${informacionGeneral.tipoIdentificacion}: ${informacionGeneral.identificacion})`;
        }

        // Footer
        const addFooter = (currentRow: number) => {
            copyCells(wsBase, ws, ec_footer.from, ec_footer.to, { x: 1, y: currentRow });
            const totalCuota    = ws.getCell(currentRow, 6);
            const totalRecibido = ws.getCell(currentRow, 7);
            const fechaConsulta = ws.getCell(currentRow+1, 8);
            
            fechaConsulta.value = new Date();
            totalCuota.value = {
                formula: `SUM(F10:F${9+informeEdades.length})`,
                date1904: false
            } 
            totalRecibido.value = {
                formula: `SUM(G10:G${9+informeEdades.length})`,
                date1904: false
            } 
        }

        // Informe edades
        const addPaginaInformeEdades = (informacionGeneral: IEInformacionGeneral, informeEdades: InformeEdades[]) => {
            addInformacionGeneral(informacionGeneral);
            let currentRow = 10;
            informeEdades.forEach((ie, i) => {
                const from = (i % 2 == 0) ? ec_lista_1.from : ec_lista_2.from;
                const to   = (i % 2 == 0) ? ec_lista_1.to   : ec_lista_2.to;

                copyCells(wsBase, ws, from, to, { x: 1, y: currentRow });

                const _row = ws.getRow(currentRow);
                addRow(_row, 2, [
                    ie.codigo,
                    ie.fechaVec,
                    ie.estadoCuota,
                    ie.concepto,
                    ie.saldo,
                    ie.valorPagado,
                    ie.estadoCuotaId == 2 ? "" : ie.diasVcto
                ]);

                currentRow += 1;
            });
            addFooter(currentRow);
        }
        
        addImages(1);
        addPaginaInformeEdades(informacionGeneral, informeEdades);

        const buffer = await wb.xlsx.writeBuffer();
        return buffer;
    } catch (err) {
        return null;
    }
}