import { FC, useEffect, useState } from "react";
import { ListViewProvider } from "../../../shared/providers/ListView/ListView";
import { KTCard } from "../../../../_metronic/helpers";
import { AuditoriaServicio } from "../../../../servicios/auditoriaServicio";
import { AuditoriaTerceroLista } from "./components/AuditoriaTerceroLista";
import { TerceroManagamentHeader } from "./components/AuditoriaTerceroHeader";
import { IATercero } from "../../../../interfaces/auditorias/IATercero";
import { ModalAuditoriaTProvider } from "./providers/ModalAuditoriaT";
import { InformacionProvider } from "../../../shared/providers/InformacionProvider";

export const AuditoriaTerceros: FC = () => {

    const [aTerceros, setATerceros] = useState<IATercero[]>([]);

    useEffect(() => {
        const obtenerAuditoriaUsuarios = async () => {
            const aTerceros = await AuditoriaServicio.AuditoriaTerceros();
            setATerceros(aTerceros);
        }
        obtenerAuditoriaUsuarios();
    }, []);

    return (
        <KTCard>
            <InformacionProvider>
                <ListViewProvider initialData={aTerceros} >
                    <ModalAuditoriaTProvider>
                        <TerceroManagamentHeader />
                        <AuditoriaTerceroLista />
                    </ModalAuditoriaTProvider>
                </ListViewProvider>
            </InformacionProvider>
        </KTCard>
    )
}
