import { FC, useEffect, useState } from "react";
import { ListViewProvider } from "../../../shared/providers/ListView/ListView";
import { KTCard } from "../../../../_metronic/helpers";
import { AuditoriaSesionLista } from './components/AuditoriaSesionLista';
import { AuditoriaServicio } from "../../../../servicios/auditoriaServicio";
import { AuditoriaSesionHeader } from "./components/AuditoriaSesionHeader";
import { IASesion } from "../../../../interfaces/auditorias/IASesion";

export const AuditoriaSesiones: FC = () => {

    const [aSesiones, setASesiones] = useState<IASesion[]>([]);

    useEffect(() => {
        const obtenerAuditoriaUsuarios = async () => {
            const aSesiones = await AuditoriaServicio.AuditoriaSesion();
            setASesiones(aSesiones);
        }
        obtenerAuditoriaUsuarios();
    }, []);

    return (
        <KTCard>
            <ListViewProvider initialData={aSesiones} >
                <AuditoriaSesionHeader />
                <AuditoriaSesionLista />
            </ListViewProvider>
        </KTCard>
    )
}
