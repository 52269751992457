import { FC, useEffect } from "react";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import { IUsuario } from "../../../interfaces/IUsuario";
import { UsuarioActualizarSchema } from "../../../validators/usuarioValidator";
import { UsuarioServicios } from "../../../servicios/usuarioServicio";
import * as alertify from 'alertifyjs';
import { roles, estados } from "../datosFormularios/Informacion";
import { Input } from "../../../_metronic/partials/formulario/Input";
import { Select } from "../../../_metronic/partials/formulario/Select";

interface UsuarioModeloActualizar extends Partial<IUsuario> {
    confirmarContrasena?: string
}

export const usuarioInitialData: Partial<UsuarioModeloActualizar> = {
    id: "",
    identificacion: "",
    nombre: "",
    correo: "",
    rolId: 1,
    estadoId: 1,
    contrasena: "",
    confirmarContrasena: ""
}


interface ModalAddClienteTerceroProps {
    show: boolean
    showModal: React.Dispatch<React.SetStateAction<boolean>>
    usuario: UsuarioModeloActualizar
    onSave?: (usuario: Partial<IUsuario>) => void
}

export const ModalEditUsuario: FC<ModalAddClienteTerceroProps> = ({
    show,
    showModal,
    usuario,
    onSave
}) => {

    const formik = useFormik({
        initialValues: usuario,
        enableReinitialize: true,
        validationSchema: UsuarioActualizarSchema,
        onSubmit: async (values, { setErrors }) => {
            try {
                if(values.contrasena != values.confirmarContrasena){
                    return setErrors({"confirmarContrasena": "Las contraseñas no coinciden"});
                }
                if(!values.contrasena) values.contrasena = "";
                values.rolId = parseInt(values.rolId!.toString());
                values.estadoId = parseInt(values.estadoId!.toString());
                await UsuarioServicios.Actualizar({
                    id: values.id,
                    nombre: values.nombre,
                    identificacion: values.identificacion,
                    rolId: values.rolId!,
                    estadoId: values.estadoId!,
                    correo: values.correo,
                    contrasena: values.contrasena
                });
                alertify.success("Usuario guardado");
                showModal(false);
                if(onSave) onSave(values);
            } catch (err) {
                alertify.error("No se pudo guardar el usuario");
            }
        }
    });

    useEffect(()=>{
        formik.setValues({...usuario, confirmarContrasena: ""})
    }, [usuario]);

    return (
        <Modal show={show} className="fade modal">
            <form
                className="form"
                onSubmit={formik.handleSubmit}
                noValidate
            >
                <Modal.Header className="p-4">
                    <h2 className="modal-title">EDITAR USUARIO</h2>
                    <div
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                        aria-label="Close"
                        onClick={() => showModal(false)}
                    >
                        <i className="fs-2 bi bi-x-lg"/>
                    </div>
                </Modal.Header>
                <Modal.Body className="bg-gray-300 px-6 py-4">
                    {/* begin::modal */}

                    <div className="row p-4 row-cols-1 bg-light border rounded-3">
                        <div className="col-12 p-0 px-5 rounded mb-2">
                            <h1 className="align-middle">Persona</h1>
                        </div>
                        <Input
                            type="email"
                            nombre={"Identificacion"}
                            requerido
                            props={formik.getFieldProps('identificacion')}
                            validProp={formik.touched.identificacion}
                            error={formik.errors.identificacion}
                            disabled
                            
                        />
                        <Input
                            nombre={"Nombres"}
                            requerido
                            props={formik.getFieldProps('nombre')}
                            validProp={formik.touched.nombre}
                            error={formik.errors.nombre}
                            
                        />
                        <Input
                            nombre={"Correo"}
                            requerido
                            props={formik.getFieldProps('correo')}
                            validProp={formik.touched.correo}
                            error={formik.errors.correo}
                        />
                        <Select
                            options={roles}
                            nombre={"Rol"}
                            requerido
                            props={formik.getFieldProps('rolId')}
                            validProp={formik.touched.rolId}
                            error={formik.errors.rolId}
                        />
                        <Select
                            options={estados}
                            nombre={"Estado"}
                            requerido
                            props={formik.getFieldProps('estadoId')}
                            validProp={formik.touched.estadoId}
                            error={formik.errors.estadoId}
                        />
                        <Input
                            nombre={"Contraseña"}
                            type="password"
                            props={formik.getFieldProps('contrasena')}
                            validProp={formik.touched.contrasena}
                            error={formik.errors.contrasena}
                        />
                        <Input
                            nombre={"Confirmar Contraseña"}
                            type="password"
                            props={formik.getFieldProps('confirmarContrasena')}
                            validProp={formik.touched.confirmarContrasena}
                            error={formik.errors.confirmarContrasena}
                        />
                    </div>
                    {/* end::modal */}
                </Modal.Body>
                <Modal.Footer className="p-4">
                    <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => showModal(false)}
                    >
                        Cancelar
                    </button>
                    <button type="submit" className="btn btn-primary">
                        Guardar usuario
                    </button>
                </Modal.Footer>
            </form>
        </Modal >
    )
}