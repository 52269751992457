import { ICuota } from "../interfaces/cuotas/ICuota";
import { axios } from "./axiosInstance";

export class CuotaServicio {

    public static obtenerCuotas = (propiedadTerceroId: string) : Promise<ICuota[]> => {
        return new Promise(async(res, rej)=>{
            try{
                const cuotas = await axios.get<ICuota[]>(`/cuota/obtener?PropiedadTerceroId=${propiedadTerceroId}`);
                cuotas.forEach((c, i, a)=>{
                    a[i].fecha = new Date(c.fecha);
                });
                cuotas.sort((a, b) => a.numero - b.numero);
                res(cuotas);
            }catch(err){
                rej(err);
            }
        });
    }

    public static obtenerCuota = (cuotaId: string) : Promise<ICuota | null> => {
        return new Promise(async(res, rej)=>{
            try{
                const cuotas = await axios.get<ICuota>(`/cuota/obtener?id=${cuotaId}`);
                res(cuotas);
            }catch(err){
                res(null);
            }
        });
    }

}