import { FC } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { useListView } from "../../../../shared/providers/ListView/ListView";
import { useThemeMode } from "../../../../../_metronic/partials";
import { IATercero } from "../../../../../interfaces/auditorias/IATercero";
import { BotonOpenModalT } from "../utils/BotonOpenModalT";
import { Accion } from "../../../../shared/misc/BotonesAttributos";
import date from "date-and-time"


const TableColumns: TableColumn<IATercero>[] = [
  {
    name: 'Usuario',
    selector: row => row.aNombreUsuario,
    sortable: true,
    wrap: true
  },
  {
    name: 'Modificado',
    selector: row => `${row.primerNombre} ${row.primerApellido}`,
    sortable: true,
    wrap: true
  },
  {
    name: 'Identificacion',
    selector: row => row.identificacion
  },
  {
    name: 'Fecha',
    selector: row => date.format(row.aFecha, 'DD-MM-YYYY HH:mm'),
    sortFunction: (a, b) => a.aFecha.getTime() - b.aFecha.getTime(),
    sortable: true
  },
  {
    name: 'IP',
    selector: row => row.aIp
  },
  {
    name: 'Tipo de Accion',
    selector: row => row.aTipoAccion,
    center: true,
    cell: (row) => <Accion accion={{ nombre: row.aTipoAccion, valor: row.aTipoAccion }} />,
    width: "100px"
  }, {
    name: 'Acciones',
    cell: (row) => (
      <BotonOpenModalT tercero={row} />
    ),
    width: "80px",
    center: true
  }
]


export const AuditoriaTerceroLista: FC = () => {

  const { filterData, cargandoDatos } = useListView();
  const { mode } = useThemeMode();

  return (
    <div className="px-5">
      <DataTable
        columns={TableColumns}
        data={filterData}
        progressPending={cargandoDatos}
        pagination
        persistTableHead
        theme={mode == "dark" ? "dark" : "light"}
        keyField="idAuditoria"
        paginationComponentOptions={{
          rowsPerPageText: "Filas por pagina:"
        }}
      />
    </div>
  )
}

