import * as Yup from 'yup'
import { IUsuario } from '../interfaces/IUsuario';


export const UsuarioActualizarSchema = Yup.object<IUsuario>().shape({
    nombre: Yup.string()
        .max(100, 'Máximo 100 símbolos.')
        .required('Campo requerido.'),
    correo: Yup.string()
        .email('Correo invalido.')
        .min(3, 'Mínimo 3 símbolos.')
        .max(50, 'Máximo 50 símbolos.')
        .required('Campo requerido.'),
    rolId: Yup.number()
        .required('Campo requerido.'),
    estadoId: Yup.number()
        .required('Campo requerido.'),
    contrasena: Yup.string()
        .max(50, 'Máximo 50 símbolos.'),
    confirmarContrasena: Yup.string()
});

export const UsuarioCrearSchema = Yup.object<IUsuario>().shape({
    identificacion: Yup.string()
        .required('Campo requerido.'),
    nombre: Yup.string()
        .max(100, 'Máximo 100 símbolos.')
        .required('Campo requerido.'),
    correo: Yup.string()
        .email('Correo invalido.')
        .min(3, 'Mínimo 3 símbolos.')
        .max(50, 'Máximo 50 símbolos.')
        .required('Campo requerido.'),
    rolId: Yup.number()
        .required('Campo requerido.'),
    contrasena: Yup.string()
        .max(50, 'Máximo 50 símbolos.')
        .required('Campo requerido.'),
    confirmarContrasena: Yup.string()
        .required('Campo requerido.')
});