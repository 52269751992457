import { Dispatch, FC, ReactNode, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { IPropiedadCaracteristicas } from "../../../../interfaces/propiedades/IPropiedadCaracteristicas";
import { PropiedadServicio } from "../../../../servicios/propiedadServicio";
import { ITerceroLista } from "../../../../interfaces/ITerceroLista";
import { TercerosServicios } from "../../../../servicios/tercerosServicios";
import * as alertify from "alertifyjs";
import { DataList } from "../../../../_metronic/partials/formulario/DataList";
import { InputNoEditable } from "../../../../_metronic/partials/formulario/InputNoEditable";

interface ModalAsignarPropiedadPros {
}

const ModalAsignarPropiedadIncial: ModalAsignarPropiedadPros = {
}

const ModalAsignarPropiedadContext = createContext<ModalAsignarPropiedadPros>(ModalAsignarPropiedadIncial)

interface ModalAsignarPropiedadProviderProps {
    children?: ReactNode,
    propiedad: IPropiedadCaracteristicas,
    show: boolean,
    setShow: Dispatch<SetStateAction<boolean>>,
    onClose: () => void,
    onSave: (propiedad: IPropiedadCaracteristicas) => void
}

const ModalAsignarPropiedadProvider: FC<ModalAsignarPropiedadProviderProps> = ({
    children,
    show,
    setShow,
    propiedad,
    onClose,
    onSave
}) => {
    const [clientes, setClientes] = useState<ITerceroLista[]>([]);

    useEffect(() => {
        const obtenerClientes = async () => {
                const clientes = await TercerosServicios.ObtenerLista();
                setClientes(clientes);
        }
        obtenerClientes();
    }, []);

    const [terceroId, setTerceroId] = useState("");

    const asignar = async (e) => {
        try{
            e.preventDefault();
            await PropiedadServicio.Asignar({
                areaPropiedad: propiedad.areaPropiedad,
                descripcion: propiedad.descripcion,
                interesAnual: propiedad.interesAnual,
                propiedadId: propiedad.propiedadId,
                terceroId: terceroId,
                valorTotal: propiedad.valorM2
            });
            alertify.success("Propiedad asignada.");
            onSave({...propiedad, tercero: terceroId});
            onClose();
        }catch(err){
            //alertify.error("No se pudo asignar la propiedad.");
        }
    }

    return (
        <ModalAsignarPropiedadContext.Provider value={{}}>
            {children}
            {
                show &&
                <Modal show={show} className="fade modal">
                    <form
                        className="form"
                        onSubmit={asignar}
                        noValidate
                    >
                        <Modal.Header className="p-4">
                            <h2 className="modal-title">Propiedad</h2>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                aria-label="Close"
                                onClick={onClose}
                            >
                                <i className="bi bi-x-lg"></i>
                            </div>
                        </Modal.Header>
                        <Modal.Body className="bg-gray-300 px-6 py-4">
                            {/* begin::modal */}
                            <div className="row g-2">
                                <div className="col-12">
                                    <div className="row p-4 row-cols-1 bg-light border rounded-3">
                                        <InputNoEditable
                                            nombre="Nombre propiedad"
                                            value={propiedad.descripcion}
                                            disabled
                                        />
                                        <DataList
                                            options={clientes.map( v => ({nombre: v.tercero, opcion: v.tercero, valor: v.id}))}
                                            requerido
                                            setFieldValue={(f, v, s) => {
                                                setTerceroId(v);
                                            }}
                                            nombre="Cliente"
                                            placeholder="Cliente"
                                            props={{
                                                value: terceroId,
                                                onChange: () => {}, onBlur: () => {},
                                                name: "idTercero"
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* end::modal */}
                        </Modal.Body>
                        <Modal.Footer className="p-4 d-flex">
                            <button
                                type="button"
                                className="btn btn-sm btn-light"
                                onClick={onClose}
                            >
                                Cancelar
                            </button>
                            <button type="submit" className="btn btn-sm btn-primary">
                                Asignar
                            </button>
                        </Modal.Footer>
                    </form>
                </Modal >
            }
        </ModalAsignarPropiedadContext.Provider>
    )
}

const useModalAsignarPropiedad = () => useContext(ModalAsignarPropiedadContext);

export {
    ModalAsignarPropiedadProvider,
    useModalAsignarPropiedad
}
