import { FC, createContext, useContext } from "react";
import { WithChildren } from "../../../../_metronic/helpers";
import { FormikProps, useFormik } from "formik";
import { ITercero } from "../../../../interfaces/ITercero";
import { terceroCrearSchema } from "../../../../validators/terceroValidator";
import { TercerosServicios } from "../../../../servicios/tercerosServicios";
import * as alertify from 'alertifyjs';
import { datosTerceroIniciales } from "../../../shared/datosFormularios/DatosInicialesFormularios";
import { useNavigate } from "react-router-dom";

interface FormularioProviderProps {
    formik: FormikProps<ITercero>
}

const initialFormularioProvider: FormularioProviderProps = {
    formik: {} as FormikProps<ITercero>
}

const FormularioProviderContext = createContext<FormularioProviderProps>(initialFormularioProvider)


const FormularioProvider: FC<WithChildren> = ({ children }) => {

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: datosTerceroIniciales,
        enableReinitialize: true,
        validationSchema: terceroCrearSchema,
        onSubmit: async (values) => {
            try {
                await TercerosServicios.Crear({...values, pareja: values.pareja});
                alertify.success("Cliente guardado");
                setTimeout(()=>{
                    navigate("/clientes/lista");
                }, 2000);
            } catch (err) {
                //alertify.success("Cliente guardado");
            }
        }
    })

    return (
        <FormularioProviderContext.Provider value={{ 
            formik
        }}>
            <form
                className="form"
                onSubmit={formik.handleSubmit}
                noValidate
            >
                {children}
            </form>
        </FormularioProviderContext.Provider>
    )
}

const useFormulario = () => useContext(FormularioProviderContext);

export {
    FormularioProvider,
    useFormulario
}
