import { FC } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { useListView } from "../../../../shared/providers/ListView/ListView";
import { useThemeMode } from "../../../../../_metronic/partials";
import { NoDataComponent } from "../../../../shared/ListView/NoDataComponent";
import date from "date-and-time"
import { InformeEdades } from "../../../../../utils/generarExcel/InformeEdades/InformeEdadesTypes";
import { EstadoCuota } from "../../../../shared/misc/BotonesAttributos/EstadoCuota";

const tableColumns: TableColumn<InformeEdades>[] = [
  {
    name: 'Codigo',
    selector: row => row.codigo
  }, {
    name: 'Fecha',
    selector: row => date.format(row.fechaVec, 'DD-MM-YYYY'),
    sortFunction: (a, b) => a.fechaVec.getTime() - b.fechaVec.getTime(),
    sortable: true
  }, {
    name: 'Concepto',
    selector: row => row.concepto
  }, {
    name: 'Saldo',
    selector: row => row.saldo.formatoMoneda2(true),
    wrap: true
  }, {
    name: 'Estado pago',
    selector: pago => pago.estadoCuota,
    cell: pago => <EstadoCuota estadoPagoId={pago.estadoCuotaId} />,
    center: true
  }, {
    name: 'Total recibido',
    selector: row => row.valorPagado.formatoMoneda2(true),
    wrap: true
  }, {
    name: 'Dias Vcto.',
    selector: row => (row.estadoCuotaId == 2) ? "" : row.diasVcto,
    width: "100px"
  }
]

export const InformeEdadesLista: FC = () => {

  const { filterData, cargandoDatos } = useListView();
  const { mode } = useThemeMode();

  return (
    <div className="px-5">
      <DataTable
        columns={tableColumns}
        data={filterData}
        progressPending={cargandoDatos}
        pagination
        persistTableHead
        theme={mode == "dark" ? "dark" : "light"}
        noDataComponent={<NoDataComponent/>}
        keyField="idAuditoria"
        paginationComponentOptions={{
          rowsPerPageText: "Filas por pagina:"
        }}
      />
    </div>
  )
}

