import { FC, createContext, useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { WithChildren } from "../../../../../_metronic/helpers";
import { IAAnterior } from "../../../../../interfaces/auditorias/IAAnterior";
import { AuditoriaServicio } from "../../../../../servicios/auditoriaServicio";
import { IAPropiedadTercero } from "../../../../../interfaces/auditorias/IAPropiedadTercero";
import { InfoAuditoriaPropiedadT } from "../utils/InfoPropiedadT";

type showModalType = (show: boolean, idAutiroria: string) => void
interface ModalAuditoriaPropiedadTPros {
    showModal: showModalType
}

const ModalAuditoriaPropiedadTIncial: ModalAuditoriaPropiedadTPros = {
    showModal: () => { }
}

const btnStyle: React.CSSProperties = {
    width: "32px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    borderRadius: "5px 0px 0px 5px"
}

const ModalAuditoriaPropiedadTContext = createContext<ModalAuditoriaPropiedadTPros>(ModalAuditoriaPropiedadTIncial)

const ModalAuditoriaPropiedadTProvider: FC<WithChildren> = ({
    children
}) => {
    const [show, setShow] = useState(false);
    const [aPropiedadT, setPropiedadT] = useState<IAAnterior<IAPropiedadTercero> | null>(null);

    const obtenerAuditoria = async (idAutiroria: string) => {
        const auditoria = await AuditoriaServicio.AuditoriaPropiedadTerceroAnterior(idAutiroria);
        setPropiedadT(auditoria);
    }

    const showModal: showModalType = (show, idAutiroria) => {
        setShow(show);
        obtenerAuditoria(idAutiroria);
    }

    return (
        <ModalAuditoriaPropiedadTContext.Provider value={{
            showModal
        }}>
            {children}
            {
                show &&
                <Modal show={show} className={`fade modal ${aPropiedadT?.actual?.aTipoAccion === "Insertar" ? "modal-lg" : "modal-xl"}`}>
                    <form
                        className="form"
                        noValidate
                    >
                        <Modal.Header className="p-4">
                            {aPropiedadT === null ? (
                                <div data-kt-indicator='on'>
                                    <span className='indicator-progress'>
                                        <span className='spinner-border spinner-border-sm align-middle'></span>
                                    </span>
                                </div>
                            ) : (
                                <h2 className="modal-title">
                                    {aPropiedadT.actual?.descripcion}
                                </h2>
                            )}
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                aria-label="Close"
                                onClick={() => setShow(false)}
                            >
                                <i className="fs-2 bi bi-x-lg" />
                            </div>
                        </Modal.Header>
                        <Modal.Body className="bg-gray-300 px-6 py-4">
                            {/* begin::modal */}
                            <div className="col-12">
                                <div className="row p-4 row-cols-1 bg-light border rounded-3">
                                    <div className="col-12 p-0 px-5 rounded mb-2">
                                        <h1 className="align-middle">Insertado</h1>
                                    </div>
                                    <div className="row row-cols-2">
                                        {
                                            (aPropiedadT?.actual?.aTipoAccion === "Insertar") ? (
                                                <div className="col-12">
                                                    <div className="row p-4 row-cols-1 bg-light border rounded-3">
                                                        <div className="col-12 p-0 px-5 rounded mb-2">
                                                            <h1 className="align-middle">Insertado</h1>
                                                        </div>
                                                        <div className="row row-cols-2">
                                                            <InfoAuditoriaPropiedadT
                                                                main={aPropiedadT?.actual}
                                                                comparar={false}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    <div className="col-6">
                                                        <div className="row p-4 row-cols-1 bg-light border rounded-3">
                                                            <div className="col-12 p-0 px-5 rounded mb-2">
                                                                <h1 className="align-middle">Anterior</h1>
                                                            </div>
                                                            <div className="row row-cols-2">
                                                                <InfoAuditoriaPropiedadT
                                                                    main={aPropiedadT?.anterior}
                                                                    target={aPropiedadT?.actual}
                                                                    comparar={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-6">
                                                        <div className="row p-4 row-cols-1 bg-light border rounded-3">
                                                            <div className="col-12 p-0 px-5 rounded mb-2">
                                                                <h1 className="align-middle">Nuevo</h1>
                                                            </div>
                                                            <div className="row row-cols-2">
                                                                <InfoAuditoriaPropiedadT
                                                                    main={aPropiedadT?.actual}
                                                                    target={aPropiedadT?.anterior}
                                                                    comparar={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>

                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* end::modal */}
                        </Modal.Body>
                        <Modal.Footer className="p-4">

                        </Modal.Footer>
                    </form>
                </Modal >
            }
        </ModalAuditoriaPropiedadTContext.Provider>
    )
}

const useModalAuditoriaPropiedadT = () => useContext(ModalAuditoriaPropiedadTContext);

export {
    ModalAuditoriaPropiedadTProvider,
    useModalAuditoriaPropiedadT
}
