import axios from "axios";
import excel, { ImageRange } from "exceljs";
import { copyCells } from "../copyCells";
import { addRow } from "../addRow";
import { IPInformacionGeneral } from "./InformeEstadoPagosTypes";
import { ep_fecha_consulta, ep_footer, ep_header, ep_identificacion_tercero, ep_lista_1, ep_lista_2, ep_nombre_tercero } from "./posiciones";
import { obtenerNombreEstadoPago } from "../../../app/shared/misc/BotonesAttributos/EstadoPago";
import { obtenerNombreTipoPago } from "../../../app/shared/misc/BotonesAttributos/TipoPago";
import { IPagoInforme } from "../../../interfaces/pago/IPagoInforme";

export const GenerarInformePagos = async (informacionGeneral: IPInformacionGeneral, pagos: IPagoInforme[]): Promise<ArrayBuffer | null> => {
    try {

        const totalData = pagos.length - 2;

        const { data } = await axios.get<ArrayBuffer>(`${window.origin}/media/excel/excel-informe-pagos.xlsx`, { responseType: 'arraybuffer' });

        const wbBase = new excel.Workbook();
        await wbBase.xlsx.load(data);
        const wsBase = wbBase.getWorksheet("Base");

        const wb = new excel.Workbook();
        const ws = wb.addWorksheet("Informe Pagos");

        const images: Array<{
            type: 'image',
            imageId: string;
            range: ImageRange;
        }> = wsBase.getImages();

        for (const image of images) {
            const img = wbBase.getImage(+image.imageId);
            wb.addImage(img);
        }
        
        // Ancho de las columnas
        for (let i = 1; i <= wsBase.columnCount; i++) {
            const col_base = wsBase.getColumn(i);
            const col = ws.getColumn(i);
            col.width = col_base.width;
        }

        const addImages = (currentRow: number) => {
            wsBase.getImages().forEach(img => {
                img.range.tl.row += currentRow;
                ws.addImage(parseInt(img.imageId), {
                    tl: img.range.tl,
                    ext: {
                        height: 100, width: 100
                    }
                })
            });
        }

        // Header
        const addHeader = () => {
            copyCells(wsBase, ws, ep_header.from, ep_header.to, { x: 1, y: 1 });

            const nombre            = ws.getCell(ep_nombre_tercero);
            const identificacion    = ws.getCell(ep_identificacion_tercero);

            nombre.value = informacionGeneral.nombreTercero;
            identificacion.value = informacionGeneral.identificacionTercero;
            
        }

        // Footer
        const addFooter = (currentRow: number) => {
            copyCells(wsBase, ws, ep_footer.from, ep_footer.to, { x: 1, y: currentRow });
            const fechaConsulta = ws.getCell(ep_fecha_consulta.y + totalData, ep_fecha_consulta.x);
            const totalRecibido     = ws.getCell(currentRow, 10);

            fechaConsulta.value = new Date();totalRecibido.value = {
                formula: `SUM(J10:J${9+pagos.length})`,
                date1904: false
            }
        }

        // Informe edades
        const addPaginaInformePago = (currentRow: number) => {

            pagos.forEach((pago, i) => {
                const from = (i % 2 == 0) ? ep_lista_1.from : ep_lista_2.from;
                const to   = (i % 2 == 0) ? ep_lista_1.to   : ep_lista_2.to;

                copyCells(wsBase, ws, from, to, { x: 1, y: currentRow });

                const _row = ws.getRow(currentRow);
                addRow(_row, 2, [
                    pago.consecutivo,
                    pago.nombreCompleto,
                    pago.propiedad,
                    pago.numeroCuota,
                    obtenerNombreTipoPago(pago.tipoPagoId),
                    obtenerNombreEstadoPago(pago.estadoPagoId),
                    pago.fecha,
                    pago.referenciaPago,
                    pago.valor,
                    pago.interes,
                    pago.capital,
                    pago.abono,
                    pago.mora
                ]);

                currentRow += 1;
            });
            return currentRow;
        }
        
        addImages(1);
        addHeader();
        
        let currentRow = 10;
        currentRow = addPaginaInformePago(currentRow);
        addFooter(currentRow);

        const buffer = await wb.xlsx.writeBuffer();
        return buffer;
    } catch (err) {
        return null;
    }
}