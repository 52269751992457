import { FC, useEffect } from "react";
import { TerceroPagoFormulario } from "./components/TerceroPagoFormulario";
import { TerceroPagoLista } from "./components/TerceroPagoLista";
import { PropiedadProvider, usePropiedad } from "./providers/PropiedadProvider";
import { TerceroPagoEstadisticas } from "./components/TerceroPagoEstadisticas";
import { BotonGuardar } from "../../../../../shared/formularioCliente/utils/BotonGuardar";
import { useAuth } from "../../../../Auth";
import { TerceroPagoInicialLista } from "./components/TerceroPagoInicialLista";
import { ModalAgregarPropiedadProvider } from "./providers/ModalAgregarPropiedad";
import { BotonAtras } from "../../../../../shared/formularioCliente/utils/BotonAtras";
import { useNavigate } from "react-router-dom";
import * as alertify from "alertifyjs";
import { EnumRol } from "../../../../../../interfaces/enums/EnumRol";

const TerceroPropiedades: FC = () => {

    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const { formik } = usePropiedad();

    const submit = () => {
        const errors = Object.values(formik.errors)
        if(errors.length > 0) {
            errors.forEach((err => {
                alertify.error(err)
            }))
            return;
        }
        formik.handleSubmit()
    }

    return (
        <form
            className="form"
            noValidate
        >
            <div className="row mb-5">
                <div className="col-7">
                    <TerceroPagoFormulario />
                </div>
                <div className="col-5">
                    <TerceroPagoEstadisticas />
                </div>
            </div>
            <TerceroPagoInicialLista className="mb-5" />
            <TerceroPagoLista />
            <BotonAtras onClick={() => navigate("/clientes/lista")} />
            {(currentUser?.rolId == EnumRol.Administrador || currentUser?.rolId == EnumRol.Comercial) && <BotonGuardar onClick={submit} />}
        </form>
    )
}

export const TerceroPagosPagina: FC = () => {
    return (
        <PropiedadProvider>
            <ModalAgregarPropiedadProvider >
                <TerceroPropiedades />
            </ModalAgregarPropiedadProvider>
        </PropiedadProvider>
    )
}