import { FC, useEffect, useState } from "react";
import { FormikErrors, FormikProps, FormikTouched } from "formik";
import { ITerceroHijo, ITercero } from "../../../../interfaces/ITercero";
import { BotonIcono } from "../../misc/BotonIcono";
import { Input } from "../../../../_metronic/partials/formulario/Input";
import { useAuth } from "../../../modules/Auth";
import { EnumRol } from "../../../../interfaces/enums/EnumRol";


interface TerceroHijosProps {
    formik: FormikProps<ITercero>
}

export const TerceroHijos: FC<TerceroHijosProps> = ({
    formik
}) => {

    const { currentUser } = useAuth();

    const addHijo = () => {
        const nuevoHijo: ITerceroHijo = {
            colegio: "",
            fechaNacimiento: "",
            nombre: "",
            estadoId: 1
        };
        formik.setFieldValue("hijos", [...(formik.values.hijos ?? []), nuevoHijo])
    }

    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer d-flex justify-content-center align-items-center'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_crear_tercero_hijos'
                aria-expanded='true'
                aria-controls='kt_crear_tercero_hijos'
                style={{ minHeight: "50px" }}
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Hijos</h3>
                </div>
            </div>
            <div id='kt_crear_tercero_hijos' className='collapse show'>
                <div className='card-body border-top px-9 py-2'>
                    <table className="table table-row-dashed table-row-gray-300 gy-7">
                        <thead>
                            <tr className="fw-bolder fs-6 text-gray-800">
                                <th className="text-center col-4">Nombres y apellidos</th>
                                <th className="text-center col-4">Colegio</th>
                                <th className="text-center col-3">Fecha de nacimiento</th>
                                <th className="text-center col-1">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (formik.values.hijos ?? []).map((hijo, i) => (
                                    hijo.estadoId != 3 && <TerceroHijoInputs key={i} index={i} formik={formik} />
                                ))
                            }
                        </tbody>
                    </table>
                    <div className="w-100 h-100 d-flex justify-content-center">
                        <button 
                            disabled={!(currentUser?.rolId == EnumRol.Administrador || currentUser?.rolId == EnumRol.Comercial)} 
                            type='button' 
                            className='btn btn-sm bg-primary text-light' 
                            onClick={addHijo}
                        >
                            Agregar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

interface TerceroHijoInputsProps {
    index: number
    formik: FormikProps<ITercero>
}

const TerceroHijoInputs: FC<TerceroHijoInputsProps> = ({
    index,
    formik
}) => {

    const { currentUser } = useAuth();

    const [touchedHijo, setTouchedHijo] = useState<FormikTouched<ITerceroHijo>>();
    const [errorHijo, setErrorHijo] = useState<FormikErrors<ITerceroHijo>>();

    useEffect(() => {
        if (formik.touched.hijos?.[index]) {
            setTouchedHijo(formik.touched.hijos[index])
        } else {
            setTouchedHijo(undefined)
        }
    }, [formik.touched.hijos?.[index]])

    useEffect(() => {
        if (formik.errors.hijos?.[index]) {
            setErrorHijo((formik.errors.hijos[index] as FormikErrors<ITerceroHijo>))
        } else {
            setErrorHijo(undefined)
        }
    }, [formik.errors.hijos?.[index]])

    const eliminar = () => {
        formik.setValues((tercero) => {
            if(tercero.id){
                tercero.hijos[index].estadoId = 3;
            }else{
                tercero.hijos.splice(index, 1);
            }
            return { ...tercero }
        });
    }

    return (
        <tr>
            <td className="p-1">
                <Input
                    disabled={!(currentUser?.rolId == EnumRol.Administrador || currentUser?.rolId == EnumRol.Comercial)}
                    autoCapitalLetter
                    placeholder="Nombres y apellidos *"
                    props={formik.getFieldProps(`hijos[${index}].nombre`)}
                    validProp={touchedHijo?.nombre}
                    error={errorHijo?.nombre}
                />
            </td>
            <td className="p-1">
                <Input
                    disabled={!(currentUser?.rolId == EnumRol.Administrador || currentUser?.rolId == EnumRol.Comercial)}
                    autoCapitalLetter
                    placeholder="Colegio *"
                    props={formik.getFieldProps(`hijos[${index}].colegio`)}
                    validProp={touchedHijo?.colegio}
                    error={errorHijo?.colegio}
                />
            </td>
            <td className="p-1">
                <Input
                    disabled={!(currentUser?.rolId == EnumRol.Administrador || currentUser?.rolId == EnumRol.Comercial)}
                    type="date"
                    props={formik.getFieldProps(`hijos[${index}].fechaNacimiento`)}
                    validProp={touchedHijo?.fechaNacimiento}
                    error={errorHijo?.fechaNacimiento}
                />
            </td>
            <td className="p-1">
                <div className='mb-2 col p-2 d-flex justify-content-center align-items-center'>
                    <BotonIcono
                        disabled={!(currentUser?.rolId == EnumRol.Administrador || currentUser?.rolId == EnumRol.Comercial)}
                        icon="bi-trash3"
                        onClick={eliminar}
                    />
                </div>
            </td>
        </tr>
    )
}

