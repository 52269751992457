import * as Yup from 'yup'

export const terceroPropiedadSchema = Yup.object().shape({
    propiedadId: Yup.string()
        .required('Campo requerido.'),
    descripcion: Yup.string()
        .required(),
    areaPropiedad: Yup.number()
        .min(1, 'El área  de la propiedad no puede ser 0.')
        .required('Campo requerido.')
        .typeError('Formato incorrecto.'),
    interesAnual: Yup.number()
        .min(0, 'El interés anual no puede ser menor a 0.')
        .required('El interés anual es requerido.')
        .typeError('Formato incorrecto.'),
    cantidadPagos: Yup.number()
        .min(1, 'La cantidad de pagos no puede ser 0.')
        .required('Campo requerido.')
        .typeError('Formato incorrecto.'),
    fechaLimitePagoInicial: Yup.date()
        .required('Fecha límite de pago inicial es requerido.'),
    fechaInicioFinanciacion: Yup.date()
        .required('Fecha inicio de financiación es requerido.'),
    valorTotal: Yup.number()
        .min(1, 'El valor total debe ser mayor a 0.')
        .required('Campo requerido.')
});