import { FC } from "react";
import clsx from "clsx";
import { FieldInputProps } from "formik";

export interface InputNumericoProps {
    nombre?: string
    placeholder?: string
    props: FieldInputProps<string | number | readonly string[] | undefined>
    validProp?: boolean | undefined
    error?: string | undefined
    requerido?: boolean
    title?: string
    disabled?: boolean
    validate?: boolean
    showError?: boolean
    className?: string
}

export const InputNumerico: FC<InputNumericoProps> = ({ 
    props,
    nombre,
    placeholder,
    error,
    validProp,
    requerido,
    title,
    validate = true,
    disabled = false,
    className = ""
}) => {

    const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        var number = e.target.value;

        var valorFormateado = number.replace(/\D/g, '').replace(/^0+(?!$)/g, '');

        e.target.value = valorFormateado;
        props.onChange(e);
    };

    const handleInputBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
        var number = e.target.value;
        if(!number || number == "") number = "0";
        
        e.target.value = number;
        props.onChange(e);
    }

    return (
        <div className={`mb-2 p-1 ${className}`}>
            {nombre && <label className='form-label fs-6 fw-bolder text-dark' title={title}>{nombre} {(requerido) ? <span className="text-danger">*</span> : ""}</label>}
            <input
                disabled={disabled}
                placeholder={placeholder}
                name={props.name}
                onBlur={handleInputBlur}
                onChange={handleInputChange}
                value={props.value ?? ""}
                className={clsx(
                    'form-control form-control-sm bg-transparent',
                    { 'is-invalid': validate && validProp && error },
                    {
                        'is-valid': validate && validProp && !error,
                    }
                )}

            />
            {validate && validProp && error && (
                <div className='fv-plugins-message-container'>
                    <span className="text-danger" role='alert'>{error}</span>
                </div>
            )}
        </div>
    );
};