import { FC, useEffect, useState } from "react";
import { ListViewProvider } from "../../../shared/providers/ListView/ListView";
import { KTCard } from "../../../../_metronic/helpers";
import { AuditoriaPropiedadCLista } from './components/AuditoriaPropiedadCLista';
import { AuditoriaServicio } from "../../../../servicios/auditoriaServicio";
import { AuditoriaPropiedadCHeader } from "./components/AuditoriaPropiedadCHeader";
import { IAPropiedadCaracteristica } from "../../../../interfaces/auditorias/IAPropiedadCaracteristica";
import { ModalAuditoriaPropiedadCProvider } from "./providers/ModalAuditoriaPropiedadC";

export const AuditoriaPropiedadCManagament: FC = () => {

    const [aPropiedadesCaracteristicas, setAPropiedadesCaracteristicas] = useState<IAPropiedadCaracteristica[]>([]);

    useEffect(() => {
        const obtenerAuditoriaUsuarios = async () => {
            const aPropiedadesCaracteristicas = await AuditoriaServicio.AuditoriaPropiedadCaracteristica();
            setAPropiedadesCaracteristicas(aPropiedadesCaracteristicas);
        }
        obtenerAuditoriaUsuarios();
    }, []);

    return (
        <KTCard>
            <ListViewProvider initialData={aPropiedadesCaracteristicas} >
                <ModalAuditoriaPropiedadCProvider>
                    <AuditoriaPropiedadCHeader />
                    <AuditoriaPropiedadCLista />
                </ModalAuditoriaPropiedadCProvider>
            </ListViewProvider>
        </KTCard>
    )
}
