import { FC } from "react";
import { BotonIcon } from "../../../../shared/misc/BotonOpenModal";
import { useModalAuditoriaPago } from "../providers/ModalAuditoriaPago";
import { IAPago } from "../../../../../interfaces/auditorias/IAPago";

interface BotonTerceroContactoProps {
    aPago: IAPago
}
export const BotonOpenModalPago: FC<BotonTerceroContactoProps> = ({
    aPago
}) => {

    const { showModal } = useModalAuditoriaPago();

    return <BotonIcon icon="bi-eye-fill" tooltip="Ver pago completo" onClick={() => showModal(true, aPago)} />
}