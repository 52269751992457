import { FormikTouched, FormikErrors, FormikProps } from "formik";
import { FC, useState, useEffect } from "react";
import { ITerceroMascota, ITercero } from "../../../../interfaces/ITercero";
import { datosTerceroMascotaIniciales } from "../../datosFormularios/DatosInicialesFormularios";
import { mascotas } from "../../datosFormularios/Informacion";
import { BotonIcono } from "../../misc/BotonIcono";
import { Input } from "../../../../_metronic/partials/formulario/Input";
import { Select } from "../../../../_metronic/partials/formulario/Select";
import { useAuth } from "../../../modules/Auth";
import { EnumRol } from "../../../../interfaces/enums/EnumRol";

interface TerceroMascotaProps {
    formik: FormikProps<ITercero>
}

export const TerceroMascotas: FC<TerceroMascotaProps> = ({
    formik
}) => {

    const { currentUser } = useAuth();

    const addMascota = () => {
        formik.setFieldValue("mascotas", [...(formik.values.mascotas ?? []), datosTerceroMascotaIniciales])
    }

    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer d-flex justify-content-center align-items-center'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_crear_tercero_mascotas'
                aria-expanded='true'
                aria-controls='kt_crear_tercero_mascotas'
                style={{ minHeight: "50px" }}
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Mascotas</h3>
                </div>
            </div>
            <div id='kt_crear_tercero_mascotas' className='collapse show'>
                <div className='card-body border-top px-9 py-2'>
                    <table className="table table-row-dashed table-row-gray-300 gy-7">
                        <thead>
                            <tr className="fw-bolder fs-6 text-gray-800">
                                <th className="text-center col-4">Nombres</th>
                                <th className="text-center col-2">Mascota</th>
                                <th className="text-center col-4">Tipo de mascota</th>
                                <th className="text-center col-1">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (formik.values.mascotas ?? []).map((mascota, i) => (
                                    mascota.estadoId != 3 && <TerceroMascotaInputs key={i} index={i} formik={formik} />
                                ))
                            }
                        </tbody>
                    </table>
                    <div className="w-100 h-100 d-flex justify-content-center">
                        <button 
                            disabled={!(currentUser?.rolId == EnumRol.Administrador || currentUser?.rolId == EnumRol.Comercial) || formik.errors.mascotas ? true : false}
                            type='button'
                            className='btn btn-sm bg-primary text-light'
                            onClick={addMascota}
                        >
                            Agregar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

interface TerceroMascotaInputsProps {
    index: number,
    formik: FormikProps<ITercero>
}

const TerceroMascotaInputs: FC<TerceroMascotaInputsProps> = ({
    index,
    formik
}) => {

    const { currentUser } = useAuth();
    const [touchedMascota, setTouchedMascota] = useState<FormikTouched<ITerceroMascota>>();
    const [errorMascota, setErrorMascota] = useState<FormikErrors<ITerceroMascota>>();

    useEffect(() => {
        if (formik.touched.mascotas?.[index]) {
            setTouchedMascota(formik.touched.mascotas[index])
        } else {
            setTouchedMascota(undefined);
        }
    }, [formik.touched.mascotas?.[index]])

    useEffect(() => {
        if (formik.errors.mascotas?.[index]) {
            setErrorMascota((formik.errors.mascotas[index] as FormikErrors<ITerceroMascota>))
        } else {
            setErrorMascota(undefined);
        }
    }, [formik.errors.mascotas?.[index]])

    const eliminar = () => {
        formik.setValues((tercero) => {
            tercero.mascotas[index].estadoId = 3;
            return { ...tercero }
        });
    }

    return (
        <tr>
            <td className="p-2">
                <Input
                    disabled={!(currentUser?.rolId == EnumRol.Administrador || currentUser?.rolId == EnumRol.Comercial)}
                    autoCapitalLetter
                    placeholder="Nombre *"
                    props={formik.getFieldProps(`mascotas[${index}].nombre`)}
                    validProp={touchedMascota?.nombre}
                    error={errorMascota?.nombre}
                />
            </td>
            <td className="p-2">
                <Select
                    disabled={!(currentUser?.rolId == EnumRol.Administrador || currentUser?.rolId == EnumRol.Comercial)}
                    placeholder="Tipo de mascota *"
                    options={mascotas}
                    props={formik.getFieldProps(`mascotas[${index}].mascota`)}
                    validProp={touchedMascota?.mascota}
                    error={errorMascota?.mascota}
                />
            </td>
            <td className="p-2">
                <Input
                    disabled={!(currentUser?.rolId == EnumRol.Administrador || currentUser?.rolId == EnumRol.Comercial)}
                    autoCapitalLetter
                    placeholder="Tipo mascota *"
                    props={formik.getFieldProps(`mascotas[${index}].raza`)}
                    validProp={touchedMascota?.raza}
                    error={errorMascota?.raza}
                />
            </td>
            <td className="p-2 d-flex justify-content-end">
                <div className='mb-2 col p-2 d-flex justify-content-center align-items-center'>
                    <BotonIcono 
                        disabled={!(currentUser?.rolId == EnumRol.Administrador || currentUser?.rolId == EnumRol.Comercial)}
                        icon="bi-trash3"
                        onClick={eliminar}
                    />
                </div>
            </td>
        </tr>
    )
}

