import { FC, useState, useEffect } from "react";
import { KTCard } from "../../../../_metronic/helpers";
import { AuditoriaServicio } from "../../../../servicios/auditoriaServicio";
import { ListViewProvider } from "../../../shared/providers/ListView/ListView";
import { AuditoriaPagoHeader } from "./components/AuditoriaPagoHeader";
import { AuditoriaPagoLista } from "./components/AuditoriaPagoLista";
import { ModalAuditoriaPagoProvider } from "./providers/ModalAuditoriaPago";
import { IAPago } from "../../../../interfaces/auditorias/IAPago";


export const AuditoriaPago: FC = () => {

    const [aUsuarios, setAUsuarios] = useState<IAPago[]>([]);

    useEffect(() => {
        const obtenerAuditoriaPagos = async () => {
            const aPagos = await AuditoriaServicio.AuditoriaPagos();
            setAUsuarios(aPagos);
        }
        obtenerAuditoriaPagos();
    }, []);

    return (
        <KTCard>
            <ListViewProvider initialData={aUsuarios} >
                <ModalAuditoriaPagoProvider>
                    <AuditoriaPagoHeader />
                    <AuditoriaPagoLista />
                </ModalAuditoriaPagoProvider>
            </ListViewProvider>
        </KTCard>
    )
}
