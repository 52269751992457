import { FC } from 'react'

import { InformacionProvider } from '../../../shared/providers/InformacionProvider'
import { TerceroInformacionPersonal } from '../../../shared/formularioCliente/components/TerceroInformacionPersonal';
import { TerceroDatosContacto } from '../../../shared/formularioCliente/components/TerceroDatosContacto';
import { TerceroDireccion } from '../../../shared/formularioCliente/components/TerceroDireccion';
import { TerceroHijos } from '../../../shared/formularioCliente/components/TerceroHijos';
import { TerceroMascotas } from '../../../shared/formularioCliente/components/TerceroMascotas';
import { TerceroInformacionPareja } from '../../../shared/formularioCliente/components/TerceroInformacionPareja';
import { BotonGuardar } from '../../../shared/formularioCliente/utils/BotonGuardar';
import { FormularioProvider, useFormulario } from './FormularioProvider';
import { useNavigate } from 'react-router-dom';
import { BotonAtras } from '../../../shared/formularioCliente/utils/BotonAtras';

const AgregarCiente: FC = () => {
  const navigate = useNavigate();
  const { formik } = useFormulario();

  return (
    <div className='w-100 d-flex justify-content-center'>
      <div style={{ maxWidth: "700px" }}>
        <TerceroInformacionPersonal formik={formik} />
        <TerceroDatosContacto formik={formik} />
        <TerceroDireccion formik={formik} />
        <TerceroHijos formik={formik} />
        <TerceroMascotas formik={formik} />
        <TerceroInformacionPareja formik={formik} tercero={formik.values} />
        <BotonAtras onClick={() => navigate("/clientes/lista")} />
        <BotonGuardar onClick={() => formik.handleSubmit()} />
      </div>
    </div>
  )
}

export function AgregarCientePage() {
  return (
    <InformacionProvider>
      <FormularioProvider>
        <AgregarCiente />
      </FormularioProvider>
    </InformacionProvider>
  )
}
