import { IPropiedadCaracteristicas } from "../../../interfaces/propiedades/IPropiedadCaracteristicas"
import { IPropiedadesTercero } from "../../../interfaces/propiedades/IPropiedadesTercero"
import { IDatosPersonales, ITerceroMascota, ITercero } from "../../../interfaces/ITercero"

export const datosPersonalesIniciales: IDatosPersonales = {
    tipoDocumentoId: "",
    identificacion: "",
    primerNombre: "",
    otrosNombres: "",
    primerApellido: "",
    segundoApellido: "",
    profesion: "",
    cargo: "",
    municipioId: "",
    barrio: "",
    direccion: "",
    contacto: "",
    correo: "",
    estadoId: 1
}

export const datosTerceroIniciales: ITercero = {
    id: "",
    ...datosPersonalesIniciales,
    contactoWhatsapp: "",
    codigoActividad: "0",
    nombreComercial: "",
    estadoCivilId: "",
    alias: "",
    hijos: [],
    mascotas: [],
    pareja: datosPersonalesIniciales
}

export const datosTerceroMascotaIniciales: ITerceroMascota = {
    nombre: "",
    mascota: "",
    raza: "",
    estadoId: 1
}

export const propiedadDatosIniciales: IPropiedadCaracteristicas = {
    id: "",
    propiedadId: "",
    descripcion: "",
    areaPropiedad: 0,
    interesAnual: 0,
    valorM2: 0,
    valorTotal: 0
}

export const propiedadTerceroIniciales: IPropiedadesTercero = {
    id: "",
    propiedadId: "",
    terceroId: "",
    descripcion: "",
    areaPropiedad: 0,
    interesAnual: 0,
    pagoInicial: 0,
    valorM2: 0,
    cantidadPagos: 0,
    porcentajeMora: 0,
    fechaLimitePagoInicial: "",
    fechaInicioFinanciacion: "",
    valorTotal: 0,
    periodoId: 1
}