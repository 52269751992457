import { useNavigate } from "react-router-dom"
import { KTIcon } from "../../../../../../_metronic/helpers";
import { useListView } from "../../../../../shared/providers/ListView/ListView";
import { TercerosServicios } from "../../../../../../servicios/tercerosServicios";
import { useLayout } from "../../../../../../_metronic/layout/core";
import clsx from "clsx";
import { useAuth } from "../../../../Auth";
import { BotonActualizar } from "../../../../../shared/misc/BotonActualizar";
import { EnumRol } from "../../../../../../interfaces/enums/EnumRol";


export const ListaBotonesComponent = () => {
    const navigate = useNavigate();

    const { currentUser } = useAuth();
    const { config } = useLayout()
    const daterangepickerButtonClass = config.app?.toolbar?.fixed?.desktop
        ? 'btn-light'
        : 'bg-body btn-color-gray-700 btn-active-color-primary'

    const { setData: setAllData, setCargandoDatos } = useListView();

    const obtenerUsuarios = async () => {
        setCargandoDatos(true);
        const usuarios = await TercerosServicios.ObtenerTodos();
        setAllData(usuarios);
    }

    return (
        <div className='d-flex justify-content-end align-items-center' data-kt-user-table-toolbar='base'>
            <div className='m-0'>
                <BotonActualizar onClick={obtenerUsuarios} />
            </div>
            {
                (currentUser?.rolId === EnumRol.Administrador || currentUser?.rolId === EnumRol.SuperAdmin) && (
                    <button type='button' className='btn btn-sm btn-light-primary' onClick={() => navigate("/clientes/crear")}>
                        <i className="fs-2 bi bi-person-fill-add"></i>
                        Agregar Cliente
                    </button>
                )
            }
        </div>
    )
}