import { Worksheet } from "exceljs";
import { Pos } from "./estadoCuenta/types";
import { addressToPos } from "./addresToPos";

export const mergeCells = (wsBase: Worksheet, ws: Worksheet, from: Pos, to: Pos, copyTo: Pos) => {

    for (let i = from.y, ii = copyTo.y; i <= to.y; i++, ii++) {
        let posToMerge: { from: Pos, to: Pos } | null = null;
        for (let j = from.x, jj = copyTo.x; j <= to.x; j++, jj++) {
            const currentCell = wsBase.getRow(i).getCell(j).model;

            if (currentCell.master) {
                if (posToMerge == null) {
                    const from = addressToPos(currentCell.master);
                    const to = addressToPos(currentCell.address as unknown as string);
                    from.x += copyTo.x - 1;
                    from.y  = ii;
                    to.x   += copyTo.x - 1;
                    to.y   = ii;
                    posToMerge = { from, to };
                } else {
                    const to = addressToPos(currentCell.address as unknown as string);
                    to.x   += copyTo.x - 1;
                    to.y   = ii;
                    posToMerge.to = to;
                }
            } else {
                if (posToMerge != null) {
                    ws.mergeCells(posToMerge.from.y, posToMerge.from.x, posToMerge.to.y, posToMerge.to.x);
                    posToMerge = null;
                }
            }
        }
    }
}