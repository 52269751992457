import { FC } from "react";
import { ListaPagosToolbar } from "./ListaPagosToolBar";

export const ListaPagosHeader: FC = () => {
    
    return (
        <div className='card-header border-0 py-4 flex justify-content-end'>
            {/* <BuscarComponent placeholder="Buscar" /> */}
            <div className='card-toolbar'>
                <ListaPagosToolbar />
            </div>
        </div>
    )
}