import { useListView } from "../../../../shared/providers/ListView/ListView";
import { AuditoriaServicio } from "../../../../../servicios/auditoriaServicio";
import { DropDownAccionFilter } from "../../utils/DropDownAccionFilter";
import { BotonActualizar } from "../../../../shared/misc/BotonActualizar";
import { BotonFiltro } from "../../../../shared/misc/BotonFiltro";

export const ListaBotonesComponent = () => {

    const { setData: setAllData, setCargandoDatos } = useListView();

    const obtenerUsuarios = async () => {
        setCargandoDatos(true);
        const usuarios = await AuditoriaServicio.AuditoriaUsuario();
        setAllData(usuarios);
    }

    return (
        <div className='d-flex justify-content-end align-items-center' data-kt-user-table-toolbar='base'>
            <div className='m-0'>
                <BotonActualizar onClick={obtenerUsuarios} />
            </div>
            <div className='m-0'>
                <BotonFiltro />
                <DropDownAccionFilter filtroNombre="Acción" filtros={["Modificar", "Insertar"]} />
            </div>
        </div>
    )
}