import { FC } from "react";
import { usePropiedad } from "../providers/PropiedadProvider";
import { useTercero } from "../../../provider/TerceroProvider";
import { roles, tipoPeriodo } from "../../../../../../shared/datosFormularios/Informacion";
import { Input } from "../../../../../../../_metronic/partials/formulario/Input";
import { InputDecimal } from "../../../../../../../_metronic/partials/formulario/InputDecimal";
import { InputEntero } from "../../../../../../../_metronic/partials/formulario/InputEntero";
import { InputMonetario } from "../../../../../../../_metronic/partials/formulario/InputMonetario";
import { InputNoEditable } from "../../../../../../../_metronic/partials/formulario/InputNoEditable";
import { Select, SelectAdd } from "../../../../../../../_metronic/partials/formulario/Select";
import { useModalAgregarPropiedad } from "../providers/ModalAgregarPropiedad";
import { useAuth } from "../../../../../Auth";
import { EnumRol } from "../../../../../../../interfaces/enums/EnumRol";

export const TerceroPagoFormulario: FC = () => {

    const { currentUser } = useAuth();
    const { formik, propiedad } = usePropiedad();
    const { propiedades } = useTercero();
    const { showModal } = useModalAgregarPropiedad();

    return (
        <>
            <div className="card p-0 mb-3">
                <div className='card-body border-top py-3 px-9'>
                    <div className="row row-cols-2">
                        <SelectAdd
                            disabledButton={currentUser?.rolId != EnumRol.Administrador}
                            onClick={() => showModal(true)}
                            className="col-12"
                            nombre="Propiedad"
                            options={propiedades.map(p => ({
                                nombre: p.descripcion,
                                opcion: p.descripcion,
                                valor: p.id
                            }))}
                            props={formik.getFieldProps('id')}
                            validProp={formik.touched.id}
                            error={formik.errors.id}
                            setFieldValue={formik.setFieldValue}
                            tooltip="Agregar nueva propiedad"
                        />
                    </div>
                </div>
            </div>
            <div className='card p-0'>
                <div className='card-body border-top py-3 px-9'>
                    <div className="row">
                        <Input
                            disabled={currentUser?.rolId != EnumRol.Administrador}
                            validate={false}
                            nombre="Fecha limite pago inicial "
                            type="date"
                            className="col-6"
                            props={formik.getFieldProps('fechaLimitePagoInicial')}
                            validProp={formik.touched.fechaLimitePagoInicial as boolean}
                            error={formik.errors.fechaLimitePagoInicial as string}
                        />
                        <Input
                            disabled={currentUser?.rolId != EnumRol.Administrador}
                            validate={false}
                            nombre="Fecha inicio financiación"
                            type="date"
                            className="col-6"
                            min={formik.values.fechaLimitePagoInicial}
                            props={formik.getFieldProps('fechaInicioFinanciacion')}
                            validProp={formik.touched.fechaInicioFinanciacion as boolean}
                            error={formik.errors.fechaInicioFinanciacion as string}
                        />
                        <InputNoEditable
                            disabled
                            nombre={"Área"}
                            className="col-6"
                            value={formik.values.areaPropiedad}
                        />
                        <InputMonetario
                            disabled={currentUser?.rolId != EnumRol.Administrador}
                            validate={false}
                            className="col-6"
                            nombre={"Total sin intereses"}
                            min={0}
                            setFieldValue={formik.setFieldValue}
                            props={formik.getFieldProps('valorTotal')}
                            validProp={formik.touched.valorTotal}
                            error={formik.errors.valorTotal}
                        />
                        <InputDecimal
                            disabled={currentUser?.rolId != EnumRol.Administrador}
                            validate={false}
                            min={0}
                            max={100}
                            className="col-6"
                            nombre={"Interés anual %"}
                            setFieldValue={formik.setFieldValue}
                            props={formik.getFieldProps('interesAnual')}
                            validProp={formik.touched.interesAnual}
                            error={formik.errors.interesAnual}
                        />
                        <InputDecimal
                            disabled={currentUser?.rolId != EnumRol.Administrador}
                            validate={false}
                            min={0}
                            max={100}
                            className="col-6"
                            nombre={"Porcentaje mora %"}
                            setFieldValue={formik.setFieldValue}
                            props={formik.getFieldProps('porcentajeMora')}
                            validProp={formik.touched.porcentajeMora}
                            error={formik.errors.porcentajeMora}
                        />
                        <Select
                            disabled={currentUser?.rolId != EnumRol.Administrador}
                            validate={false}
                            className="col-6"
                            options={tipoPeriodo}
                            nombre="Periodo de pago"
                            props={formik.getFieldProps('periodoId')}
                            validProp={formik.touched.periodoId}
                            error={formik.errors.periodoId}
                        />
                        <InputEntero
                            disabled={currentUser?.rolId != EnumRol.Administrador}
                            validate={false}
                            className="col-6"
                            min={0}
                            max={150}
                            nombre="Cantidad de pagos"
                            setFieldValue={formik.setFieldValue}
                            props={formik.getFieldProps('cantidadPagos')}
                            validProp={formik.touched.cantidadPagos}
                            error={formik.errors.cantidadPagos}
                        />
                        <InputMonetario
                            disabled={currentUser?.rolId != EnumRol.Administrador}
                            validate={false}
                            className="col-6"
                            setFieldValue={formik.setFieldValue}
                            nombre={"Pago inicial"}
                            min={0}
                            max={propiedad.valorTotalLote}
                            props={formik.getFieldProps('pagoInicial')}
                            validProp={formik.touched.pagoInicial}
                            error={formik.errors.pagoInicial}
                        />
                        <InputNoEditable
                            nombre={"Valor por m2"}
                            className="col-6"
                            value={formik.values.valorM2.formatoMoneda2(true)}
                        />
                    </div>

                </div>
            </div>
        </>

    )
}