import { FC, useState } from "react"
import clsx from "clsx";
import { KTIcon } from "../../../../../_metronic/helpers";
import { Select } from "../../../../../_metronic/partials/formulario/Select";
import { tiposDePago } from "../../../../shared/datosFormularios/Informacion";
import { useListView } from "../../../../shared/providers/ListView/ListView";

const TipoDeAccion = ["Todos", "Eliminar", "Confirmar", "Insertar"]

export const AuditoriaPagoDropDownFilter: FC = () => {

    const { addFilter, removeFilters } = useListView();

    const [accion, setAccion] = useState(0);
    const [tipoPagoId, setTipoPagoId] = useState(0);

    const cambiarFiltros = () => {
        if (accion == 0) removeFilters("aTipoAccion");
        else addFilter("aTipoAccion", TipoDeAccion[accion]);

        if (tipoPagoId == 0) removeFilters("tipoPagoId");
        else addFilter("tipoPagoId", tipoPagoId);
    }

    const quitarFiltros = () => {
        setAccion(0);
        setTipoPagoId(0);
    }

    return (
        <div className='menu menu-sub menu-sub-dropdown w-200px w-md-250px' data-kt-menu='true'>
            <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Filtros</div>
            </div>

            <div className='separator border-gray-200'></div>

            <div className='px-7'>
                {
                    <Select
                        nombre="Tipo de pago"
                        placeholder="Tipo de pago"
                        className="col-12"
                        options={[{ nombre: "Todos", valor: 0 }, ...tiposDePago].map(p => ({
                            nombre: p.nombre,
                            valor: p.valor
                        }))}
                        props={{
                            name: "",
                            onBlur: () => { },
                            onChange: (e) => {
                                setTipoPagoId(parseInt(e.target.value))
                            },
                            value: tipoPagoId
                        }}
                    />
                }
            </div>
            <div className='px-7'>
                {
                    <Select
                        nombre="Acción"
                        placeholder="Tipo acción"
                        options={[
                            {nombre: "Todos", valor: 0},
                            {nombre: "Eliminar", valor: 1},
                            {nombre: "Confirmar", valor: 2},
                            {nombre: "Insertar", valor: 3},
                        ]}
                        props={{
                            name: "",
                            onBlur: () => { },
                            onChange: (e) => {
                                setAccion(e.target.value)
                            },
                            value: accion
                        }}
                    />
                }
            </div>
            <div className='d-flex justify-content-end mb-5 px-8'>
                <button type='button' className={clsx('btn btn-sm btn-light btn-active-light-primary me-2')} onClick={quitarFiltros}>
                    <KTIcon iconName='arrow-circle-right' className='fs-1' />
                    Limpiar
                </button>
                <button
                    type='submit'
                    className='btn btn-sm btn-primary'
                    data-kt-menu-dismiss='true'
                    onClick={cambiarFiltros}
                >
                    Aplicar
                </button>
            </div>
        </div>
    )
}