import { FC } from "react"
import { InputInformativo } from "../../../../shared/misc/InputInformativo"
import { IAPago } from "../../../../../interfaces/auditorias/IAPago"

interface InfoAuditoriaUsuarioProps {
    pago: IAPago
}

export const InfoAuditoriaPago: FC<InfoAuditoriaUsuarioProps> = ({
    pago
}) => {

    return (
        <>
            <InputInformativo
                nombre="Propiedad"
                icon="bi-credit-card"
                text={"Lote 14"}
            />
            <InputInformativo
                nombre="Referencia de pago"
                icon="bi-credit-card"
                text={pago.referenciaPago}
            />
            <InputInformativo
                nombre="Fecha de pago"
                icon="bi-credit-card"
                text={pago.fecha.formato()}
            />
            <InputInformativo
                nombre="Valor pago"
                icon="bi-credit-card"
                text={pago.valor.formatoMoneda2(true)}
            />
            <InputInformativo
                nombre="Intereses"
                icon="bi-credit-card"
                text={pago.interes.formatoMoneda2(true)}
            />
            <InputInformativo
                nombre="Capital"
                icon="bi-credit-card"
                text={pago.capital.formatoMoneda2(true)}
            />
            <InputInformativo
                nombre="Abono"
                icon="bi-credit-card"
                text={pago.abono.formatoMoneda2(true)}
            />
            <InputInformativo
                nombre="Mora"
                icon="bi-credit-card"
                text={pago.mora.formatoMoneda2(true)}
            />
        </>
    )
}