import { IPropiedadAsignar } from "../interfaces/propiedades/IPropiedadAsignar";
import { IPropiedadGet } from "../interfaces/propiedades/IPropiedadGet";
import { IPropiedadSelect } from "../interfaces/propiedades/IPropiedadSelect";
import { IPropiedadTerceroActualizar } from "../interfaces/propiedades/IPropiedadTerceroActualizar";
import { IPropiedadCaracteristicas } from "../interfaces/propiedades/IPropiedadCaracteristicas";
import { axios } from "./axiosInstance";

export class PropiedadServicio {

    public static ObtenerTodos = () : Promise<IPropiedadCaracteristicas[]> => {
        return new Promise(async(res, rej)=>{
            try{
                const propiedades = await axios.get<IPropiedadCaracteristicas[]>('/propiedad/lista')
                res(propiedades);
            }catch(err){
                res([]);
            }
        });
    }

    public static ObtenerNoAsignados = () : Promise<IPropiedadSelect[]> => {
        return new Promise(async(res, rej)=>{
            try{
                const propiedades = await axios.get<IPropiedadSelect[]>('/propiedad/select')
                res(propiedades);
            }catch(err){
                res([]);
            }
        });
    }

    public static ObtenerPorId = (id: string) : Promise<IPropiedadGet | null> => {
        return new Promise(async(res, rej)=>{
            try{
                const propiedad = await axios.get<IPropiedadGet>(`/propiedad-caracteristica?PropiedadId=${id}`)
                res(propiedad);
            }catch(err){
                res(null);
            }
        });
    }

    public static Actualizar = (propiedad: IPropiedadCaracteristicas) : Promise<void> => {
        return new Promise(async(res, rej)=>{
            try{
                await axios.post('/propiedad-caracteristica', propiedad);
                res();
            }catch(err){
                rej(err);
            }
        });
    }

    public static Asignar = (propiedad: IPropiedadAsignar) : Promise<void> => {
        return new Promise(async(res, rej)=>{
            try{
                await axios.post('/propiedad-tercero', propiedad);
                res();
            }catch(err){
                rej(err);
            }
        });
    }

    public static ActualizarAsignacion = (propiedad: IPropiedadTerceroActualizar) : Promise<void> => {
        return new Promise(async(res, rej)=>{
            try{
                await axios.put('/propiedad-tercero', propiedad);
                res();
            }catch(err){
                rej(err);
            }
        });
    }

}