import { CardsWidget17 } from "../../../_metronic/partials/widgets/cards/CardsWidget17"
import { useDashboard } from "./hooks/useDashboard"
import { ChartsWidget2 } from "../../../_metronic/partials/widgets/charts/ChartsWidget2";


export const Dashboard = () => {

    const { totalLotesVendidos, totalVentas, informeArea } = useDashboard();

    return (
        <>
            {/* begin::Row */}
            <div className='row g-5 g-xl-10'>
                <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
                    <CardsWidget17
                        className=''
                        title="Total ventas"
                        chartSize={80}
                        chartLine={15}
                        total={totalVentas.total}
                        data={totalVentas.data}
                    />
                </div>
                {/* 
                <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
                    <CardsWidget20
                        className='mb-5 mb-xl-10'
                        description='Propiedad(es) vendidas'
                        color='#F1416C'
                        img={toAbsoluteUrl('/media/patterns/vector-1.png')}
                        cantidad={totalLotesVendidos.vendidas}
                        cantidadMaxima={totalLotesVendidos.total}
                    />
                </div> */}
                <div className='col-xxl-6'>

                </div>
                {/* end::Col */}
            </div>
            {/* end::Row */}

            {/* begin::Row */}
            <div className='row gx-5 gx-xl-10'>
                {/* begin::Col */}
                
                <div className='col-md-12 col-lg-12 col-xl-6 mb-md-5 mb-xl-10'>
                    <ChartsWidget2
                        className=''
                        title="Lotes vendidos por m2"
                        subTitle={`${totalLotesVendidos.vendidas} lotes vendidos de ${totalLotesVendidos.total}`}
                        data={informeArea.data}
                        labels={informeArea.labels}
                    />
                </div>
                {/* end::Col */}

                {/* begin::Col */}
                <div className='col-xxl-6 mb-5 mb-xl-10'>

                </div>
                {/* end::Col */}
            </div>
            {/* end::Row */}

            {/* begin::Row */}
            <div className='row gy-5 gx-xl-8'>
                <div className='col-xxl-4'>

                </div>
                <div className='col-xl-8'>

                </div>
            </div>
            {/* end::Row */}

            {/* begin::Row */}
            <div className='row gy-5 g-xl-8'>
                <div className='col-xl-4'>

                </div>
                <div className='col-xl-4'>

                </div>
                <div className='col-xl-4'>

                </div>
            </div>
            {/* end::Row */}

            <div className='row g-5 gx-xxl-8'>
                <div className='col-xxl-4'>

                </div>
                <div className='col-xxl-8'>

                </div>
            </div>
        </>
    )
}