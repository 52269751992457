import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { DashboardWrapper } from '../pages/DashboardWrapper'
import { TercerosManagamentWrapper } from '../pages/TercerosManagamentWrapper'
import { AgregarTerceroWrapper } from '../pages/AgregarTerceroWrapper'
import { UsuariosManagamentWrapper } from '../pages/UsuariosManagamentWrapper'
import { AuditoriasWrapper } from '../pages/AuditoriasWrapper'
import { TerceroCuentaWrapper } from '../pages/TerceroCuentaWrapper'
import { PropiedadWrapper } from '../pages/PropiedadWrapper'
import { useAuth } from '../modules/Auth'
import { InformesWrapper } from '../modules/Informes/Informes'
import { EnumRol } from '../../interfaces/enums/EnumRol'

const PrivateRoutes = () => {

  const { currentUser } = useAuth();

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='clientes/lista' element={<TercerosManagamentWrapper />} />
        {
          (currentUser?.rolId === EnumRol.Administrador || currentUser?.rolId === EnumRol.SuperAdmin) && (
            <Route path='clientes/crear' element={<AgregarTerceroWrapper />} />
          )
        }
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='clientes/lista' element={<TercerosManagamentWrapper />} />
        {
          (currentUser?.rolId === EnumRol.Administrador || currentUser?.rolId === EnumRol.SuperAdmin) && (
            <Route path='clientes/crear' element={<AgregarTerceroWrapper />} />
          )
        }
        <Route path='clientes/profile/*' element={<TerceroCuentaWrapper />} />
        <Route path='usuarios' element={<UsuariosManagamentWrapper />} />
        {
          (currentUser?.rolId === EnumRol.Administrador || currentUser?.rolId === EnumRol.SuperAdmin) && (
            <Route path='auditorias/*' element={<AuditoriasWrapper />} />
          )
        }
        <Route path='informes/*' element={<InformesWrapper />} />
        <Route path='cartera/*' element={<PropiedadWrapper />} />

        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export { PrivateRoutes }
