import { FC } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { useListView } from "../../../../shared/providers/ListView/ListView";
import { useThemeMode } from "../../../../../_metronic/partials";
import { NoDataComponent } from "../../../../shared/ListView/NoDataComponent";
import { IASesion } from "../../../../../interfaces/auditorias/IASesion";
import { Rol } from "../../../../shared/misc/BotonesAttributos";
import date from "date-and-time"

const tableColumns: TableColumn<IASesion>[] = [
  {
    name: 'Usuario',
    selector: row => row.nombre,
    wrap: true
  }, {
    name: 'Identificacion',
    selector: row => row.identificacion
  }, {
    name: 'IP',
    selector: row => row.aIp
  }, {
    name: 'Fecha',
    selector: row => date.format(row.aFecha, 'DD-MM-YYYY HH:mm'),
    sortFunction: (a, b) => a.aFecha.getTime() - b.aFecha.getTime(),
    sortable: true
  }, {
    name: "Rol",
    cell: row => (
      <Rol rolId={row.rolId} />
    ),
    center: true,
    width: "180px"
  }
]


export const AuditoriaSesionLista: FC = () => {

  const { filterData, cargandoDatos } = useListView();
  const { mode } = useThemeMode();

  return (
    <div className="px-5">
      <DataTable
        columns={tableColumns}
        data={filterData}
        progressPending={cargandoDatos}
        pagination
        persistTableHead
        theme={mode == "dark" ? "dark" : "light"}
        noDataComponent={<NoDataComponent/>}
        keyField="idAuditoria"
        paginationComponentOptions={{
          rowsPerPageText: "Filas por pagina:"
        }}
      />
    </div>
  )
}

