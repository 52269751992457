import FileSaver from "file-saver";
import { EnumRol } from "../../../../../../../interfaces/enums/EnumRol";
import { PagoServicio } from "../../../../../../../servicios/pagoServicio";
import { GenerarInformePagos } from "../../../../../../../utils/generarExcel/InformePagos/InformeEstadoPagos";
import { IPInformacionGeneral } from "../../../../../../../utils/generarExcel/InformePagos/InformeEstadoPagosTypes";
import { BotonActualizar } from "../../../../../../shared/misc/BotonActualizar";
import { BotonFiltro } from "../../../../../../shared/misc/BotonFiltro";
import { useListView } from "../../../../../../shared/providers/ListView/ListView";
import { useAuth } from "../../../../../Auth";
import { useTercero } from "../../../provider/TerceroProvider";
import * as alertify from "alertifyjs";
import { InformeServicio } from "../../../../../../../servicios/informeServicio";
import { useState } from "react";
import { useModalCrearPago } from "../providers/ModalCrearPago";
import { PropiedadesDropDownFilter } from "./PropiedadesDropDownFilter";

export const ListaPagosToolbar = () => {

    const { currentUser } = useAuth();
    const { tercero } = useTercero();
    const { setData, setCargandoDatos } = useListView();

    const { showModal } = useModalCrearPago();

    const obtenerPagos = async () => {
        if (!tercero) return;
        setCargandoDatos(true);
        const pagos = await PagoServicio.obtenerPagos(tercero.id);
        setData(pagos);
    }

    const [descargando, setDescargando] = useState(false);
    const descargarInformePagos = async () => {
        if (!tercero) return;
        setDescargando(true);

        const pagos = await InformeServicio.InformePagos({
            terceroId: tercero.id,
            fechaFinal: null,
            estadoPagoId: null
        });

        const informacionGeneral: IPInformacionGeneral = {
            nombreTercero: tercero?.nombreCompleto ?? "",
            identificacionTercero: tercero?.identificacion ?? ""
        }

        const buffer = await GenerarInformePagos(informacionGeneral, pagos);
        if (buffer) {
            FileSaver.saveAs(new Blob([buffer]), "InformePagos.xlsx");
        } else {
            alertify.error("No se pudo generar el excel");
        }
        setDescargando(false);
    }

    return (
        <div className='d-flex justify-content-end align-items-center' data-kt-user-table-toolbar='base'>
            <div className='m-0'>
                <BotonActualizar onClick={obtenerPagos} />
            </div>
            <div className='m-0'>
                <BotonFiltro />
                <PropiedadesDropDownFilter />
            </div>
            <button disabled={descargando} type='button' className='btn btn-sm btn-light-primary me-2' onClick={descargarInformePagos}>

                {
                    (descargando) ? (
                        <>
                            <div className="spinner-border me-2" role="status" style={{ height: "15px", width: "15px" }}>
                                <span className="sr-only"></span>
                            </div>
                            Descargando
                        </>
                    ) : (
                        <>
                            <i className="bi bi-file-earmark-excel"></i>
                            Descargar Excel
                        </>
                    )
                }
            </button>
            {
                (currentUser?.rolId === EnumRol.Administrador || currentUser?.rolId === EnumRol.SuperAdmin) && (
                    <>
                        <button type='button' className='btn btn-sm btn-light-primary' onClick={() => showModal(true)}>
                            <i className="fs-2 bi bi-cash-coin"></i>
                            Agregar Pago
                        </button>
                    </>
                )
            }
        </div>
    )
}