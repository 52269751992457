import axios from "axios";
import excel, { ImageRange } from "exceljs";
import { copyCells } from "../copyCells";
import { IInformePropiedad } from "./InformePropiedadesTypes";
import { p_footer, p_header, p_lista_1, p_lista_2 } from "./posiciones";
import { addRow } from "../addRow";

export const GenerarInformePropiedades = async (propiedades: IInformePropiedad[]): Promise<ArrayBuffer | null> => {
    try {

        const { data } = await axios.get<ArrayBuffer>(`${window.origin}/media/excel/excel-informe-propiedades-base.xlsx`, { responseType: 'arraybuffer' });

        const wbBase = new excel.Workbook();
        await wbBase.xlsx.load(data);
        const wsBase = wbBase.getWorksheet("Base");

        const wb = new excel.Workbook();
        const ws = wb.addWorksheet("Informe Propiedades");

        const images: Array<{
            type: 'image',
            imageId: string;
            range: ImageRange;
        }> = wsBase.getImages();

        for (const image of images) {
            const img = wbBase.getImage(+image.imageId);
            wb.addImage(img);
        }

        // Ancho de las columnas
        for (let i = 1; i <= wsBase.columnCount; i++) {
            const col_base = wsBase.getColumn(i);
            const col = ws.getColumn(i);
            col.width = col_base.width;
        }

        const addImages = (currentRow: number) => {
            wsBase.getImages().forEach(img => {
                img.range.tl.row += currentRow;
                ws.addImage(parseInt(img.imageId), {
                    tl: img.range.tl,
                    //@ts-ignore
                    ext: img.range.ext
                })
            });
        }

        // Header
        const addInformacionGeneral = () => {
            copyCells(wsBase, ws, p_header.from, p_header.to, { x: 1, y: 1 });
        }

        // Footer
        const addFooter = (currentRow: number) => {
            copyCells(wsBase, ws, p_footer.from, p_footer.to, { x: 1, y: currentRow });
            const fechaConsulta     = ws.getCell(currentRow+1, 9);
            
            fechaConsulta.value = new Date();
        }

        // Informe edades
        const addPaginaInformePropiedades = (informePropiedades: IInformePropiedad[], currentRow: number) => {
            
            informePropiedades.forEach((p, i) => {
                const from = (i % 2 == 0) ? p_lista_1.from : p_lista_2.from;
                const to   = (i % 2 == 0) ? p_lista_1.to   : p_lista_2.to;

                copyCells(wsBase, ws, from, to, { x: 1, y: currentRow });

                const _row = ws.getRow(currentRow);
                addRow(_row, 2, [
                    p.nombre,
                    p.area,
                    p.valorTotal,
                    p.interesAnual,
                    p.propietario,
                    p.disponible
                ]);

                currentRow += 1;
            });
            return currentRow;
        }
        
        addImages(1);

        const rowInfo = ws.getRow(2);
        rowInfo.height = 40

        addInformacionGeneral();
        let currentRow = addPaginaInformePropiedades(propiedades, 6);
        addFooter(currentRow);

        const buffer = await wb.xlsx.writeBuffer();
        return buffer;
    } catch (err) {
        return null;
    }
}