import axios from "axios";
import excel, { ImageRange } from "exceljs";
import date from "date-and-time";
import { PlanDePagos, Movimientos, InformacionGeneral, TotalVenta } from "./types";
import { copyCells } from "../copyCells";
import { pos_correo, pos_saldo_final, pos_direccion, pos_documento, pos_fecha, pos_footer, pos_header, pos_hora, pos_mv_col, pos_mv_lista_1, pos_mv_lista_2, pos_nombre, pos_pagina, pos_pi_col, pos_pi_lista_1, pos_pi_lista_2, pos_pn_col, pos_pn_lista_1, pos_pn_lista_2, pos_reporte, pos_telefonos, por_valor_total, pos_unidad, pos_total_recibido, pos_valor_financiar, pos_cuotas_mora, pos_pago_actual_lista, pos_area_lote, pos_interes_anual, pos_cantidad_pagos, pos_valor_a_financiar, pos_interes_mora } from "./posiciones";
import { addRow } from "../addRow";

export const GenerarExcel = async (
    pagosIniciales: PlanDePagos[],
    pagosNormales: PlanDePagos[],
    movimientos: Movimientos[],
    informacionGeneral: InformacionGeneral,
    totalVentaDatos: TotalVenta
): Promise<ArrayBuffer | null> => {
    try {

        const totalData = pagosIniciales.length + pagosNormales.length + movimientos.length - 8;

        const { data } = await axios.get<ArrayBuffer>(`${window.origin}/media/excel/excel-pagos-base.xlsx`, { responseType: 'arraybuffer' });

        const wbBase = new excel.Workbook();
        await wbBase.xlsx.load(data);
        const wsBase = wbBase.getWorksheet("Base");

        const wb = new excel.Workbook();
        const ws = wb.addWorksheet("Pagos");

        const images: Array<{
            type: 'image',
            imageId: string;
            range: ImageRange;
        }> = wsBase.getImages();

        for (const image of images) {
            const img = wbBase.getImage(+image.imageId);
            wb.addImage(img);
        }
        
        // Ancho de las columnas
        for (let i = 1; i <= wsBase.columnCount; i++) {
            const col_base = wsBase.getColumn(i);
            const col = ws.getColumn(i);
            col.width = col_base.width;
        }

        const addImages = (currentRow: number) => {
            wsBase.getImages().forEach(img => {
                img.range.tl.row += currentRow;
                ws.addImage(parseInt(img.imageId), {
                    tl: img.range.tl,
                    ext: { height: 100, width: 100 }
                })
            });
        }
        
        const addHeader = () => {
            copyCells(wsBase, ws, pos_header.from, pos_header.to, { x: 1, y: 1 });
            
            const nombre            = ws.getCell(pos_nombre);
            const documento         = ws.getCell(pos_documento);
            const direccion         = ws.getCell(pos_direccion);
            const unidad            = ws.getCell(pos_unidad);
            const telefonos         = ws.getCell(pos_telefonos);
            const correo            = ws.getCell(pos_correo);
            const areaLote          = ws.getCell(pos_area_lote);
            const interesAnual      = ws.getCell(pos_interes_anual);
            const interesDeMora     = ws.getCell(pos_interes_mora);
            const cantidadPagos     = ws.getCell(pos_cantidad_pagos);
            const valorFinanciar    = ws.getCell(pos_valor_a_financiar);

            nombre.value            = informacionGeneral.nombre;
            documento.value         = informacionGeneral.documento;
            direccion.value         = informacionGeneral.direccion;
            unidad.value            = informacionGeneral.unidad;
            telefonos.value         = informacionGeneral.telefonos;
            correo.value            = informacionGeneral.correo;
            areaLote.value          = informacionGeneral.areaLote;
            interesAnual.value      = informacionGeneral.interesAnual;
            interesDeMora.value     = informacionGeneral.interesDeMora;
            cantidadPagos.value     = informacionGeneral.cantidadPagos;
            valorFinanciar.value    = informacionGeneral.valorFinanciar;
        }

        const addPagosIniciales = (currentRow: number): number => {
            copyCells(wsBase, ws, pos_pi_col.from, pos_pi_col.to, { x: 1, y: currentRow });
            currentRow += 2;

            pagosIniciales.forEach((pago, i) => {
                const from = pago.actual ? pos_pago_actual_lista.from : (i % 2 == 0) ? pos_pi_lista_1.from : pos_pi_lista_2.from;
                const to   = pago.actual ? pos_pago_actual_lista.to : (i % 2 == 0) ? pos_pi_lista_1.to   : pos_pi_lista_2.to;

                copyCells(wsBase, ws, from, to, { x: 1, y: currentRow });

                const _row = ws.getRow(currentRow);
                addRow(_row, 2, [
                    pago.no,
                    pago.fecha,
                    pago.cuota,
                    pago.interes,
                    pago.capital,
                    pago.mora,
                    pago.saldo
                ]);
                currentRow += 1;
            });

            return currentRow;
        }

        const addPagosNormales  = (currentRow: number): number => {
            copyCells(wsBase, ws, pos_pn_col.from, pos_pn_col.to, { x: 1, y: currentRow });
            currentRow += 2;

            for (let i = 0; i < pagosNormales.length; i++) {
                const from = pagosNormales[i].actual ? pos_pago_actual_lista.from : (i % 2 == 0) ? pos_pn_lista_1.from : pos_pn_lista_2.from;
                const to   = pagosNormales[i].actual ? pos_pago_actual_lista.to : (i % 2 == 0) ? pos_pn_lista_1.to   : pos_pn_lista_2.to;

                copyCells(wsBase, ws, from, to, { x: 1, y: currentRow });

                const _row = ws.getRow(currentRow);
                addRow(_row, 2, [
                    pagosNormales[i].no,
                    pagosNormales[i].fecha,
                    pagosNormales[i].cuota,
                    pagosNormales[i].interes,
                    pagosNormales[i].capital,
                    pagosNormales[i].mora,
                    pagosNormales[i].saldo
                ]);

                currentRow += 1;
            }
            return currentRow;
        }

        const addMovimientos  = (currentRow: number): number => {
            copyCells(wsBase, ws, pos_mv_col.from, pos_mv_col.to, { x: 1, y: currentRow });
            currentRow += 2;

            for (let i = 0; i < movimientos.length; i++) {
                const from = (i % 2 == 0) ? pos_mv_lista_1.from : pos_mv_lista_2.from;
                const to   = (i % 2 == 0) ? pos_mv_lista_1.to   : pos_mv_lista_2.to;

                copyCells(wsBase, ws, from, to, { x: 1, y: currentRow });

                const _row = ws.getRow(currentRow);
                addRow(_row, 2, [
                    movimientos[i].no,
                    movimientos[i].referenciaPago,
                    movimientos[i].fecha,
                    movimientos[i].pago,
                    movimientos[i].capital,
                    movimientos[i].interes,
                    movimientos[i].abono,
                    movimientos[i].mora
                ]);
                currentRow += 1;
            }
            return currentRow;
        }

        const addFooter = (currentRow: number) => {
            copyCells(wsBase, ws, pos_footer.from, pos_footer.to, { x: 1, y: currentRow });
            
            const valorTotal    = ws.getCell(por_valor_total.y + totalData, por_valor_total.x);
            const valorFinanciar = ws.getCell(pos_valor_financiar.y + totalData, pos_valor_financiar.x);
            const totalRecibido  = ws.getCell(pos_total_recibido.y + totalData, pos_total_recibido.x);
            const cuotasMora    = ws.getCell(pos_cuotas_mora.y + totalData, pos_cuotas_mora.x);
            const saldoFinal    = ws.getCell(pos_saldo_final.y + totalData, pos_saldo_final.x);

            valorTotal.value    = totalVentaDatos.valorTotal;
            valorFinanciar.value = totalVentaDatos.valorFinanciar;
            totalRecibido.value  = totalVentaDatos.totalRecibido;
            cuotasMora.value    = totalVentaDatos.cuotasMora;
            saldoFinal.value    = totalVentaDatos.saldoFinal;

            const pagina  = ws.getCell(pos_pagina.y + totalData, pos_pagina.x);
            const fecha   = ws.getCell(pos_fecha.y  + totalData, pos_fecha.x);
            const hora    = ws.getCell(pos_hora.y + totalData, pos_hora.x);
            const reporte = ws.getCell(pos_reporte.y + totalData, pos_reporte.x);

            pagina.value  = 1;
            fecha.value   = date.format(new Date(), "YYYY/MM/DD");
            hora.value    = date.format(new Date(), "HH:mm:ss");
            reporte.value = "REPORTE: REP_003A";
        }
        
        addHeader();
        addImages(1);
        let currentRow = 12;

        currentRow = addPagosIniciales(currentRow);
        currentRow = addPagosNormales(currentRow);
        currentRow = addMovimientos(currentRow);
        addFooter(currentRow);

        /*
        mergeCells(wsBase, ws, {x: 1, y: 14}, {x: 16, y: 14}, {x: 1, y: 1});
        const row = ws.getRow(1);
        addRow(row, 2, ["hola", "como", "tal e v"]);
        */

        const buffer = await wb.xlsx.writeBuffer();
        return buffer;
    } catch (err) {
        return null;
    }
}