import { FormikProps, useFormik } from "formik";
import { createContext, FC, useEffect, useContext } from "react";
import { WithChildren } from "../../../../../../_metronic/helpers";
import { ITercero } from "../../../../../../interfaces/ITercero";
import { TercerosServicios } from "../../../../../../servicios/tercerosServicios";
import { terceroCrearSchema } from "../../../../../../validators/terceroValidator";
import { useTercero } from "../../provider/TerceroProvider";
import { datosTerceroIniciales } from "../../../../../shared/datosFormularios/DatosInicialesFormularios";
import * as alertify from "alertifyjs";
import { useNavigate } from "react-router-dom";

interface FormularioProviderProps {
    formik: FormikProps<ITercero>
}

const initialFormularioProvider: FormularioProviderProps = {
    cargando: false,
    setCargando: ()=>{},
    // @ts-ignore
    formik: {}
}

const FormularioProviderContext = createContext<FormularioProviderProps>(initialFormularioProvider)


const FormularioProvider: FC<WithChildren> = ({ children }) => {

    const { setTercero, tercero, estado } = useTercero();

    const formik = useFormik({
        initialValues: datosTerceroIniciales,
        validationSchema: terceroCrearSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                if(estado != values.estadoId && [1,2].includes(estado)) values.estadoId = estado;
                if(values.codigoActividad == "") values.codigoActividad = "0";

                const response = await TercerosServicios.Actualizar(values);
                if(response){ 
                    alertify.success("Cliente actualizado");
                    setTercero({...values})
                }
            } catch (err) { }
        }
    })

    useEffect(()=>{
        if(tercero) formik.setValues({...tercero});
    }, [tercero]);

    return (
        <FormularioProviderContext.Provider value={{ 
            formik,
        }}>
            {children}
        </FormularioProviderContext.Provider>
    )
}

const useFormulario = () => useContext(FormularioProviderContext);

export {
    FormularioProvider,
    useFormulario
}
