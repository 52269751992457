import { FC } from "react"
import { InputInformativo } from "../../../../shared/misc/InputInformativo"
import { IAUsuario } from "../../../../../interfaces/auditorias/IAUsuario"

interface InfoAuditoriaUsuarioProps {
    main?: IAUsuario | null
    target?: IAUsuario | null
    comparar?: boolean
}

export const InfoAuditoriaUsuario: FC<InfoAuditoriaUsuarioProps> = ({
    main,
    target,
    comparar = true
}) => {

    return (
        <>
            <div className="col-12">
                <InputInformativo
                    nombre="Nombres"
                    icon="bi-person-circle"
                    text={main?.nombre}
                    changed={(!comparar) ? false : main?.nombre !== target?.nombre}
                />
            </div>
            <InputInformativo
                nombre="Correo"
                icon="bi-envelope-fill"
                text={main?.correo}
                changed={(!comparar) ? false : main?.correo !== target?.correo}
            />
            <InputInformativo
                nombre="Identificación"
                icon="bi-credit-card"
                text={main?.identificacion}
                changed={(!comparar) ? false : main?.identificacion !== target?.identificacion}
            />
            <InputInformativo
                nombre="Rol"
                icon="bi-tag-fill"
                text={main?.rol}
                changed={(!comparar) ? false : main?.rol !== target?.rol}
            />
            <InputInformativo
                nombre="Estado"
                icon="bi-code-slash"
                text={main?.estado}
                changed={(!comparar) ? false : main?.estado !== target?.estado}
            />
        </>
    )
}