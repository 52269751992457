import { FC, createContext, useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { WithChildren } from "../../../../../_metronic/helpers";
import { datosTerceroIniciales } from "../../../../shared/datosFormularios/DatosInicialesFormularios";
import { IUbicacion } from "../../../../../interfaces/IUbicacion";
import { InputInformativo } from "../../../../shared/misc/InputInformativo";
import { ITercero } from "../../../../../interfaces/ITercero";

type showModalType = (show: boolean, usuario: ITercero | null, municipio: IUbicacion | null) => void
interface ModalContactoPros {
    showModal: showModalType
}

const ModalContactoIncial: ModalContactoPros = {
    showModal: () => { }
}

const ModalContactoContext = createContext<ModalContactoPros>(ModalContactoIncial)

const ModalContactoProvider: FC<WithChildren> = ({
    children
}) => {
    const [show, setShow] = useState(false);
    const [tercero, setTercero] = useState<ITercero | null>(datosTerceroIniciales);
    const [municipio, setMunicipio] = useState<IUbicacion | null>(null);

    const showModal: showModalType = (show, tercero, municipio) => {
        setShow(show);
        setTercero(tercero);
        setMunicipio(municipio);
    }

    return (
        <ModalContactoContext.Provider value={{
            showModal
        }}>
            {children}
            {
                show &&
                <Modal show={show} className="fade modal modal">
                    <form
                        className="form"
                        noValidate
                    >
                        <Modal.Header className="p-4">
                            <h2 className="modal-title">{tercero?.primerNombre} {tercero?.otrosNombres} {tercero?.primerApellido} {tercero?.segundoApellido}</h2>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                aria-label="Close"
                                onClick={() => setShow(false)}
                            >
                                <i className="bi bi-x-lg"></i>
                            </div>
                        </Modal.Header>
                        <Modal.Body className="bg-gray-300 px-6 py-4">
                            {/* begin::modal */}
                            <div className="row bg-light border rounded-3">
                                <div className="col-6">
                                    <div className="row p-4 row-cols-1">
                                        <div className="col-12 p-0 px-5 rounded mb-2">
                                            <h1 className="align-middle">Contacto</h1>
                                        </div>
                                        <InputInformativo
                                            icon="bi-person-video2"
                                            nombre="Alias"
                                            text={tercero?.alias}
                                        />
                                        <InputInformativo
                                            icon="bi-telephone-fill"
                                            nombre="Celular"
                                            text={tercero?.contacto}
                                        />
                                        <InputInformativo
                                            icon="bi-whatsapp"
                                            nombre="Contacto Whatsapp"
                                            text={tercero?.contactoWhatsapp}
                                        />
                                        <InputInformativo
                                            icon="bi-envelope-fill"
                                            nombre="Correo"
                                            text={tercero?.correo}
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row p-4 row-cols-1">
                                        <div className="col-12 p-0 px-5 rounded mb-2">
                                            <h1 className="align-middle">Dirección</h1>
                                        </div>
                                        <InputInformativo
                                            icon="bi-geo-alt-fill"
                                            nombre="Municipio"
                                            text={municipio?.nombreConjunto}
                                        />
                                        <InputInformativo
                                            icon="bi-geo-alt-fill"
                                            nombre="Barrio"
                                            text={tercero?.barrio}
                                        />
                                        <InputInformativo
                                            icon="bi-geo-alt-fill"
                                            nombre="Direccion"
                                            text={tercero?.direccion}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* end::modal */}
                        </Modal.Body>
                        <Modal.Footer className="p-4">
                            
                        </Modal.Footer>
                    </form>
                </Modal >
            }
        </ModalContactoContext.Provider>
    )
}

const useModalContacto = () => useContext(ModalContactoContext);

export {
    ModalContactoProvider,
    useModalContacto
}
