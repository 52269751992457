import { axios } from "./axiosInstance";
import { IUsuario } from "../interfaces/IUsuario";
import { AuthModel } from "../app/modules/Auth";

type SesionRespuesta = {
    auth: AuthModel
    usuario: IUsuario
}

export class UsuarioServicios {

    public static Login = (correo: string, contrasena: string) : Promise<SesionRespuesta> => {
        return new Promise(async(res, rej)=>{
            try{
                const usuario = await axios.post<IUsuario>("/sesion", {
                    correo, contrasena
                });
                const authModel: AuthModel = {
                    api_token: usuario.token!
                } 
                res({
                    auth: authModel, usuario
                });
            }catch(err){
                rej(err);
            }
        });
    }

    public static Registro = (usuario: IUsuario) : Promise<SesionRespuesta|null> => {
        return new Promise(async(res, rej)=>{
            try{
                const { data } = await axios.post("/usuario", {
                    usaurio: usuario
                })
                const authModel: AuthModel = {
                    api_token: data.value.token
                } 
                res({
                    auth: authModel,
                    usuario: data.value
                });
            }catch(err){
                res(null);
            }
        });
    }

    public static ObtenerTodos = () : Promise<IUsuario[]> => {
        return new Promise(async(res, rej)=>{
            try{
                const usuarios = await axios.get<IUsuario[]>("/usuario/lista");
                res(usuarios);
            }catch(err){
                res([]);
            }
        });
    }

    public static Crear = (usuario: Partial<IUsuario>) : Promise<void> => {
        return new Promise(async(res, rej)=>{
            try{
                await axios.post<IUsuario[]>("/usuario", {...usuario});
                res();
            }catch(err){
                rej(err);
            }
        });
    }

    public static Actualizar = (usuario: Partial<IUsuario>) : Promise<void> => {
        return new Promise(async(res, rej)=>{
            try{
                await axios.put<IUsuario[]>("/usuario", {...usuario});
                res();
            }catch(err){
                rej(err);
            }
        });
    }
}


