import { useEffect, useMemo } from "react";
import { ICuota } from "../../../../../../../interfaces/cuotas/ICuota"
import { IPago } from "../../../../../../../interfaces/pago/IPago"
import { EnumTipoPago } from "../../../../../../../interfaces/enums/EnumTipoPago";
import { EstadoPagoEnum } from "../../../../../../../interfaces/enums/EnumEstadoPago";

type useCuotasType = (cuotas: ICuota[], pagos: IPago[]) => {
    cuotasPagoInicial: ICuota[],
    cuotasPIpagadas: ICuota[],
    //cuotasPIpendientes: ICuota[],
    cuotasPagoNormal: ICuota[],
    cuotasPNpagadas: ICuota[],
    //cuotasPNpendientes: ICuota[],

    //pagosIniciales: IPago[],
    //pagosNoIniciales: IPago[],
    pagosAbonos: IPago[],
    //pagosCuotas: IPago[]
}

export const useCuotas: useCuotasType = (
    cuotas,
    pagos
) => {

    // Cuotas: iniciales y normales de la propiedad actual
    const cuotasPagoInicial: ICuota[] = useMemo(() => cuotas.filter(cuota => cuota.tipoPagoId == EnumTipoPago.inicial), [cuotas]);
    const cuotasPIpagadas: ICuota[]   = useMemo(() => cuotasPagoInicial.filter(cuota => cuota.estadoCuotaId == EstadoPagoEnum.pagado), [cuotasPagoInicial]);
    //const cuotasPIpendientes: ICuota[] = useMemo(() => cuotasPagoInicial.filter(cuota => cuota.estadoPagoId == EstadoPagoEnum.pendiente), [cuotasPagoInicial]);

    const cuotasPagoNormal: ICuota[] = useMemo(() => cuotas.filter(cuota => cuota.tipoPagoId != EnumTipoPago.inicial), [cuotas]);
    const cuotasPNpagadas: ICuota[]  = useMemo(() => cuotasPagoNormal.filter(cuota => cuota.estadoCuotaId == EstadoPagoEnum.pagado), [cuotasPagoNormal]);
    //const cuotasPNpendientes: ICuota[] = useMemo(() => cuotasPagoNormal.filter(cuota => cuota.estadoPagoId == EstadoPagoEnum.pendiente), [cuotasPagoNormal]);

    // Pagos realizados: iniciales, abonos y cuotas de la propiedad actual
    
    //const pagosIniciales: IPago[] = useMemo(() => pagos.filter(p => p.tipoPagoId == TipoPagoEnum.inicial), [pagos]);
    const pagosNoIniciales: IPago[] = useMemo(() => pagos.filter(p => p.tipoPagoId != EnumTipoPago.inicial), [pagos]);
    const pagosAbonos: IPago[]      = useMemo(() => pagosNoIniciales.filter(p => p.tipoPagoId == EnumTipoPago.abono), [pagosNoIniciales]);
    //const pagosCuotas: IPago[] = useMemo(() => pagosNoIniciales.filter(p => p.tipoPagoId == TipoPagoEnum.cuota), [pagosNoIniciales]);
    
    return {
        cuotasPagoInicial,
        cuotasPIpagadas,
        //cuotasPIpendientes,
        cuotasPagoNormal,
        cuotasPNpagadas,
        //cuotasPNpendientes,

        //pagosIniciales,
        //pagosNoIniciales,
        pagosAbonos,
        //pagosCuotas
    }
}
