import { addressToPos } from "../addresToPos";

export const ic_ficha_desde = "D5";
export const ic_ficha_hasta = "E5";
export const ic_fecha_consulta = addressToPos("J13");
export const ic_total_cuota = addressToPos("H12");
export const ic_total_recibido = addressToPos("I12");

export const ic_header = {
    from: addressToPos("A1"),
    to: addressToPos("K8")
};

export const ic_footer = {
    from: addressToPos("A12"),
    to: addressToPos("K14")
};


export const ic_lista_1 = {
    from: addressToPos("A9"),
    to: addressToPos("K9")
};

export const ic_lista_2 = {
    from: addressToPos("A10"),
    to: addressToPos("K10")
};

