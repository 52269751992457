import { FC } from "react";
import { ListaBotonesComponent } from "./ListaBotonesComponent";
import { BuscarComponent } from "../../../../../shared/providers/ListView/BuscarComponent";

export const TerceroManagamentHeader: FC = () => {
    return (
        <div className='card-header border-0 py-4'>
            <BuscarComponent placeholder="Buscar Cliente" />
            <div className='card-toolbar'>
                <ListaBotonesComponent />
            </div>
        </div>
    )
}