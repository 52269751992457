import { FC } from "react"
import { AgregarCientePage } from "../modules/Terceros/Crear/TerceroCrearPagina"
import { PageTitle } from "../../_metronic/layout/core"

export const AgregarTerceroWrapper: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Crear cliente</PageTitle>
            <AgregarCientePage />
        </>
    )
}
