import { addressToPos } from "../addresToPos";

export const ep_nombre_tercero = "F2";
export const ep_identificacion_tercero = "F3";
export const ep_fecha_consulta = addressToPos("L13");

export const ep_header = {
    from: addressToPos("A1"),
    to: addressToPos("O9")
};

export const ep_footer = {
    from: addressToPos("A12"),
    to: addressToPos("O14")
};


export const ep_lista_1 = {
    from: addressToPos("A10"),
    to: addressToPos("O10")
};

export const ep_lista_2 = {
    from: addressToPos("A11"),
    to: addressToPos("O11")
};

