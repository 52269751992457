import { FormikProps } from "formik"
import { FC } from "react"
import { ITercero } from "../../../../interfaces/ITercero"
import { FormularioCampo } from "../../../../_metronic/partials/formulario/FormularioCampo"
import { Input } from "../../../../_metronic/partials/formulario/Input"
import { InputNumerico } from "../../../../_metronic/partials/formulario/InputNumerico"
import { useAuth } from "../../../modules/Auth"
import { EnumRol } from "../../../../interfaces/enums/EnumRol"



interface TerceroDatosContactoProps {
    formik: FormikProps<ITercero>
}

export const TerceroDatosContacto: FC<TerceroDatosContactoProps> = ({
    formik
}) => {

    const { currentUser } = useAuth();

    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer d-flex justify-content-center align-items-center'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_crear_tercero_contacto'
                aria-expanded='true'
                aria-controls='kt_crear_tercero_contacto'
                style={{minHeight: "50px"}}
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Contacto</h3>
                </div>
            </div>
            <div id='kt_crear_tercero_contacto' className='collapse show'>
                <div className='card-body border-top p-9'>
                    <div className="row row-cols-2">

                        <FormularioCampo nombre="Alias">
                            <div className="row p-0">
                                <Input
                                    disabled={currentUser?.rolId != EnumRol.Administrador}
                                    autoCapitalLetter
                                    placeholder={"Alias"}
                                    props={formik.getFieldProps('alias')}
                                    validProp={formik.touched.alias}
                                    error={formik.errors.alias}
                                />
                            </div>
                        </FormularioCampo>

                        <FormularioCampo nombre="Celular" requerido>
                            <div className="row p-0">
                                <InputNumerico
                                    validate
                                    disabled={currentUser?.rolId != EnumRol.Administrador}
                                    placeholder={"Celular *"}
                                    props={formik.getFieldProps('contacto')}
                                    validProp={formik.touched.contacto}
                                    error={formik.errors.contacto}
                                />
                            </div>
                        </FormularioCampo>

                        <FormularioCampo nombre="Contacto WhatsApp" requerido>
                            <div className="row p-0">
                                <InputNumerico
                                    validate
                                    disabled={currentUser?.rolId != EnumRol.Administrador}
                                    placeholder={"Contacto WhatsApp *"}
                                    props={formik.getFieldProps('contactoWhatsapp')}
                                    validProp={formik.touched.contactoWhatsapp}
                                    error={formik.errors.contactoWhatsapp}
                                />
                            </div>
                        </FormularioCampo>

                        <FormularioCampo nombre="Correo" requerido>
                            <div className="row p-0">
                                <Input
                                    deleteSpaces
                                    disabled={currentUser?.rolId != EnumRol.Administrador}
                                    placeholder={"Correo *"}
                                    props={formik.getFieldProps('correo')}
                                    validProp={formik.touched.correo}
                                    error={formik.errors.correo}
                                />
                            </div>
                        </FormularioCampo>
                        
                    </div>
                </div>
            </div>
        </div>

    )
}

