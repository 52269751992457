export function monthDiff(d1: Date, d2: Date) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}

function treatAsUTC(date: Date) {
    var ndate = new Date(date);
    ndate.setMinutes(ndate.getMinutes() - ndate.getTimezoneOffset());
    return ndate;
}

export function daysBetween(startDate: Date, endDate: Date) {
    var millisecondsPerDay = 24 * 60 * 60 * 1000;
    return (endDate.getTime() - startDate.getTime()) / millisecondsPerDay;
}