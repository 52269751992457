import { useCallback, useEffect, useMemo, useState } from "react"
import { ICuota } from "../../../../../../../interfaces/cuotas/ICuota";
import * as alertify from "alertifyjs";
import { EstadoPagoEnum } from "../../../../../../../interfaces/enums/EnumEstadoPago";
import { EnumTipoPago } from "../../../../../../../interfaces/enums/EnumTipoPago";
import date from "date-and-time";
import { daysBetween } from "../../../../../../../utils/dateDiff";
import { Propiedad } from "../providers/PropiedadProvider";
import { CalcularMora } from "../../../../../../../utils/calculos/CalcularMora";

export interface IPagoInicialColumna {
    cuotaId: string
    propiedadTerceroId: string
    fecha?: Date
    cuota: number
    mora: number
    estadoPagoId: number
    tipoPadoId: number
    numeroPago: number
    saldo: number
    className?: string
}


type usePagosInicialesType = (
    fechaLimitePagoInicial: string,
    pagoInicial: number,
    cuotasPagoInicial: ICuota[],
    porcentajeMora: number
) => {
    planPagoInicial: IPagoInicialColumna[],
    actualizarFecha: (numeroCuota: number, fecha: Date) => void,
    actualizarPago: (numeroCuota: number, valor: number) => void,
    agregarCuota: () => void,
    eliminarCuota: (numeroCuota: number) => void,
    valorRestante: number
}

export const usePagosIniciales: usePagosInicialesType = (
    fechaLimitePagoInicial,
    pagoInicial,
    cuotasPagoInicial,
    porcentajeMora
) => {
    const [planPagoInicial, setPlanPagoInicial] = useState<IPagoInicialColumna[]>([]);

    const valorRestante = useMemo(() => {
        var totalPagado = planPagoInicial.reduce((p, c) => p + c.cuota, 0);
        return pagoInicial - totalPagado;
    }, [pagoInicial, planPagoInicial]);

    useEffect(() => {
        if (fechaLimitePagoInicial == "") return;
        const currentDate = date.parse(new Date().formato(), "YYYY-MM-DD");

        const cuotas: IPagoInicialColumna[] = [];
        let saldoAcumulado = pagoInicial;

        cuotas.push({
            cuotaId: "",
            propiedadTerceroId: "",
            cuota: 0,
            mora: 0,
            estadoPagoId: EstadoPagoEnum.pagado,
            tipoPadoId: EnumTipoPago.inicial,
            numeroPago: 0,
            saldo: pagoInicial
        });

        cuotasPagoInicial.forEach((cuota, i) => {
            saldoAcumulado -= cuota.cuota;
            const diasMora = Math.floor(daysBetween(currentDate, cuota.fecha));
            const mora     = CalcularMora(diasMora, cuota.cuota, porcentajeMora);
            cuotas.push({
                cuotaId:            cuota.id,
                propiedadTerceroId: cuota.propiedadTerceroId,
                fecha:              cuota.fecha,
                cuota:              cuota.cuota,
                mora:               mora,
                estadoPagoId:       cuota.estadoCuotaId,
                tipoPadoId:         cuota.tipoPagoId,
                numeroPago:         i + 1,
                saldo:              saldoAcumulado
            });
        });
        
        setPlanPagoInicial([...cuotas]);
    }, [pagoInicial, fechaLimitePagoInicial, cuotasPagoInicial, porcentajeMora]);

    // Funcion para actualizar una fecha de los pagos iniciales
    const actualizarFecha = useCallback((numeroCuota: number, fecha: Date) => {
        if(fechaLimitePagoInicial == ""){
            alertify.error(`No se pudo actualizar la fecha intentalo mas tarde`);
            return;
        }

        const currentDate    = date.parse(new Date().formato(), "YYYY-MM-DD");
        const fechaAnterior  = planPagoInicial[numeroCuota - 1]?.fecha;
        const fechaSiguiente = planPagoInicial[numeroCuota + 1]?.fecha;
        const fechaFinal     = date.parse(fechaLimitePagoInicial, "YYYY-MM-DD");

        if (fechaAnterior && fechaAnterior.getTime() > fecha.getTime()) {
            alertify.error(`La fecha del pago inicial (#${numeroCuota}) no puede ser menor a la fecha del pago inicial (#${numeroCuota - 1})`);
            return;
        }
        if (fechaSiguiente && fechaSiguiente.getTime() < fecha.getTime()) {
            alertify.error(`La fecha del pago inicial (#${numeroCuota}) no puede ser mayor a la fecha del pago inicial (#${numeroCuota + 1})`);
            return;
        }
        if(fechaFinal.getTime() < fecha.getTime()){
            alertify.error(`La fecha del pago inicial (#${numeroCuota}) no puede ser mayor a la fecha limite de pago inicial`);
            return;
        }

        const diasMora = Math.floor(daysBetween(currentDate, fecha));
        const mora     = diasMora < 0 ? (planPagoInicial[numeroCuota].cuota * porcentajeMora / 30 * diasMora) : 0;

        planPagoInicial[numeroCuota].fecha = fecha;
        planPagoInicial[numeroCuota].mora  = Math.abs(mora);

        setPlanPagoInicial([...planPagoInicial]);
    }, [fechaLimitePagoInicial, planPagoInicial]);

    // Funcion para actualizar el pago de una cuota
    const actualizarPago = useCallback((numeroCuota: number, valor: number) => {
        
        var cuota = planPagoInicial[numeroCuota];
        let _valorRestante = planPagoInicial[numeroCuota-1]?.saldo ?? pagoInicial;

        const currentDate = date.parse(new Date().formato(), "YYYY-MM-DD");

        if (valor > _valorRestante) {
            alertify.error("El valor del pago no puede ser mayor al valor a pagar.");
            return;
        }
        if (valor <= 0) {
            alertify.error("El valor del pago debe ser mayor a 0.");
            return;
        }

        const diasMora = Math.floor(daysBetween(currentDate, cuota.fecha!));
        const mora     = CalcularMora(diasMora, valor, porcentajeMora);
        
        planPagoInicial[numeroCuota].cuota = valor;
        planPagoInicial[numeroCuota].mora  = mora;
        
        let saldoAcumulado = pagoInicial;
        planPagoInicial.map((c, i, a) => {
            saldoAcumulado -= c.cuota;
            a[i].saldo = saldoAcumulado;
        });

        setPlanPagoInicial([...planPagoInicial]);
    }, [pagoInicial, planPagoInicial]);

    //Funcion para agregar una cuota
    const agregarCuota = useCallback(() => {

        if (valorRestante <= 0) {
            alertify.error("No se pueden agregar mas pagos, saldo restante del pago inicial igual a 0.");
            return;
        }
        const currentDate = date.parse(new Date().formato(), "YYYY-MM-DD");

        var fecha = (planPagoInicial[planPagoInicial.length - 1]) ? planPagoInicial[planPagoInicial.length - 1].fecha : new Date();

        fecha = (fecha ?? new Date()).addDays(1);
        const diasMora      = Math.floor(daysBetween(currentDate, fecha));
        const mora          = diasMora < 0 ? (valorRestante * porcentajeMora / 30 * diasMora) : 0;

        planPagoInicial.push({
            cuotaId:            "",
            propiedadTerceroId: "",
            fecha:              fecha,
            cuota:              valorRestante,
            mora:               Math.abs(mora),
            estadoPagoId:       EstadoPagoEnum.pendiente,
            tipoPadoId:         EnumTipoPago.inicial,
            numeroPago:         0,
            saldo:              pagoInicial
        });

        let saldoAcumulado = pagoInicial;
        planPagoInicial.map((v, i, a) => {
            saldoAcumulado -= v.cuota;
            a[i].saldo = saldoAcumulado;
            a[i].numeroPago = i
        });

        setPlanPagoInicial([...planPagoInicial]);
    }, [valorRestante, pagoInicial, planPagoInicial]);

    //Funcion para quitar una cuota
    const eliminarCuota = useCallback((numeroCuota: number) => {

        planPagoInicial.splice(numeroCuota, 1);

        let saldoAcumulado = pagoInicial;
        planPagoInicial.map((v, i, a) => {
            saldoAcumulado -= v.cuota;
            a[i].saldo = saldoAcumulado;
            a[i].numeroPago = i
        });

        setPlanPagoInicial([...planPagoInicial]);
    }, [valorRestante, pagoInicial, planPagoInicial]);

    return {
        valorRestante,
        planPagoInicial,
        actualizarFecha,
        actualizarPago,
        agregarCuota,
        eliminarCuota
    };
}