import { useEffect, useState } from "react"
import { PageTitle } from "../../../_metronic/layout/core"
import { ListViewProvider } from "../../shared/providers/ListView/ListView"
import { PropiedadCaracteristicaBody } from "./components/body/PropiedadCaracteristicaBody"
import { PropiedadCaracteristicaHeader } from "./components/header/PropiedadCaracteristicaHeader"
import { ModalPropiedadProvider } from "./providers/ModalEditarPropiedad"
import { PropiedadServicio } from "../../../servicios/propiedadServicio"
import { IPropiedadCaracteristicas } from "../../../interfaces/propiedades/IPropiedadCaracteristicas"
import { KTCard } from "../../../_metronic/helpers"
import { ModalAsignarPropiedadProvider } from "./providers/ModalAsignarPropiedad"


export const Propiedad = () => {

    const [propiedades, setPropiedades] = useState<IPropiedadCaracteristicas[]>([]);

    const obtenerPropiedades = async () => {
        const propiedades = await PropiedadServicio.ObtenerTodos();
        setPropiedades(propiedades);
    }

    useEffect(() => {
        obtenerPropiedades();
    }, []);

    return (
        <ListViewProvider initialData={propiedades} >
            <ModalPropiedadProvider>
                <PageTitle>Propiedades</PageTitle>
                <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
                    <KTCard>
                        <PropiedadCaracteristicaHeader />
                        <PropiedadCaracteristicaBody />
                    </KTCard>
                </div>
            </ModalPropiedadProvider>
        </ListViewProvider>
    )
}
