import { axios } from "./axiosInstance";

export class RecordatorioServicio {

    static Recordar = (propiedadTerceroId: string): Promise<boolean> => {
        return new Promise(async(res, rej)=>{
            try{
                await axios.get(`/recordatorio?PropiedadTerceroId=${propiedadTerceroId}`)
                res(true);
            }catch(err){
                res(false);
            }
        });
    }

}