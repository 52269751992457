/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { usePropiedad } from '../providers/PropiedadProvider'
import date from "date-and-time";
import { useNavigate } from 'react-router-dom';
import { useTercero } from '../../../provider/TerceroProvider';
import { EstadoPagoEnum } from '../../../../../../../interfaces/enums/EnumEstadoPago';
import { IPagoInicialColumna } from '../hooks/usePagosIniciales';
import { InputMonetario2 } from '../../../../../../../_metronic/partials/formulario/InputMonetario';
import { BotonIcono } from '../../../../../../shared/misc/BotonIcono';
import * as alertify from "alertifyjs";
import { useEscribiendo } from '../../../../../../shared/hooks/useEscribiendo';
import { useAuth } from '../../../../../Auth';
import { EnumRol } from '../../../../../../../interfaces/enums/EnumRol';

type Props = {
    className?: string
}

export const TerceroPagoInicialLista: React.FC<Props> = ({
    className = ""
}) => {

    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const { tercero } = useTercero();
    const { formik, planPagosInicial, agregarCuotaInicial: agregarCuotaInicial } = usePropiedad();

    return (
        <div className={`card ${className}`}>
            <div className='card-header border-0 pt-5 d-flex'>
                <h3 className='card-title flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Valor inicial</span>
                </h3>
                {
                    (currentUser?.rolId === EnumRol.Administrador || currentUser?.rolId === EnumRol.SuperAdmin) && (
                        <>
                            <button
                                type='button'
                                className='btn btn-sm btn-light-primary ms-auto'
                                style={{ maxHeight: "40px" }}
                                onClick={agregarCuotaInicial}
                            >
                                <i className="bi bi-calendar-plus"></i>
                                Agregar Cuota
                            </button>
                            <button
                                type='button'
                                className='btn btn-sm btn-light-primary ms-4'
                                style={{ maxHeight: "40px" }}
                                onClick={() => navigate(`/clientes/profile/pagos/?idCliente=${tercero?.id}&propiedadTerceroId=${formik.values.id}&tipoPagoId=3&pagar=1`)}
                            >
                                <i className="fs-2 bi bi-cash-coin"></i>
                                Agregar Pago
                            </button>
                        </>
                    )
                }
            </div>

            <div className='card-body py-3'>
                <div className='table-responsive'>
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                        <thead>
                            <tr className='fw-bold text-muted'>
                                <th style={{ width: "10px" }}></th>
                                <th style={{ width: "10px" }}>No</th>
                                <th className='min-w-100px' style={{ textAlign: "center" }}>Fecha</th>
                                <th className='min-w-100px'>Cuota Mensual</th>
                                <th className='min-w-100px'>Mora</th>
                                <th className='min-w-100px'>Cuota a pagar</th>
                                <th className='min-w-80px'>Saldo</th>
                                <th style={{ width: "10px", textAlign: "center" }}>Accion</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: "12px" }}>
                            {
                                planPagosInicial.map((pago, i) =>
                                    <TerceroPagoRow
                                        key={i}
                                        i={i}
                                        pago={pago}
                                    />)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

interface TerceroPagoInicialRowProp {
    pago: IPagoInicialColumna,
    i: number
}

const TerceroPagoRow: FC<TerceroPagoInicialRowProp> = ({
    pago,
    i
}) => {
    const { actualizarPagoCuotaInicial, actualizarFechaCuotaInicial, eliminarCuotaInicial } = usePropiedad();

    const { inputRef, escribiendo } = useEscribiendo();

    useEffect(() => {
        if (!inputRef.current) return;
        const current = inputRef.current;

        if (!escribiendo) {
            const fecha = date.parse(current.value, "YYYY-MM-DD");
            if (!isNaN(fecha.getTime())) {
                if (fecha.getFullYear() > 1000) {
                    actualizarFechaCuotaInicial(i, fecha);
                }
            }
        }
    }, [inputRef, escribiendo]);

    const onChangePago = (value: number) => {
        actualizarPagoCuotaInicial(i, value)
    }

    return (
        <tr key={i} className={pago.className ?? ""}>
            <td></td>
            <td>
                <span className='text-dark fw-bold text-hover-primary' style={{ cursor: "default" }}>
                    {pago.numeroPago ?? ""}
                </span>
            </td>
            <td className='d-flex flex-column justify-content-center align-items-center'>
                {
                    (pago.estadoPagoId == EstadoPagoEnum.pendiente) ? (
                        <input
                            ref={inputRef}
                            style={{ maxWidth: "100px", fontSize: "10px" }}
                            className='form-control form-control-sm m-0'
                            type={"date"}
                            autoComplete='off'
                            placeholder={"Fecha"}
                            defaultValue={pago.fecha ? pago.fecha.formato() : undefined}
                        />
                    ) : (
                        <span className='text-dark fw-bold text-hover-primary' style={{ cursor: "default" }}>
                            {pago.fecha ? date.format(pago.fecha, "DD/MM/YYYY") : ""}
                        </span>
                    )
                }
            </td>
            <td>
                {
                    (pago.estadoPagoId == EstadoPagoEnum.pendiente) ? (
                        <InputMonetario2
                            style={{ maxWidth: "150px", fontSize: "10px" }}
                            min={0}
                            placeholder={"Cuota"}
                            defaultValue={pago.cuota}
                            onChange={onChangePago}
                        />
                    ) : (
                        <span className='text-dark fw-bold text-hover-primary d-block mb-1' style={{ cursor: "default" }}>
                            {(pago.cuota) ? pago.cuota.formatoMoneda2(true) : ""}
                        </span>
                    )
                }
            </td>
            <td>
                <span className={`${(pago.estadoPagoId != EstadoPagoEnum.pagado && pago.mora > 0) ? "text-danger" : "text-dark"} fw-bold text-hover-primary`} style={{ cursor: "default" }}>
                    {pago.mora.formatoMoneda2(true)}
                </span>
            </td>
            <td>
                <span className={`text-dark fw-bold text-hover-primary`} style={{ cursor: "default" }}>
                    {(pago.mora + pago.cuota).formatoMoneda2(true)}
                </span>
            </td>
            <td>
                <span className='text-dark fw-bold text-hover-primary d-block mb-1' style={{ cursor: "default" }}>
                    {(pago.saldo) ? pago.saldo.formatoMoneda2(true) : ""}
                </span>
            </td>
            <td className="p-1">
                {
                    (pago.estadoPagoId == EstadoPagoEnum.pendiente) && (
                        <div className='mb-2 col p-2 d-flex justify-content-center align-items-center'>
                            <BotonIcono tooltip='Eliminar cuota' icon="bi-trash3" onClick={() => eliminarCuotaInicial(i)} />
                        </div>
                    )
                }
            </td>
        </tr>
    )
}
