import { FC, useEffect } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { useListView } from "../../../../shared/providers/ListView/ListView";
import { useThemeMode } from "../../../../../_metronic/partials";
import { NoDataComponent } from "../../../../shared/ListView/NoDataComponent";
import date from "date-and-time"
import { EstadoPagoEnum } from "../../../../../interfaces/enums/EnumEstadoPago";
import { TipoPago } from "../../../../shared/misc/BotonesAttributos";
import { EstadoPago } from "../../../../shared/misc/BotonesAttributos/EstadoPago";
import { BotonDescargarComrpobante } from "../../../Terceros/perfil/paginas/pagos/components/BotonDescargarComrpobante";
import { IPagoInforme } from "../../../../../interfaces/pago/IPagoInforme";

const tableColumns: TableColumn<IPagoInforme>[] = [
  {
    name: 'No',
    selector: pago => pago.consecutivo,
    cell: pago => `#${pago.consecutivo}`,
    width: "40px",
    sortable: true
  }, {
    name: 'Cliente',
    selector: pago => pago.nombreCompleto,
    sortable: true,
    wrap: true
  }, {
    name: 'Propiedad',
    selector: pago => pago.propiedad!,
    sortable: true,
    width: "80px"
  }, {
    name: 'No Cuota',
    selector: pago => pago.numeroCuota,
    center: true
  }, {
    name: 'Tipo de pago',
    selector: pago => pago.tipoPagoId,
    cell: pago => <TipoPago tipoPagoId={pago.tipoPagoId} />,
    center: true
  }, {
    name: 'Estado',
    selector: pago => pago.estadoPagoId,
    cell: pago => <EstadoPago estadoPagoId={pago.estadoPagoId} />,
    center: true
  }, {
    name: 'Fecha',
    selector: pago => new Date(pago.fecha).getTime(),
    cell: pago => date.format(new Date(pago.fecha), "YYYY/MM/DD"),
    sortable: true
  }, {
    name: 'Referencia de pago',
    selector: pago => pago.referenciaPago,
    width: "150px"
  }, {
    name: 'Valor',
    selector: pago => pago.valor && pago.valor.formatoMoneda2(true),
    sortable: true
  }, {
    name: 'Interes',
    selector: pago => pago.interes && pago.interes.formatoMoneda2(true)
  }, {
    name: 'Capital',
    selector: pago => pago.capital && pago.capital.formatoMoneda2(true)
  }, {
    name: 'Abono',
    selector: pago => pago.abono && pago.abono.formatoMoneda2(true)
  }, {
    name: 'Mora',
    selector: pago => pago.mora && pago.mora.formatoMoneda2(true)
  }, {
    name: 'Acciones',
    cell: pago => (pago.estadoPagoId == EstadoPagoEnum.pagado) && <BotonDescargarComrpobante pago={pago} />,
    width: "70px"
  }
]

export const InformePagosLista: FC = () => {

  const { filterData, cargandoDatos } = useListView();
  const { mode } = useThemeMode();

  return (
    <div className="px-5">
      <DataTable
        columns={tableColumns}
        data={filterData}
        progressPending={cargandoDatos}
        pagination
        persistTableHead
        theme={mode == "dark" ? "dark" : "light"}
        noDataComponent={<NoDataComponent/>}
        keyField="idAuditoria"
        paginationComponentOptions={{
          rowsPerPageText: "Filas por pagina:"
        }}
      />
    </div>
  )
}

