import { FC } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { useListView } from "../../../../shared/providers/ListView/ListView";
import { useThemeMode } from "../../../../../_metronic/partials";
import { NoDataComponent } from "../../../../shared/ListView/NoDataComponent";
import date from "date-and-time"
import { IInformeCuenta } from "../../../../../interfaces/informes/IInformeCuenta";
import { EstadoCuota } from "../../../../shared/misc/BotonesAttributos/EstadoCuota";

const tableColumns: TableColumn<IInformeCuenta>[] = [
  {
    name: 'Codigo',
    selector: row => row.prefijo
  }, {
    name: 'Fecha',
    selector: row => date.format(row.fecha, 'DD-MM-YYYY'),
    sortFunction: (a, b) => a.fecha.getTime() - b.fecha.getTime(),
    sortable: true
  }, {
    name: 'Nombre cliente',
    selector: row => row.nombre,
    wrap: true
  }, {
    name: 'No Cuota',
    selector: row => row.numeroCuota,
    sortable: true
  }, {
    name: 'Cuota',
    selector: row => row.cuota.formatoMoneda2(true)
  }, {
    name: 'Lote',
    selector: row => row.lote
  }, {
    name: 'Estado Pago',
    selector: row => row.estadoCuotaId,
    cell: row => <EstadoCuota estadoPagoId={row.estadoCuotaId} />,
    center: true,
    width: "120px"
  }, {
    name: 'Total recibido',
    selector: row => row.valorPagado.formatoMoneda2(true)
  }, {
    name: 'Dias',
    selector: row => (row.estadoCuotaId == 2) ? "" : row.dias
  }
]

export const InformeCuentaLista: FC = () => {

  const { filterData, cargandoDatos } = useListView();
  const { mode } = useThemeMode();

  return (
    <div className="px-5">
      <DataTable
        columns={tableColumns}
        data={filterData}
        progressPending={cargandoDatos}
        pagination
        persistTableHead
        theme={mode == "dark" ? "dark" : "light"}
        noDataComponent={<NoDataComponent/>}
        keyField="idAuditoria"
        paginationComponentOptions={{
          rowsPerPageText: "Filas por pagina:"
        }}
      />
    </div>
  )
}

