import { addressToPos } from "../addresToPos";

export const p_header = {
    from: addressToPos("A1"),
    to: addressToPos("J5")
};

export const p_footer = {
    from: addressToPos("A8"),
    to: addressToPos("J10"),
};

export const p_fecha_consulta = addressToPos("I9")

export const p_lista_1 = {
    from: addressToPos("A6"),
    to: addressToPos("J6"),
};

export const p_lista_2 = {
    from: addressToPos("A7"),
    to: addressToPos("J7"),
};

