import * as Yup from 'yup'

export const propiedadConfiguracionSchema = Yup.object().shape({
    descripcion: Yup.string()
        .min(3, 'Mínimo 3 símbolos.')
        .max(50, 'Máximo 50 símbolos.')
        .required('Campo requerido.'),
    areaPropiedad: Yup.number()
        .required('Campo requerido.')
        .typeError('Formato incorrecto.'),
    valorM2: Yup.string()
        .test('len', 'El valor del m2 debe ser mayor a 0.', (val) => {
            val = val ? val.replace(/\D/g, "") : "0";
            return parseFloat(val ?? "0") > 0;
        })
        .required('Campo requerido.'),
    interesAnual: Yup.number()
        .required('Campo requerido.')
        .typeError('Formato incorrecto.'),
});

export const propiedadAsignacion2Schema = Yup.object().shape({
    id: Yup.string()
        .required('Campo requerido.'),
    propiedadId: Yup.string()
        .required('Campo requerido.'),
    terceroId: Yup.string()
        .required('Campo requerido.'),
    descripcion: Yup.string()
        .min(3, 'Mínimo 3 símbolos.')
        .max(50, 'Máximo 50 símbolos.')
        .required('Campo requerido.'),
    areaPropiedad: Yup.number()
        .required('Campo requerido.')
        .typeError('Formato incorrecto.'),
    valorM2: Yup.string()
        .required('Campo requerido.'),
    interesAnual: Yup.number()
        .required('Campo requerido.')
        .typeError('Formato incorrecto.'),
    cantidadPagos: Yup.number()
        .required('Campo requerido.')
        .typeError('Formato incorrecto.')
});

export const propiedadAsignacionSchema = Yup.object().shape({
    id: Yup.string()
        .required('Campo requerido.'),
    areaPropiedad: Yup.number()
        .required('Campo requerido.')
        .typeError('Formato incorrecto.'),
    valorM2: Yup.string()
        .required('Campo requerido.'),
    interesAnual: Yup.number()
        .required('Campo requerido.')
        .typeError('Formato incorrecto.'),
    propiedadId: Yup.string()
        .required('Campo requerido.')
});