import { FC } from "react"
import { Navigate, Outlet, Route, Routes } from "react-router-dom"
import { PageLink, PageTitle } from "../../../_metronic/layout/core"
import { InformeCuenta } from "./InformeCuenta/InformeCuenta"
import { InformeEdades } from "./InformeEdades/InformeEdades"
import { InformePagos } from "./InformePagos/InformePagos"

const accountBreadCrumbs: Array<PageLink> = [
    {
        title: 'Informes',
        path: '/informe/cuenta',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

export const InformesRouter: FC = () => {
    return (
        <Routes>
            <Route
                element={
                    <>
                        {/*<AuditoriasHeader /> */}
                        <Outlet />
                    </>
                }
            >
                <Route
                    path='cuenta'
                    element={
                        <>
                            <PageTitle breadcrumbs={accountBreadCrumbs}>Cuenta</PageTitle>
                            <InformeCuenta />
                        </>
                    }
                />
                <Route
                    path='edades'
                    element={
                        <>
                            <PageTitle breadcrumbs={accountBreadCrumbs}>Edades</PageTitle>
                            <InformeEdades />
                        </>
                    }
                />
                <Route
                    path='pagos'
                    element={
                        <>
                            <PageTitle breadcrumbs={accountBreadCrumbs}>Pagos</PageTitle>
                            <InformePagos />
                        </>
                    }
                />
                <Route index element={<Navigate to='/informes/cuenta' />} />
            </Route>
        </Routes>
    )
}