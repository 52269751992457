import { FC, useCallback } from "react";
import clsx from "clsx";
import { FieldInputProps } from "formik";

export interface InputDecimalProps {
    nombre?: string
    placeholder?: string
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
    props: FieldInputProps<string | number | readonly string[] | undefined>
    validProp?: boolean | undefined
    error?: string | undefined
    requerido?: boolean
    title?: string
    disabled?: boolean
    validate?: boolean
    showError?: boolean
    min?: number
    max?: number
    className?: string
}

export const InputDecimal: FC<InputDecimalProps> = ({
    props,
    nombre,
    placeholder,
    error,
    validProp,
    requerido,
    title,
    validate = true,
    min,
    max,
    disabled = false,
    className = "",
    setFieldValue
}) => {

    const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
        var number = e.target.value.replace(/[^0-9.]/g, '');
        if(number == "") number = "0";

        var points = number.split('.');
        if (points.length > 1) number = points.shift() + '.' + points.join('');
        else number = points[0];
        number = number.replace(/^0+(?!$)/g, '');

        e.target.value = number;
        if(e.target.value != ""){
            setFieldValue(props.name, e.target.value);
        }
    }, []);

    const onBlur: React.FocusEventHandler<HTMLInputElement> = useCallback((e) => {
        var number = e.target.value;
        if(number == "") number = "0";

        if (number[number.length - 1] == ".") {
            number = number.slice(0, number.length - 1);
        }

        if (max && parseFloat(number) > max) {
            number = max.toString();
        }else if (min && parseFloat(number) < min) {
            number = min.toString();
        }

        e.target.value = number;
        setFieldValue(props.name, parseFloat(e.target.value));
    }, []);

    return (
        <div className={`mb-2 p-1 ${className}`}>
            {nombre && <label className='form-label fs-6 fw-bolder text-dark' title={title}>{nombre} {(requerido) ? <span className="text-danger">*</span> : ""}</label>}
            <input
                disabled={disabled}
                autoComplete="off"
                placeholder={placeholder}
                name={props.name}
                onBlur={onBlur}
                onChange={handleInputChange}
                value={props.value ?? ""}
                className={clsx(
                    'form-control form-control-sm bg-transparent',
                    { 'is-invalid': validate && validProp && error },
                    {
                        'is-valid': validate && validProp && !error,
                    }
                )}
            />
            {validate && validProp && error && (
                <div className='fv-plugins-message-container'>
                    <span className="text-danger" role='alert'>{error}</span>
                </div>
            )}
        </div>
    );
};