import { FC } from "react"
import { IUsuario } from "../../../../../../interfaces/IUsuario"
import { BotonIcon } from "../../../../../shared/misc/BotonOpenModal"

interface BotonEditarUsuarioProps {
    usuario: IUsuario
    openModal: (usuario: Partial<IUsuario>) => void
}

export const BotonEditarUsuario: FC<BotonEditarUsuarioProps> = ({
    usuario,
    openModal
}) => {
    return <BotonIcon icon="bi-pencil-square" tooltip="Editar Usuario" onClick={()=>openModal({...usuario, contrasena: ""})} />
} 
