import { FC } from "react"
import { Navigate, Outlet, Route, Routes } from "react-router-dom"
import { PageLink, PageTitle } from "../../../_metronic/layout/core"
import { AuditoriasHeader } from "./AuditoriasHeader"
import { AuditoriaUsuarios } from "./Usuarios/AuditoriaUsuarioManagment"
import { AuditoriaTerceros } from "./Terceros/AuditoriaTerceroManagament"
import { AuditoriaSesiones } from "./Sesiones/AuditoriaSesionManagament"
import { AuditoriaPropiedadTManagament } from "./PropiedadTercero/AuditoriaPropiedadTManagament"
import { AuditoriaPropiedadCManagament } from "./PropiedadCaracteristica/AuditoriaPropiedadCManagament"
import { AuditoriaPago } from "./Pago/AuditoriaPago"

const accountBreadCrumbs: Array<PageLink> = [
    {
        title: 'Auditorias',
        path: '/auditorias/usuarios',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

export const AuditoriasRouter: FC = () => {
    return (
        <Routes>
            <Route
                element={
                    <>
                        {/*<AuditoriasHeader /> */}
                        <Outlet />
                    </>
                }
            >
                <Route
                    path='sesiones'
                    element={
                        <>
                            <PageTitle breadcrumbs={accountBreadCrumbs}>Sesiones</PageTitle>
                            <AuditoriaSesiones />
                        </>
                    }
                />
                <Route
                    path='usuarios'
                    element={
                        <>
                            <PageTitle breadcrumbs={accountBreadCrumbs}>Usuarios</PageTitle>
                            <AuditoriaUsuarios />
                        </>
                    }
                />
                <Route
                    path='clientes'
                    element={
                        <>
                            <PageTitle breadcrumbs={accountBreadCrumbs}>Clientes</PageTitle>
                            <AuditoriaTerceros />
                        </>
                    }
                />
                <Route
                    path='propiedad-tercero'
                    element={
                        <>
                            <PageTitle breadcrumbs={accountBreadCrumbs}>Propiedad Tercero</PageTitle>
                            <AuditoriaPropiedadTManagament />
                        </>
                    }
                />
                <Route
                    path='propiedad-caracteristica'
                    element={
                        <>
                            <PageTitle breadcrumbs={accountBreadCrumbs}>Propiedad Caracteristica</PageTitle>
                            <AuditoriaPropiedadCManagament />
                        </>
                    }
                />
                <Route
                    path='pagos'
                    element={
                        <>
                            <PageTitle breadcrumbs={accountBreadCrumbs}>Pagos</PageTitle>
                            <AuditoriaPago />
                        </>
                    }
                />
                <Route index element={<Navigate to='/auditorias/sesiones' />} />
            </Route>
        </Routes>
    )
}