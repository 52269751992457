import { FC, ReactNode } from "react"

export interface FormularioCampoProps {
    nombre: string
    requerido?: boolean
    children: ReactNode
}

export const FormularioCampo: FC<FormularioCampoProps> = ({
    nombre,
    requerido = false,
    children
}) => {
    return (
        <div className="col-12">
            <div className="row">
                <div className="col-12 col-sm-2 mb-0 d-flex align-items-center">
                    <label className='col-form-label fw-bold fs-6 p-0'>{nombre}{requerido ? <span className="text-danger"> *</span> : ''}</label>
                </div>
                <div className="col-12 col-sm-10 mt-0">
                    {children}
                </div>
            </div>
        </div>
    )
}