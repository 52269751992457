import { FC, useEffect, useState } from "react";
import { ListViewProvider } from "../../../shared/providers/ListView/ListView";
import { KTCard } from "../../../../_metronic/helpers";
import { AuditoriaPropiedadTLista } from './components/AuditoriaPropiedadTLista';
import { AuditoriaServicio } from "../../../../servicios/auditoriaServicio";
import { AuditoriaPropiedadTHeader } from "./components/AuditoriaPropiedadTHeader";
import { IAPropiedadTercero } from "../../../../interfaces/auditorias/IAPropiedadTercero";
import { ModalAuditoriaPropiedadTProvider } from "./providers/ModalAuditoriaPropiedadT";

export const AuditoriaPropiedadTManagament: FC = () => {

    const [aPropiedadesCaracteristicasT, setACaracteristicasTerceros] = useState<IAPropiedadTercero[]>([]);

    useEffect(() => {
        const obtenerAuditoriaUsuarios = async () => {
            const aPropiedadesCaracteristicasT = await AuditoriaServicio.AuditoriaPropiedadTercero();
            setACaracteristicasTerceros(aPropiedadesCaracteristicasT);
        }
        obtenerAuditoriaUsuarios();
    }, []);

    return (
        <KTCard>
            <ListViewProvider initialData={aPropiedadesCaracteristicasT} >
                <ModalAuditoriaPropiedadTProvider>
                    <AuditoriaPropiedadTHeader />
                    <AuditoriaPropiedadTLista />
                </ModalAuditoriaPropiedadTProvider>
            </ListViewProvider>
        </KTCard>
    )
}
