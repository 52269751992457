import { FC, createContext, useContext, useEffect, useState } from "react";
import { WithChildren } from "../../../_metronic/helpers";
import { IActividadEconomica } from "../../../interfaces/IActividadEconomica";
import { IUbicacion } from "../../../interfaces/IUbicacion";
import { InformacionServicios } from "../../../servicios/ubicacionServicios";

export type ObjectType<T> = { [key: string]: T }

interface InformacionProviderProps {
    municipios: IUbicacion[]
    actividadesEconomicas: IActividadEconomica[]
    municipiosObject: ObjectType<IUbicacion>
    actividadesEconomicasObject: ObjectType<IActividadEconomica>
}


const initialInformacionProvider: InformacionProviderProps = {
    municipios: [],
    actividadesEconomicas: [],
    actividadesEconomicasObject: {},
    municipiosObject: {}
}

const InformacionProviderContext = createContext<InformacionProviderProps>(initialInformacionProvider)



const InformacionProvider: FC<WithChildren> = ({ children }) => {

    const [municipios, setMunicipios] = useState<IUbicacion[]>([]);
    const [actividadesEconomicas, setActividadesEconomicoas] = useState<IActividadEconomica[]>([]);

    const [municipiosObject, setMunicipiosObject] = useState<ObjectType<IUbicacion>>({});
    const [actividadesEconomicasObject, setactividadesEconomicasObject] = useState<ObjectType<IActividadEconomica>>({});


    const obtenerInformacion = async () => {
        const municipios = await InformacionServicios.ObtenerUbicaciones();
        const actividadesEconomicas = await InformacionServicios.ObtenerActividadesEconmica();

        const municipiosObject = municipios.reduce<ObjectType<IUbicacion>>((obj, municipio) => {
            obj[municipio.id] = municipio;
            return obj;
        }, {});

        const actividadesEconomicasObject = actividadesEconomicas.reduce<ObjectType<IActividadEconomica>>((obj, act) => {
            obj[act.codigo] = act;
            return obj;
        }, {});

        setMunicipios(municipios);
        setActividadesEconomicoas(actividadesEconomicas);
        setMunicipiosObject(municipiosObject);
        setactividadesEconomicasObject(actividadesEconomicasObject);
    }


    useEffect(() => {
        const obtenerInformacion = async () => {
            const municipios = await InformacionServicios.ObtenerUbicaciones();
            const _municipiosObject = {};
            municipios.forEach(municipio => {
                _municipiosObject[municipio.id] = municipio;
            });
            setMunicipiosObject(_municipiosObject);
        }
        obtenerInformacion();
    }, []);

    useEffect(() => {
        obtenerInformacion();
    }, []);

    return (
        <InformacionProviderContext.Provider value={{
            municipios,
            actividadesEconomicas,
            actividadesEconomicasObject,
            municipiosObject
        }}>
            {children}
        </InformacionProviderContext.Provider>
    )
}

const useInformacion = () => useContext(InformacionProviderContext);

export {
    InformacionProvider,
    useInformacion
}
