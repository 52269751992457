import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { FormikProps, useFormik } from "formik";
import { useTercero } from "../../../provider/TerceroProvider";
import { terceroPropiedadSchema } from "../../../../../../../validators/terceroPropiedadSchema";
import { WithChildren } from "../../../../../../../_metronic/helpers";
import { PropiedadServicio } from "../../../../../../../servicios/propiedadServicio";
import { propiedadTerceroIniciales } from "../../../../../../shared/datosFormularios/DatosInicialesFormularios";
import * as alertify from "alertifyjs";
import { EnumTipoPago } from "../../../../../../../interfaces/enums/EnumTipoPago";
import { CalcularCuota } from "../../../../../../../utils/calculos/CalcularCuota";
import { IPago } from "../../../../../../../interfaces/pago/IPago";
import { ICuota } from "../../../../../../../interfaces/cuotas/ICuota";
import { CuotaServicio } from "../../../../../../../servicios/cuotaServicio";
import { EstadoPagoEnum } from "../../../../../../../interfaces/enums/EnumEstadoPago";
import { IPagoInicialColumna, usePagosIniciales } from "../hooks/usePagosIniciales";
import { IPagoColumna, usePagos } from "../hooks/usePagos";
import { useCuotas } from "../hooks/useCuotas";
import { PeriodoEnum } from "../../../../../../shared/datosFormularios/Informacion";
import { IPropiedadesTercero } from "../../../../../../../interfaces/propiedades/IPropiedadesTercero";
import { useLocation, useSearchParams } from "react-router-dom";

/**
 * Provider para el calculo de los valores de la propiedad.
 * 
 * Toma los valores del input -> ( @see PropiedadesTercero )
 * y cuando detecte un cambio en uno de esos valores del input crea un nuevo objeto a partir de esos valores -> ( @see Propiedad )
 * 
 */


// Interface de la propiedad
export interface Propiedad {
    interesDiario: number
    cantidadPagos: number
    pagoInicial: number
    porcentajePagoInicial: number
    porcentajeMora: number
    valorTotalLote: number
    valorFinanciar: number
    valorCuota: number
}

export const propiedadInitial: Propiedad = {
    cantidadPagos: 0,
    pagoInicial: 0,
    porcentajePagoInicial: 0,
    porcentajeMora: 0,
    valorTotalLote: 0,
    valorFinanciar: 0,
    valorCuota: 0,
    interesDiario: 0
}

// Propiedades del provider
interface PropiedadProviderProps {
    formik: FormikProps<IPropiedadesTercero> // formulario de la propiedad
    propiedad: Propiedad // propiedad que contiene todos los datos calculados
    pagos: IPago[] // Total pagos realizados
    planPagos: IPagoColumna[] // Plan de pagos (cuotas normales)
    planPagosInicial: IPagoInicialColumna[] // Cuotas iniciales
    saldoRestante: number // Saldo restante
    totalMora: number // Total mora
    totalAbonos: number // Total abonos
    actualizarFechaCuotaNormal: (i: number, fecha: Date) => void
    actualizarFechaCuotaInicial: (numeroCuota: number, fecha: Date) => void
    actualizarPagoCuotaInicial: (numeroCuota: number, valor: number) => void
    agregarCuotaInicial: () => void
    eliminarCuotaInicial: (numeroCuota: number) => void
    valorPagoInicialRestante: number
}

// Datos iniciales del provider
const initialFormularioProvider: PropiedadProviderProps = {
    propiedad: propiedadInitial,
    formik: {} as FormikProps<IPropiedadesTercero>,
    pagos: [],
    planPagos: [],
    planPagosInicial: [],
    saldoRestante: 0,
    totalMora: 0,
    totalAbonos: 0,
    actualizarFechaCuotaNormal: () => true,
    actualizarFechaCuotaInicial: () => { },
    actualizarPagoCuotaInicial: () => { },
    agregarCuotaInicial: () => { },
    eliminarCuotaInicial: () => { },
    valorPagoInicialRestante: 0
}

// Creacion del context
const PropiedadProviderContext = createContext<PropiedadProviderProps>(initialFormularioProvider)

export const PropiedadProvider: React.FC<WithChildren> = ({ children }) => {

    // Estado para el manejo de parametros
    const [getQP, setQP] = useSearchParams();

    // Datos del cliente
    const { tercero, propiedades, setPropiedades, pagosPorPropiedad } = useTercero();

    // Estado para almacenar las Cuotas de la propiedad actual
    const [cuotas, setCuotas] = useState<ICuota[]>([]);

    // Formulario
    const formik = useFormik({
        initialValues: propiedadTerceroIniciales,
        enableReinitialize: true,
        validationSchema: terceroPropiedadSchema,
        onSubmit: async (values) => {
            try {
                if (!tercero) throw new Error();
                

                await PropiedadServicio.ActualizarAsignacion({
                    id: values.id,
                    valorTotal: values.valorTotal,
                    areaPropiedad: values.areaPropiedad,
                    interesAnual: values.interesAnual,
                    cantidadPagos: values.cantidadPagos,
                    fechaInicioFinanciacion: values.fechaInicioFinanciacion,
                    fechaLimitePagoInicial: values.fechaLimitePagoInicial,
                    pagoInicial: values.pagoInicial,
                    periodoId: values.periodoId,
                    porcentajeMora: values.porcentajeMora,
                    cuotas: cuotasSinPagar.map(cuota => ({
                        numero: cuota.numeroCuota!,
                        fecha: cuota.fecha
                    })),
                    cuotasIniciales: cuotasInicialSinPagar.map(cuota => ({
                        numero: cuota.numeroPago,
                        valor: cuota.cuota,
                        fecha: cuota.fecha!
                    }))
                });

                setPropiedades((p) => {
                    const idx = p.findIndex((e) => e.propiedadId == values.propiedadId);
                    if (idx != -1) {
                        p[idx] = values;
                    }
                    return p;
                });
                alertify.success("Propiedad actualizada.");
            } catch (err) {
                //alertify.error("No se pudo actualizar la propiedad del cliente.");
            }
        }
    });

    // Obtiene el parametro propiedadId de la url
    useEffect(() => {
        const propiedadTerceroId = getQP.get("propiedadTerceroId");
        if(propiedadTerceroId){
            const propiedad = propiedades.find(p => p.id == propiedadTerceroId)
            if(propiedad){
                formik.setFieldValue("id", propiedad.id);
                return;
            }
        }
        if(propiedades[0]){
            formik.setFieldValue("id", propiedades[0].id);
        }
    }, [propiedades]);

    // Estado para calcular los valores del lote
    const propiedad: Propiedad = useMemo(() => {
        const {
            areaPropiedad,
            valorM2,
            interesAnual,
            cantidadPagos,
            pagoInicial,
            porcentajeMora
        } = formik.values;

        const valorTotalLote = (areaPropiedad * valorM2);

        const porcentajePagoInicial = valorTotalLote == 0 ? 0 : (pagoInicial * 100) / valorTotalLote;
        const valorFinanciar = valorTotalLote - pagoInicial;

        const interesMensual = (interesAnual / 100) / 12;
        const interesDiario  = (interesAnual / 100) / 360;
        const mora = porcentajeMora / 100;

        const valorCuota = CalcularCuota(valorFinanciar, interesMensual, cantidadPagos);

        return {
            cantidadPagos,
            interesDiario,
            pagoInicial,
            porcentajeMora: mora,
            porcentajePagoInicial,
            valorCuota,
            valorFinanciar,
            valorTotalLote
        }
    }, [
        formik.values.areaPropiedad,
        formik.values.valorM2,
        formik.values.interesAnual,
        formik.values.cantidadPagos,
        formik.values.pagoInicial,
        formik.values.porcentajeMora
    ]);

    // Pagos de la propiedad actual seleccionada
    const pagosPropiedad: IPago[] = useMemo(() => pagosPorPropiedad[formik.values.id] ?? [], [formik.values.id, pagosPorPropiedad]);

    // Custom hook para separar las cuotas (cuotas iniciales, normales, pendientes, etc.)
    const {
        cuotasPagoInicial,
        cuotasPIpagadas,
        cuotasPagoNormal,
        cuotasPNpagadas,
        pagosAbonos
    } = useCuotas(cuotas, pagosPropiedad);

    // Custom hook para el manejo de las cuotas iniciales
    const {
        actualizarFecha: actualizarFechaInicial,
        actualizarPago: actualizarPagoInicial,
        agregarCuota: agregarCuotaInicial,
        eliminarCuota: eliminarCuotaInicial,
        planPagoInicial: planPagosInicial,
        valorRestante: valorPagoInicialRestante
    } = usePagosIniciales(
        formik.values.fechaLimitePagoInicial,
        formik.values.pagoInicial,
        cuotasPagoInicial,
        propiedad.porcentajeMora
    );

    // Custom hook para el manejo de las cuotas normales
    const {
        cuotas: planPagos,
        actualizarFechaCuotaNormal
    } = usePagos(
        propiedad,
        cuotasPagoNormal,
        cuotasPNpagadas,
        formik.values.fechaInicioFinanciacion,
        parseInt(formik.values.periodoId.toString()),
        pagosAbonos,
        () => {
            formik.setFieldValue("periodoId", PeriodoEnum.Abierto);
        }
    )
    
    // Total abonos
    const totalAbonos: number = useMemo(() => pagosAbonos.reduce((p, c) => p + c.abono, 0), [pagosAbonos]);

    // Cuotas pendientes del plan de pagos
    const cuotasSinPagar: IPagoColumna[] = useMemo(() => (
        planPagos
            .filter(cuota => cuota.tipoPagoId != EnumTipoPago.abono && cuota.estadoPagoId == EstadoPagoEnum.pendiente)
            .map((v, i) => ({ ...v, numeroPago: i + 1 + cuotasPNpagadas.length }))
    ), [cuotasPNpagadas, planPagos]);

    // Cuotas iniciales pendientes del plan de pagos
    const cuotasInicialSinPagar: IPagoInicialColumna[] = useMemo(() => (
        planPagosInicial
            .slice(1)
            .filter(cuota => cuota.estadoPagoId == EstadoPagoEnum.pendiente)
            .map((v, i) => ({ ...v, numeroPago: i + 1 + cuotasPIpagadas.length }))
    ), [cuotasPIpagadas, planPagosInicial]);

    // saldo restante
    const saldoRestante: number = useMemo(() => {
        const saldoCuotasIniciales = planPagosInicial.length > 1 
            ? planPagosInicial.reduce((p, c) => (c.estadoPagoId == EstadoPagoEnum.pendiente) ? p + c.cuota : p, 0)
            : propiedad.pagoInicial;
        const saldoCuotasNormales  = planPagos.length > 0 
            ? planPagos.reduce((p, c) => (c.estadoPagoId == EstadoPagoEnum.pendiente) ? p + c.cuota : p, 0)
            : propiedad.valorFinanciar;
        return saldoCuotasIniciales + saldoCuotasNormales;
    }, [
        propiedad.pagoInicial,
        propiedad.valorFinanciar,
        planPagosInicial,
        planPagos
    ]);

    // Total Mora
    const totalMora: number = useMemo(() => planPagos.reduce((p, c) => p + c.mora, 0), [planPagos]);

    // Cuando se cambia el id de la propiedad del formulario, busca la propiedad y actualiza los campos, tambien cambia el valor del query param
    useEffect(() => {
        const obtenerCuotas = async (propiedadTerceroId: string) => {
            const cuotas = await CuotaServicio.obtenerCuotas(propiedadTerceroId);
            setCuotas([...cuotas]);
        }

        if (formik.values.id == '') return;
        const propiedad = propiedades.find(p => p.id == formik.values.id);
        
        if (propiedad) {
            let currenParams = getQP;
            currenParams.set("propiedadTerceroId", propiedad.id);
            setQP(currenParams);

            obtenerCuotas(propiedad.id);
            const valorTotal = propiedad.areaPropiedad * propiedad.valorM2;
            formik.setValues({ ...propiedad, valorTotal }, false);
            formik.setErrors({});
            formik.setTouched({});
        }
    }, [formik.values.id]);

    // Cuando cambia el valor del total, cambia el valor m2
    useEffect(() => {
        const {
            areaPropiedad,
            valorTotal
        } = formik.values;

        formik.setFieldValue("valorM2", areaPropiedad == 0 ? 0 : valorTotal / areaPropiedad);
    }, [
        formik.values.areaPropiedad,
        formik.values.valorTotal
    ]);

    return (
        <PropiedadProviderContext.Provider value={{
            propiedad,
            formik,
            pagos: pagosPropiedad,
            planPagos,
            planPagosInicial,
            saldoRestante,
            totalMora,
            totalAbonos,
            actualizarFechaCuotaNormal,
            actualizarFechaCuotaInicial: actualizarFechaInicial,
            actualizarPagoCuotaInicial: actualizarPagoInicial,
            agregarCuotaInicial: agregarCuotaInicial,
            eliminarCuotaInicial,
            valorPagoInicialRestante
        }}>
            {children}
        </PropiedadProviderContext.Provider>
    )
}

export const usePropiedad = () => useContext(PropiedadProviderContext);
