import { addressToPos } from "../addresToPos";

export const ec_header = {
    from: addressToPos("A1"),
    to: addressToPos("I9")
};

export const ec_footer = {
    from: addressToPos("A12"),
    to: addressToPos("I14")
};

export const ec_nombre_comercial = "C2";

export const ec_nit = "C3";

export const ec_total_cuota = addressToPos("F12");
export const ec_total_recibido = addressToPos("G12");

export const ec_lista_1 = {
    from: addressToPos("A10"),
    to: addressToPos("I10"),
};

export const ec_lista_2 = {
    from: addressToPos("A11"),
    to: addressToPos("I11"),
};

