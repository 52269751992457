import { FC } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { IPago } from "../../../../../../../interfaces/pago/IPago";
import { useListView } from "../../../../../../shared/providers/ListView/ListView";
import { useThemeMode } from "../../../../../../../_metronic/partials";
import { NoDataComponent } from "../../../../../../shared/ListView/NoDataComponent";
import date from "date-and-time";
import { TipoPago } from "../../../../../../shared/misc/BotonesAttributos";
import { BotonDescargarComrpobante } from "./BotonDescargarComrpobante";
import { EstadoPago } from "../../../../../../shared/misc/BotonesAttributos/EstadoPago";
import { EstadoPagoEnum } from "../../../../../../../interfaces/enums/EnumEstadoPago";

const tableColumns: TableColumn<IPago>[] = [
  {
    name: 'No',
    selector: pago => pago.consecutivo,
    cell: pago => `#${pago.consecutivo}`,
    width: "40px",
    sortable: true
  }, {
    name: 'Tipo de pago',
    selector: pago => pago.tipoPagoId,
    cell: pago => <TipoPago tipoPagoId={pago.tipoPagoId} />,
    center: true
  }, {
    name: 'Estado',
    selector: pago => pago.tipoPagoId,
    cell: pago => <EstadoPago estadoPagoId={pago.estadoPagoId} />,
    center: true
  }, {
    name: 'Fecha',
    selector: pago => new Date(pago.fecha).getTime(),
    cell: pago => date.format(new Date(pago.fecha), "YYYY/MM/DD"),
    sortable: true
  }, {
    name: 'Referencia de pago',
    selector: pago => pago.referenciaPago,
    width: "150px"
  }, {
    name: 'Valor',
    selector: pago => pago.valor.formatoMoneda2(true),
    sortable: true
  }, {
    name: 'Interes',
    selector: pago => pago.interes.formatoMoneda2(true)
  }, {
    name: 'Capital',
    selector: pago => pago.capital.formatoMoneda2(true)
  }, {
    name: 'Abono',
    selector: pago => pago.abono.formatoMoneda2(true)
  }, {
    name: 'Mora',
    selector: pago => pago.mora.formatoMoneda2(true)
  }, {
    name: 'Propiedad',
    selector: pago => pago.propiedad!,
    sortable: true,
    width: "80px"
  }, {
    name: 'Acciones',
    cell: pago => (pago.estadoPagoId == EstadoPagoEnum.pagado) && <BotonDescargarComrpobante pago={pago} />,
    width: "70px"
  }
]


export const ListaPagos: FC = () => {

  const { filterData, cargandoDatos } = useListView();
  const { mode } = useThemeMode();

  return (
    <div className="px-5">
      <DataTable
        columns={tableColumns}
        data={filterData}
        progressPending={cargandoDatos}
        pagination
        persistTableHead
        theme={mode == "dark" ? "dark" : "light"}
        noDataComponent={<NoDataComponent />}
        keyField="id"
        paginationComponentOptions={{
          rowsPerPageText: "Filas por pagina:"
        }}
      />
    </div>
  )
}

