/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { PageTitle } from '../../_metronic/layout/core'
import { Dashboard } from '../modules/dashboard/Dashboard'


const DashboardWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Inicio</PageTitle>
      <Dashboard/>
    </>
  )
}

export { DashboardWrapper }
