import { FC, useEffect, useState } from "react";
import { ListaPagosHeader } from "./components/ListaPagosHeader";
import { ListaPagos } from "./components/ListaPagos";
import { ListViewProvider } from "../../../../../shared/providers/ListView/ListView";
import { IPago } from "../../../../../../interfaces/pago/IPago";
import { PagoServicio } from "../../../../../../servicios/pagoServicio";
import { useTercero } from "../../provider/TerceroProvider";
import { KTCard } from "../../../../../../_metronic/helpers";
import { BotonAtras } from "../../../../../shared/formularioCliente/utils/BotonAtras";
import { useNavigate } from "react-router-dom";
import { ModalCrearPagoProvider } from "./providers/ModalCrearPago";

export const Pagos: FC = () => {

    const navigate = useNavigate();
    const { tercero } = useTercero();
    const [pagos, setPagos] = useState<IPago[]>([]);

    useEffect(() => {
        if (!tercero) return;
        const obtenerPagos = async () => {
            const pagos = await PagoServicio.obtenerPagos(tercero.id);
            setPagos([...pagos]);
        }
        obtenerPagos();
    }, [tercero]);

    return (
        <KTCard>
            <ListViewProvider initialData={pagos}>
                <ModalCrearPagoProvider>
                    <ListaPagosHeader />
                    <ListaPagos />
                    <BotonAtras onClick={() => navigate("/clientes/lista")} />
                </ModalCrearPagoProvider>
            </ListViewProvider>
        </KTCard>
    )
}