import { AuditoriaServicio } from "../../../../../servicios/auditoriaServicio";
import { useListView } from "../../../../shared/providers/ListView/ListView";
import { BotonActualizar } from "../../../../shared/misc/BotonActualizar";

export const AuditoriaPropiedadCHeaderToolbar = () => {

    const { setData: setAllData, setCargandoDatos } = useListView();

    const obtenerUsuarios = async () => {
        setCargandoDatos(true);
        const aPropCar = await AuditoriaServicio.AuditoriaPropiedadCaracteristica();
        setAllData(aPropCar);
    }

    return (
        <div className='d-flex justify-content-end align-items-center' data-kt-user-table-toolbar='base'>
            <div className='m-0'>
                <BotonActualizar onClick={obtenerUsuarios}/>
            </div>
        </div>
    )
}