import { FC } from "react";
import { BuscarComponent } from "../../../../shared/providers/ListView/BuscarComponent";
import { InformeEdadesHeaderToolbar } from "./InformeEdadesHeaderToolbar";

export const InformeEdadesHeader: FC = () => {
    
    return (
        <div className='card-header border-0 py-4'>
            <BuscarComponent placeholder="Buscar" />
            <div className='card-toolbar'>
                <InformeEdadesHeaderToolbar />
            </div>
        </div>
    )
}