/* eslint-disable react/jsx-no-target-blank */
import { FC } from 'react'
import { useAuth } from '../../../../../app/modules/Auth'
import { SidebarMenuItem } from './SidebarMenuItem'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { EnumRol } from '../../../../../interfaces/enums/EnumRol'


const SideBarAuditorias: FC = () => {
    return (
        <>
            <SidebarMenuItem
                to='/auditorias/sesiones'
                title="Sesiones"
                icon='bi-person-fill-up'
                hasBullet={true}
            />
            <SidebarMenuItem
                to='/auditorias/usuarios'
                title="Usuarios"
                icon='bi-person-fill-gear'
                hasBullet={true}
            />
            <SidebarMenuItem
                to='/auditorias/clientes'
                title="Clientes"
                icon='bi-people-fill'
                hasBullet={true}
            />
            <SidebarMenuItem
                to='/auditorias/propiedad-tercero'
                title="Propiedades Clientes"
                icon='bi-building-fill'
                hasBullet={true}
            />
            <SidebarMenuItem
                to='/auditorias/propiedad-caracteristica'
                title="Propiedades Configuración"
                icon='bi-building-fill-gear'
                hasBullet={true}
            />
            <SidebarMenuItem
                to='/auditorias/pagos'
                title="Pagos"
                icon='bi-cash-coin'
                hasBullet={true}
            />
        </>
    )
}

const SidebarMenuMain = () => {

    const { currentUser } = useAuth();

    return (
        <>
            <SidebarMenuItem
                to='/dashboard'
                icon='bi-graph-up-arrow'
                title="Dashboard"
                fontIcon='bi-app-indicator'
            />

            <SidebarMenuItemWithSub
                to='/clientes'
                title='Clientes'
                icon='bi-person-fill'
                fontIcon='bi-app-indicator'
            >
                <SidebarMenuItem
                    to='/clientes/lista'
                    icon='bi-people-fill'
                    title="Ver clientes" hasBullet={true}
                />
                {
                    (currentUser?.rolId === EnumRol.Administrador || currentUser?.rolId === EnumRol.SuperAdmin) && (
                        <SidebarMenuItem
                            to='/clientes/crear'
                            icon='bi-person-plus-fill'
                            title="Crear" hasBullet={true}
                        />
                    )
                }
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub
                to='/informes'
                title='Informes'
                icon='bi-file-bar-graph'
                fontIcon='bi-app-indicator'
            >
                <SidebarMenuItem
                    to='/informes/cuenta'
                    icon='bi-people-fill'
                    title="Informe cuenta" hasBullet={true}
                />
                <SidebarMenuItem
                    to='/informes/edades'
                    icon='bi-wallet-fill'
                    title="Informe edades" hasBullet={true}
                />
                <SidebarMenuItem
                    to='/informes/pagos'
                    icon='bi-currency-dollar'
                    title="Informe pagos" hasBullet={true}
                />
            </SidebarMenuItemWithSub>

            {
                (currentUser?.rolId === EnumRol.Administrador || currentUser?.rolId === EnumRol.SuperAdmin) && (
                    <SidebarMenuItem
                        to='/usuarios'
                        icon='bi-person-fill-gear'
                        title="Usuarios"
                        fontIcon='bi-app-indicator'
                    />
                )
            }

            <SidebarMenuItem
                to='/cartera'
                icon='bi-building-fill-gear'
                title="Propiedades"
                fontIcon='bi-app-indicator'
            />

            {
                (currentUser?.rolId === EnumRol.Administrador || currentUser?.rolId === EnumRol.SuperAdmin) && (
                    <SidebarMenuItemWithSub
                        to='/auditorias'
                        title='Auditorias'
                        icon='bi-card-list'
                        fontIcon='bi-app-indicator'
                    >
                        <SideBarAuditorias />
                    </SidebarMenuItemWithSub>
                )
            }

        </>
    )
}

export { SidebarMenuMain }
