import { ChangeEventHandler, FC, useEffect } from "react";
import clsx from "clsx";
import { FieldInputProps } from "formik";
import { Informacion } from "../../../app/shared/datosFormularios/Informacion";
import { BotonIcono } from "../../../app/shared/misc/BotonIcono";

export interface SelectProps {
    nombre?: string
    placeholder?: string
    props: FieldInputProps<string | number | readonly string[] | undefined>
    validProp?: boolean | undefined
    error?: string | undefined
    options: Informacion[]
    requerido?: boolean
    defaultValue?: string | number
    setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void
    disabled?: boolean
    disabledButton?: boolean
    className?: string
    onClick?: () => void
    tooltip?: string
    validate?: boolean
}

export const Select: FC<SelectProps> = ({
    nombre,
    props,
    validProp,
    error,
    options,
    requerido = false,
    placeholder,
    defaultValue,
    setFieldValue,
    disabled,
    className,
    validate = true
}) => {

    useEffect(() => {
        if (defaultValue && setFieldValue) {
            setFieldValue(props.name, defaultValue, false);
        }
    }, [defaultValue])

    return (
        <div className={`mb-2 p-1 ${className}`}>
            {nombre && <label className='form-label fs-6 fw-bolder text-dark'>{nombre} {(requerido) ? <span className="text-danger">*</span> : ""}</label>}
            <select
                required
                className={clsx(
                    'form-select form-select-sm bg-transparent',
                    { 'is-invalid': validProp && error },
                    {
                        'is-valid': validate && validProp && !error,
                    }
                )}
                name={props.name}
                onBlur={props.onBlur}
                value={props.value ?? undefined}
                onChange={props.onChange}
                disabled={disabled}
            >
                <option value="" disabled hidden>{placeholder}</option>
                {
                    options.map((option, i) => (
                        <option key={i} value={option.valor}>
                            {option.nombre}
                        </option>
                    ))
                }
            </select>
            {validProp && error && (
                <div className='fv-plugins-message-container'>
                    <span className='text-danger'>{error}</span>
                </div>
            )}
        </div>
    )
}


export const SelectAdd: FC<SelectProps> = ({
    nombre,
    props,
    validProp,
    error,
    options,
    requerido = false,
    placeholder,
    defaultValue,
    setFieldValue,
    disabled,
    disabledButton,
    className,
    onClick = () => {},
    tooltip
}) => {

    useEffect(() => {
        if (defaultValue && setFieldValue) {
            setFieldValue(props.name, defaultValue, false);
        }
    }, [defaultValue])

    const onChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
        props.onChange(e);
    }

    return (
        <div className={`mb-2 p-1 ${className}`}>
            {nombre && <label className='form-label fs-6 fw-bolder text-dark'>{nombre} {(requerido) ? <span className="text-danger">*</span> : ""}</label>}
            <div className="input-group">
                <select
                    required
                    disabled={disabled}
                    autoComplete="off"
                    className={clsx(
                        'form-control form-control-sm bg-transparent',
                        { 'is-invalid': validProp && error },
                        {
                            'is-valid': validProp && !error,
                        }
                    )}
                    name={props.name}
                    onBlur={props.onBlur}
                    value={props.value}
                    onChange={props.onChange}
                >
                    <option value="" disabled hidden>{placeholder}</option>
                    {
                        options.map((option, i) => (
                            <option key={i} value={option.valor}>
                                {option.nombre}
                            </option>
                        ))
                    }
                </select>
                <div className="input-group-append">
                    <BotonIcono 
                        tooltip={tooltip}
                        icon="bi-plus-circle"
                        disabled={disabledButton}
                        onClick={onClick}
                        style={{ borderRadius: "0px 5px 5px 0px" }} 
                    />
                </div>
            </div>
            {validProp && error && (
                <div className='fv-plugins-message-container'>
                    <span className='text-danger'>{error}</span>
                </div>
            )}
        </div>
    )
}

