import { FC } from "react"
import { Informacion } from "../../datosFormularios/Informacion"

interface AccionProps {
    accion: Informacion
    className?: string
}

export const Accion: FC<AccionProps> = ({
    accion,
    className
}) => {
    switch (accion.valor) {
        case "Modificar":
        case "Confirmar":
            return (
                <button className={`btn btn-sm nobtn-light-info fw-bolder ms-2 py-1 px-3 ${className}`}>
                    {accion.nombre}
                </button>
            )
        case "Insertar":
            return (
                <button className={`btn btn-sm nobtn-light-success  fw-bolder ms-2 py-1 px-3 ${className}`}>
                    {accion.nombre}
                </button>
            )
        case "Sesion":
            return (
                <button className={`btn btn-sm nobtn-light-primary fw-bolder ms-2 py-1 px-3 ${className}`}>
                    {accion.nombre}
                </button>
            )
        case "Modificacion Cliente":
            return (
                <button className={`btn btn-sm nobtn-light-primary fw-bolder ms-2 py-1 px-3 ${className}`}>
                    {accion.nombre}
                </button>
            )
        case "Desactivar Tercero":
            return (
                <button className={`btn btn-sm nobtn-light-warning fw-bolder ms-2 py-1 px-3 ${className}`}>
                    {accion.nombre}
                </button>
            )
        case "Cancelar":
            return (
                <button className={`btn btn-sm nobtn-light-danger fw-bolder ms-2 py-1 px-3 ${className}`}>
                    {accion.nombre}
                </button>
            )
        default:
            return (
                <button className={`btn btn-sm nobtn-light-warning fw-bolder ms-2 py-1 px-3 ${className}`}>
                    N/A
                </button>
            )
    }
}