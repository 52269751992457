import { ChangeEventHandler, FC, FormEventHandler } from "react"
import { Archivo } from "../../../utils/Files";

export { }

declare global {
    interface File {
        isLeapYear(): boolean;
    }
}

interface InputFileProps {
    nombre?: string,
    requerido?: boolean,
    onChange: (archivo: Archivo) => void
    accept?: string
}

export const InputFile: FC<InputFileProps> = ({
    nombre,
    requerido = true,
    onChange: onChangeFn,
    accept
}) => {

    const onChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
        if(!e.target.files) return;
        const file = e.target.files[0];

        const archivo = await file.toArchivo();
        onChangeFn(archivo);
    }

    return (
        <div className="mb-3">
            {nombre && <label className='form-label fs-6 fw-bolder text-dark'>{nombre} {(requerido) ? <span className="text-danger">*</span> : ""}</label>}
            <input
                className="form-control"
                type="file"
                onChange={onChange}
                accept={accept}
            />
        </div>
    )
}