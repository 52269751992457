import { FC } from "react";
import { ListaTerceros } from "./ListaTerceros";
import { ModalContactoProvider } from "../ModalContacto";

export const TerceroManagamentBody: FC = () => {

    return (
        <ModalContactoProvider>
            <div className="px-10">
                <ListaTerceros />
            </div>
        </ModalContactoProvider>
    )
}
