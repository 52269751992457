import { FC } from 'react'
import { TerceroInformacionPersonal } from '../../../../../shared/formularioCliente/components/TerceroInformacionPersonal'
import { TerceroDatosContacto } from '../../../../../shared/formularioCliente/components/TerceroDatosContacto'
import { TerceroDireccion } from '../../../../../shared/formularioCliente/components/TerceroDireccion'
import { TerceroHijos } from '../../../../../shared/formularioCliente/components/TerceroHijos'
import { TerceroMascotas } from '../../../../../shared/formularioCliente/components/TerceroMascotas'
import { TerceroInformacionPareja } from '../../../../../shared/formularioCliente/components/TerceroInformacionPareja'
import { InformacionProvider } from '../../../../../shared/providers/InformacionProvider'
import { FormularioProvider, useFormulario } from './FormularioProvider'
import { useTercero } from '../../provider/TerceroProvider'
import { BotonGuardar } from '../../../../../shared/formularioCliente/utils/BotonGuardar'
import { useAuth } from '../../../../Auth'
import { BotonAtras } from '../../../../../shared/formularioCliente/utils/BotonAtras'
import { useNavigate } from 'react-router-dom'
import { EnumRol } from '../../../../../../interfaces/enums/EnumRol'

const TerceroActualizar: FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { formik } = useFormulario();
  const { tercero } = useTercero();

  return (
    <div className='w-100 d-flex justify-content-center'>
      <form
        className="form"
        //onSubmit={formik.handleSubmit}
        noValidate
        style={{ maxWidth: "700px" }}
      >
        <TerceroInformacionPersonal formik={formik} />
        <TerceroDatosContacto formik={formik} />
        <TerceroDireccion formik={formik} />
        <TerceroHijos formik={formik} />
        <TerceroMascotas formik={formik} />
        <TerceroInformacionPareja formik={formik} tercero={tercero} />
        <BotonAtras onClick={() => navigate("/clientes/lista")} />
        {(currentUser?.rolId == EnumRol.Administrador || currentUser?.rolId == EnumRol.Comercial) && <BotonGuardar onClick={() => formik.handleSubmit()} />}
      </form>
    </div>
  )
}

export function TerceroActualizarPagina() {
  return (
    <InformacionProvider>
      <FormularioProvider>
        <TerceroActualizar />
      </FormularioProvider>
    </InformacionProvider>
  )
}
