import { FC, useCallback } from "react";
import clsx from "clsx";
import { FieldInputProps } from "formik";

export interface InputEnteroProps {
    nombre?: string
    placeholder?: string
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
    props: FieldInputProps<string | number | readonly string[] | undefined>
    validProp?: boolean | undefined
    error?: string | undefined
    requerido?: boolean
    title?: string
    disabled?: boolean
    validate?: boolean
    showError?: boolean
    min?: number
    max?: number
    className?: string
}

export const InputEntero: FC<InputEnteroProps> = ({
    props,
    nombre,
    placeholder,
    error,
    validProp,
    requerido,
    title,
    validate = true,
    min,
    max,
    disabled = false,
    className = "",
    setFieldValue
}) => {

    const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
        var number = e.target.value.replace(/\D/g, '');

        e.target.value = (number != "") ? number : "0";
        if(e.target.value != ""){
            setFieldValue(props.name, parseInt(e.target.value));
        }
    }, []);

    const handleInputBlur: React.FocusEventHandler<HTMLInputElement> = useCallback((e) => {
        var number = e.target.value;
        if(number == "") number = "0";

        if (max && parseFloat(number) > max) {
            number = max.toString();
        }else if (min && parseFloat(number) < min) {
            number = min?.toString();
        }
        
        e.target.value = number;
        setFieldValue(props.name, parseInt(e.target.value));
    }, []);

    return (
        <div className={`mb-2 p-1 ${className}`}>
            {nombre && <label className='form-label fs-6 fw-bolder text-dark' title={title}>{nombre} {(requerido) ? <span className="text-danger">*</span> : ""}</label>}
            <input
                disabled={disabled}
                autoComplete="off"
                placeholder={placeholder}
                name={props.name}
                onBlur={handleInputBlur}
                onChange={handleInputChange}
                value={props.value ?? ""}
                className={clsx(
                    'form-control form-control-sm bg-transparent',
                    { 'is-invalid': validate && validProp && error },
                    {
                        'is-valid': validate && validProp && !error,
                    }
                )}
            />
            {validate && validProp && error && (
                <div className='fv-plugins-message-container'>
                    <span className="text-danger" role='alert'>{error}</span>
                </div>
            )}
        </div>
    );
};