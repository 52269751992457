import { FC, useState } from "react";
import { UsuarioServicios } from "../../../../../../servicios/usuarioServicio";
import { ModalCrearUsuario } from "../../../../../shared/modals/modalCrearUsuario";
import { useListView } from "../../../../../shared/providers/ListView/ListView";
import { UsuariosDropDownFilter } from "../../utils/UsuariosDropDownFilter";
import { BotonActualizar } from "../../../../../shared/misc/BotonActualizar";
import { BotonFiltro } from "../../../../../shared/misc/BotonFiltro";



export const ListaBotonesComponent: FC = () => {

    const [show, setShow] = useState(false);
    const { setData, setCargandoDatos } = useListView();

    const obtenerUsuarios = async () => {
        setCargandoDatos(true);
        const usuarios = await UsuarioServicios.ObtenerTodos();
        setData(usuarios);
    }

    return (
        <>
            <div className='d-flex justify-content-end align-items-center' data-kt-user-table-toolbar='base'>
                <div className='m-0'>
                    <BotonActualizar onClick={obtenerUsuarios} />
                </div>
                <div className='m-0'>
                    <BotonFiltro/>
                    <UsuariosDropDownFilter />
                </div>
                <button type='button' className='btn btn-sm btn-light-primary' onClick={() => setShow(true)}>
                    <i className="fs-2 bi bi-person-fill-add"></i>
                    Agregar usuario
                </button>
            </div>
            {show ? <ModalCrearUsuario show={show} showModal={setShow} /> : <></>}
        </>
    )
}