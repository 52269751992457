import { FC } from "react";
import { KTIcon } from "../../../../../_metronic/helpers";
import { IAUsuario } from "../../../../../interfaces/auditorias/IAUsuario";
import { useModalAuditoriaU } from "../providers/ModalAuditoriaU";
import { BotonIcon } from "../../../../shared/misc/BotonOpenModal";

interface BotonTerceroContactoProps {
    tercero: IAUsuario
}
export const BotonOpenModalAuditoriaUsuario: FC<BotonTerceroContactoProps> = ({
    tercero
}) => {

    const { showModal } = useModalAuditoriaU();

    return <BotonIcon icon="bi-eye-fill" tooltip="Ver cambios" onClick={() => showModal(true, tercero.idAuditoria)} />
}