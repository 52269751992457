import { AuditoriaServicio } from "../../../../../servicios/auditoriaServicio";
import { useListView } from "../../../../shared/providers/ListView/ListView";
import { BotonActualizar } from "../../../../shared/misc/BotonActualizar";

export const AuditoriaPropiedadTHeaderToolbar = () => {

    const { setData: setAllData, setCargandoDatos } = useListView();

    const obtenerUsuarios = async () => {
        setCargandoDatos(true);
        const aPropTer = await AuditoriaServicio.AuditoriaPropiedadTercero();
        setAllData(aPropTer);
    }

    return (
        <div className='d-flex justify-content-end align-items-center' data-kt-user-table-toolbar='base'>
            <div className='m-0'>
                <BotonActualizar onClick={obtenerUsuarios}/>
            </div>
        </div>
    )
}