import { FC } from "react";
import { ITercero } from "../../../../../../interfaces/ITercero";
import { IUbicacion } from "../../../../../../interfaces/IUbicacion";
import { useModalContacto } from "../ModalContacto";
import { useNavigate } from "react-router-dom";
import { BotonIcon } from "../../../../../shared/misc/BotonOpenModal";

interface BotonTerceroContactoProps {
    tercero: ITercero,
    municipio: IUbicacion | null
}
export const BotonTerceroContacto: FC<BotonTerceroContactoProps> = ({
    tercero,
    municipio
}) => {

    const { showModal } = useModalContacto();
    const navigate = useNavigate();
    
    return (
        <>   
            <div className="row">
                <div className="col-6">
                    <BotonIcon icon="bi-person-lines-fill" tooltip="Ver información de contacto" onClick={() => showModal(true, tercero, municipio)} />
                </div>
                <div className="col-6">
                    <BotonIcon icon="bi-eye-fill" tooltip="Ver cliente" onClick={() => navigate(`/clientes/profile/perfil/?idCliente=${tercero.id}`)} />
                </div>
            </div>
        </>
    )
}