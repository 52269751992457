import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { toAbsoluteUrl, KTIcon } from "../../../../_metronic/helpers"
import { useMemo, useState } from "react"
import { trunc } from "../../../../utils/trunc"
import { BotonIcono } from "../../../shared/misc/BotonIcono"
import { RecordatorioServicio } from "../../../../servicios/recordatorioServicio"
import * as alertify from "alertifyjs";
import { useAuth } from "../../Auth"
import { EnumRol } from "../../../../interfaces/enums/EnumRol"
import { useTercero } from "./provider/TerceroProvider"

export const TerceroCuentaHeader: React.FC = () => {

  const [get] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation()
  const { tercero, pagos, propiedades } = useTercero();
  const { currentUser } = useAuth();

  // SUMA DE TODOS LOS TOTALES DE LAS PROPIEDADES
  const valorTotalPropiedades = useMemo(() => propiedades.sum(p => p.valorTotal) , [propiedades]);

  // VALOR TOTAL PAGADO
  const totalPagado = useMemo(() => pagos.reduce((p, c) => p + (c.estadoPagoId == 2 ? c.capital + c.abono : 0), 0), [pagos]);

  // TOTAL PROPIEDADES - VALOR PAGADO
  const saldoTotalRestante = useMemo(() => valorTotalPropiedades - totalPagado, [totalPagado, valorTotalPropiedades]);

  // PORCENTAJE PAGADO
  const porcentajePagado = useMemo(() => (valorTotalPropiedades == 0) ? 0 : (totalPagado * 100) / valorTotalPropiedades, [totalPagado, valorTotalPropiedades]);

  const navegar = (to: string) => {
    const propiedadTerceroId = get.get("propiedadTerceroId");
    const _to = (!propiedadTerceroId) ? to : `${to}&propiedadTerceroId=${propiedadTerceroId}`;
    navigate(_to);
  }

  const [notificando, setNotificando] = useState(false);

  const notificar = async () => {
    setNotificando(true);

    const propiedadTerceroId = get.get("propiedadTerceroId");
    if (!propiedadTerceroId) {
      alertify.error("Selecciona una propiedad para notificar");
      setNotificando(false);
      return;
    }

    const propiedad = propiedades.find(p => p.id == propiedadTerceroId);
    if (!propiedad) {
      alertify.error("Propiedad invalida");
      setNotificando(false);
      return;
    }

    alertify.confirm("Confirmacion", `¿Deseas notificar al cliente ${tercero?.nombreCompleto}, la propiedad "${propiedad.descripcion}"?`, async () => {
      var response = await RecordatorioServicio.Recordar(propiedad.id);
      if(response){
        alertify.success("Notificacion enviada con exito");
      }
      setNotificando(false);
    }, () => {
      setNotificando(false);
    });
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <img src={toAbsoluteUrl('/media/dinamicos/profile-account.jpg')} alt='Metronic' />
              <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  {!tercero ? (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  ) : (
                    <a className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {tercero.nombreCompleto}
                    </a>
                  )
                  }
                  {
                    tercero?.estadoId == 1 ? (
                      <a

                        className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'
                      >
                        Activo
                      </a>

                    ) : (
                      <a

                        className='btn btn-sm btn-light-danger fw-bolder ms-2 fs-8 py-1 px-3'
                      >
                        Inactivo
                      </a>
                    )
                  }
                  {
                    /*
                      <a
                        className={`btn btn-sm btn-light-danger fw-bolder ms-2 fs-8 py-1 px-3`}
                        style={{ cursor: "default" }}
                      >
                        Mora
                      </a>
                    */
                  }
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <div
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                    {tercero?.profesion ?? " - "}
                  </div>
                  <div
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTIcon iconName='geolocation' className='fs-4 me-1' />
                    {tercero?.cargo ?? " - "}
                  </div>
                  <div
                    className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                  >
                    <KTIcon iconName='sms' className='fs-4 me-1' />
                    {tercero?.correo}
                  </div>
                </div>
              </div>
              {
                (currentUser?.rolId == EnumRol.Administrador) && (
                  <BotonIcono
                    icon="bi-bell-fill"
                    onClick={notificar}
                    tooltip="Notificar"
                    color="warning"
                    disabled={notificando}
                  />
                )
              }

              {
                /*
                <div className='d-flex my-4'>
                  <div className="form-group col-xs-4 col-md-4">
                    <Estado estadoId={estado} className="fs-3" />
                    <div className="py-1 px-3">
                      <div className="mb-2 form-check form-switch form-switch-red">
                        <input
                          onChange={(e) => setEstado((e) => e == 1 ? 2 : 1)}
                          checked={estado == 1 ? true : false}
                          value="1"
                          className={`form-check-input form-check-input-red w-60px h-30px ${estado == 1 ? 'bg-success' : ''}`}
                          type="checkbox"
                          role='switch'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                */
              }
            </div>

            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                      <div className='fs-2 fw-bolder'>{totalPagado.formatoMoneda2(true)}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Pagado</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTIcon iconName='arrow-down' className='fs-3 text-danger me-2' />
                      <div className='fs-2 fw-bolder'>{saldoTotalRestante.formatoMoneda2(true)}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Saldo</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                      <div className='fs-2 fw-bolder'>{trunc(porcentajePagado, 1)}%</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Porcentaje pagado</div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className='d-flex h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <div
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname.includes('/clientes/profile/perfil') && 'active')
                }
                style={{ cursor: "pointer" }}
                onClick={() => navegar(`/clientes/profile/perfil/?idCliente=${tercero?.id}`)}
              >
                Perfil
              </div>
            </li>
            <li className='nav-item'>
              <div
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname.includes('/clientes/profile/pagos') && 'active')
                }
                style={{ cursor: "pointer" }}
                onClick={() => navegar(`/clientes/profile/pagos/?idCliente=${tercero?.id}`)}
              >
                Pagos
              </div>
            </li>
            <li className='nav-item'>
              <div
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname.includes('/clientes/profile/cartera') && 'active')
                }
                style={{ cursor: "pointer" }}
                onClick={() => navegar(`/clientes/profile/cartera/?idCliente=${tercero?.id}`)}
              >
                Cartera
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}