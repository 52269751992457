import { FC, ReactNode, createContext, useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import * as alertify from "alertifyjs";
import { PropiedadServicio } from "../../../../../../../servicios/propiedadServicio";
import { IPropiedadSelect } from "../../../../../../../interfaces/propiedades/IPropiedadSelect";
import { useFormik } from "formik";
import { propiedadAsignacionSchema } from "../../../../../../../validators/propiedadValidator";
import { useTercero } from "../../../provider/TerceroProvider";
import { IPropiedadGet } from "../../../../../../../interfaces/propiedades/IPropiedadGet";
import { TercerosServicios } from "../../../../../../../servicios/tercerosServicios";
import { DataList } from "../../../../../../../_metronic/partials/formulario/DataList";
import { InputDecimal } from "../../../../../../../_metronic/partials/formulario/InputDecimal";
import { InputMonetario } from "../../../../../../../_metronic/partials/formulario/InputMonetario";
import { usePropiedad } from "./PropiedadProvider";
import { useSearchParams } from "react-router-dom";

interface ModalAgregarPropiedadPros {
    show: boolean
    showModal: (show: boolean) => void
}

const ModalAgregarPropiedadIncial: ModalAgregarPropiedadPros = {
    show: false,
    showModal: () => {}
}

const ModalAgregarPropiedadContext = createContext<ModalAgregarPropiedadPros>(ModalAgregarPropiedadIncial)

interface ModalAgregarPropiedadProviderProps {
    children?: ReactNode,
}

const ModalAgregarPropiedadProvider: FC<ModalAgregarPropiedadProviderProps> = ({
    children
}) => {
    const [getQP, setQP] = useSearchParams();
    const { tercero, setPropiedades: setPropiedadesTercero } = useTercero();
    const { formik: formikPropiedades } = usePropiedad();

    const [show, setShow] = useState(false);

    const [propiedades, setPropiedades] = useState<IPropiedadSelect[]>([]);

    const showModal = (show: boolean) => {
        setShow(show);
        if(!show) formik.resetForm();
    }

    const obtenerPropiedadesNoAsignadas = async () => {
        const propiedades = await PropiedadServicio.ObtenerNoAsignados();
        setPropiedades(propiedades);
    }
    useEffect(() => {
        obtenerPropiedadesNoAsignadas();
    }, []);

    const formik = useFormik<IPropiedadGet>({
        initialValues: {
            areaPropiedad: 0,
            id: "",
            interesAnual: 0,
            propiedadId: "",
            valorM2: 0,
            valorTotal: 0
        },
        enableReinitialize: true,
        validationSchema: propiedadAsignacionSchema,
        onSubmit: async (values) => {
            try {
                if(!tercero?.id) return;
                const prop = propiedades.find(p => p.id == values.propiedadId);
                if(!prop) return;

                await PropiedadServicio.Asignar({
                    propiedadId: values.propiedadId,
                    terceroId: tercero.id,
                    descripcion: prop.descripcion,
                    areaPropiedad: values.areaPropiedad,
                    interesAnual: values.interesAnual,
                    valorTotal: values.valorTotal
                });
                
                await obtenerPropiedadesNoAsignadas();

                const propiedadesTercero = await TercerosServicios.ObtenerPropiedades(tercero.id);
                
                const propiedad = propiedadesTercero.find(p => p.propiedadId == values.propiedadId);
                if(propiedad){
                    setPropiedadesTercero([...propiedadesTercero]);
    
                    getQP.set("propiedadTerceroId", propiedad.id);
                    setQP(getQP);
    
                    formikPropiedades.setFieldValue("id", values.id);
    
                    showModal(false);
                    alertify.success("Propiedad asignada.");

                }
                
            } catch (err) {
                alertify.error("No se pudo guardar la propiedad.");
            }
        }
    });

    useEffect(() => {
        if(!formik.values.propiedadId || formik.values.propiedadId == "") return;
        const obtenerPropiedad = async () => {
            const propiedad = await PropiedadServicio.ObtenerPorId(formik.values.propiedadId);
            if(propiedad){
                formik.setValues({...propiedad});
            }
        }
        obtenerPropiedad();
    }, [formik.values.propiedadId]);

    return (
        <ModalAgregarPropiedadContext.Provider value={{
            show,
            showModal
        }}>
            {children}
            {
                show &&
                <Modal show={show} className="fade modal">
                    <form
                        className="form"
                        onSubmit={formik.handleSubmit}
                        noValidate
                    >
                        <Modal.Header className="p-4">
                            <h2 className="modal-title">Propiedad</h2>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                aria-label="Close"
                                onClick={() => showModal(false)}
                            >
                                <i className="bi bi-x-lg"></i>
                            </div>
                        </Modal.Header>
                        <Modal.Body className="bg-gray-300 px-6 py-4">
                            {/* begin::modal */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="row p-4 row-cols-1 bg-light border rounded-3">
                                        <DataList
                                            options={propiedades.map(m => ({nombre: m.descripcion, opcion: m.descripcion, valor: m.id}))}
                                            requerido
                                            nombre="Propiedad"
                                            placeholder="Propiedad"
                                            setFieldValue={formik.setFieldValue}
                                            props={formik.getFieldProps("propiedadId")}
                                            error={formik.errors.propiedadId}
                                            validProp={formik.touched.propiedadId}
                                        />
                                        <InputDecimal
                                            requerido
                                            nombre="Área Propiedad"
                                            setFieldValue={formik.setFieldValue}
                                            props={formik.getFieldProps("areaPropiedad")}
                                            error={formik.errors.areaPropiedad}
                                            validProp={formik.touched.areaPropiedad}
                                        />
                                        <InputMonetario
                                            requerido
                                            nombre="Valor Total"
                                            setFieldValue={formik.setFieldValue}
                                            props={formik.getFieldProps("valorTotal")}
                                            error={formik.errors.valorTotal}
                                            validProp={formik.touched.valorTotal}
                                        />
                                        <InputDecimal
                                            requerido
                                            nombre="Interés anual"
                                            setFieldValue={formik.setFieldValue}
                                            props={formik.getFieldProps("interesAnual")}
                                            error={formik.errors.interesAnual}
                                            validProp={formik.touched.interesAnual}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* end::modal */}
                        </Modal.Body>
                        <Modal.Footer className="p-4 d-flex">
                            <button
                                type="button"
                                className="btn btn-sm btn-light"
                                onClick={() => showModal(false)}
                            >
                                Cancelar
                            </button>
                            <button type="submit" className="btn btn-sm btn-primary">
                                Asignar
                            </button>
                        </Modal.Footer>
                    </form>
                </Modal >
            }
        </ModalAgregarPropiedadContext.Provider>
    )
}

const useModalAgregarPropiedad = () => useContext(ModalAgregarPropiedadContext);

export {
    ModalAgregarPropiedadProvider,
    useModalAgregarPropiedad
}
