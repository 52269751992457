import { FC } from "react";
import { useModalAuditoriaPropiedadT } from "../providers/ModalAuditoriaPropiedadT";
import { BotonIcon } from "../../../../shared/misc/BotonOpenModal";
import { IAPropiedadTercero } from "../../../../../interfaces/auditorias/IAPropiedadTercero";

interface BotonTerceroContactoProps {
    tercero: IAPropiedadTercero
}
export const BotonOpenModalAPT: FC<BotonTerceroContactoProps> = ({
    tercero
}) => {

    const { showModal } = useModalAuditoriaPropiedadT();
    
    return <BotonIcon icon="bi-eye-fill" tooltip="Ver cambios" onClick={() => showModal(true, tercero.idAuditoria)}/>
}