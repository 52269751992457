import { FC } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { useThemeMode } from "../../../../../_metronic/partials";
import { useListView } from "../../../../shared/providers/ListView/ListView";
import { NoDataComponent } from "../../../../shared/ListView/NoDataComponent";
import { IPropiedadCaracteristicas } from "../../../../../interfaces/propiedades/IPropiedadCaracteristicas";
import { BotonEditarPropiedad } from "../../utils/BotonEditarPropiedad";
import { trunc } from "../../../../../utils/trunc";
import { useAuth } from "../../../Auth";
import { Asignado } from "../../../../shared/misc/BotonesAttributos";
import { EnumRol } from "../../../../../interfaces/enums/EnumRol";


const TableColumnsAdmin: TableColumn<IPropiedadCaracteristicas>[] = [
    {
        name: 'Nombre',
        selector: row => row.descripcion,
        width: "110px"
    }, {
        name: 'Área m2',
        selector: row => row.areaPropiedad,
        sortable: true,
        width: "100px"
    }, {
        name: 'Valor total',
        selector: row => row.valorTotal ? row.valorTotal.formatoMoneda2(true) : ""
    }, {
        name: 'Interés anual',
        selector: row => row.interesAnual ? `${trunc(row.interesAnual, 2)} %` : "",
        width: "150px"
    }, {
        name: 'Cliente',
        cell: (row) => row.tercero
    }, {
        name: 'Disponible',
        cell: (row) => <Asignado asignado={row.tercero == null}/>,
        center: true
    }, {
        name: 'Acciones',
        cell: (row) => <BotonEditarPropiedad propiedad={row} />,
        width: "140px",
        center: true
    }
]

const TableColumnsEmpleado: TableColumn<IPropiedadCaracteristicas>[] = [
    {
        name: 'Nombre',
        selector: row => row.descripcion,
        width: "180px"
    }, {
        name: 'Área m2',
        selector: row => row.areaPropiedad
    }, {
        name: 'Valor m2',
        selector: row => row.valorM2 ? row.valorM2.formatoMoneda2(true) : ""
    }, {
        name: 'Interés anual',
        selector: row => row.interesAnual ? `${trunc(row.interesAnual, 2)} %` : ""
    }
]


export const ListPropiedades: FC = () => {

    const { currentUser } = useAuth();
    const { filterData, cargandoDatos } = useListView();
    const { mode } = useThemeMode();
    
    return (
        <div className="px-5">
            <DataTable
                columns={(currentUser?.rolId === EnumRol.Administrador || currentUser?.rolId === EnumRol.SuperAdmin) ? TableColumnsAdmin : TableColumnsEmpleado}
                data={filterData}
                progressPending={cargandoDatos}
                pagination
                persistTableHead
                noDataComponent={<NoDataComponent />}
                theme={mode == "dark" ? "dark" : "light"}
                paginationComponentOptions={{
                    rowsPerPageText: "Filas por pagina:"
                }}
            />
        </div>
    )
}
