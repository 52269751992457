import { RefObject, useEffect, useMemo, useRef, useState } from "react"

export type useEscribiendoType = (intervalo?: number) => {
    inputRef: RefObject<HTMLInputElement>,
    escribiendo: boolean
}

export const useEscribiendo: useEscribiendoType = (intervalo = 2000) => {

    const [first, setFirts] = useState(true);

    const inputRef = useRef<HTMLInputElement>(null);

    const [tiempo, setTiempo] = useState(intervalo);

    const escribiendo = useMemo(() => {
        if(tiempo <= 0){
            if(first){
                setFirts(false);
                return true;
            }
            return false
        } else return true;
    }, [first, tiempo]);

    useEffect(() => {
        var interval = setInterval(() => {
            setTiempo(t =>  t-100);
        }, 100);

        return () => {
            clearInterval(interval);
        }
    }, []);

    useEffect(() => {
        if(!inputRef.current) return;

        const onChange = (e: Event) => {
            setTiempo(2000);
        }

        inputRef.current.addEventListener("change", onChange);

        return () => {
            if(!inputRef.current) return;
            inputRef.current.removeEventListener("change", onChange);
        }
    }, [inputRef]);

    return {
        inputRef,
        escribiendo
    }
}
