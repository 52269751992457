import * as Yup from 'yup'

export const crearPagoSchema = Yup.object().shape({
    referenciaPago: Yup.string()
        .required('Campo requerido.'),
    valor: Yup.number()
        .required('Campo requerido.'),
    tipoPagoId: Yup.number()
        .required('Campo requerido.'),
    propiedadTerceroId: Yup.string()
        .required('Campo requerido.')
        .typeError('Formato incorrecto.')
});

