import { FC } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { useListView } from "../../../../shared/providers/ListView/ListView";
import { useThemeMode } from "../../../../../_metronic/partials";
import { NoDataComponent } from "../../../../shared/ListView/NoDataComponent";
import { IAPropiedadCaracteristica } from "../../../../../interfaces/auditorias/IAPropiedadCaracteristica";
import { BotonOpenModalAPC } from "../utils/BotonOpenModalAPC";
import { Accion } from "../../../../shared/misc/BotonesAttributos";
import date from "date-and-time"

const tableColumns: TableColumn<IAPropiedadCaracteristica>[] = [
  {
    name: 'Usuario',
    selector: row => row.aNombreUsuario,
    wrap: true
  }, {
    name: 'Modificado',
    selector: row => row.descripcionPropiedad,
  }, {
    name: 'Fecha',
    selector: row => date.format(row.aFecha, 'DD-MM-YYYY HH:mm'),
    sortFunction: (a, b) => a.aFecha.getTime() - b.aFecha.getTime(),
    sortable: true
  }, {
    name: 'IP',
    selector: row => row.aIp
  }, {
    name: 'Accion',
    selector: row => row.aTipoAccion,
    cell: (row) => <Accion accion={{ nombre: row.aTipoAccion, valor: row.aTipoAccion }} />,
    center: true,
    width: "100px"
  }, {
    name: 'Acciones',
    cell: (row) => (
      <BotonOpenModalAPC tercero={row} />
    ),
    width: "100px",
    center: true
  }
]


export const AuditoriaPropiedadCLista: FC = () => {

  const { filterData, cargandoDatos } = useListView();
  const { mode } = useThemeMode();

  return (
    <div className="px-5">
      <DataTable
        columns={tableColumns}
        data={filterData}
        progressPending={cargandoDatos}
        pagination
        persistTableHead
        theme={mode == "dark" ? "dark" : "light"}
        noDataComponent={<NoDataComponent />}
        keyField="idAuditoria"
        paginationComponentOptions={{
          rowsPerPageText: "Filas por pagina:"
        }}
      />
    </div>
  )
}

