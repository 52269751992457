import { IPago } from "../interfaces/pago/IPago";
import { IPagoPost } from "../interfaces/pago/IPagoPost";
import { IPropiedadCaracteristicas } from "../interfaces/propiedades/IPropiedadCaracteristicas";
import { axios } from "./axiosInstance";

const url = `${ window.origin.includes("localhost") ? process.env.REACT_APP_API_BASE_URL_PRUEBAS : process.env.REACT_APP_API_BASE_URL}/api`;

export class PagoServicio {

    public static CrearPago = (pago: IPagoPost) : Promise<void> => {
        return new Promise(async(res, rej)=>{
            try{
                await axios.post<IPropiedadCaracteristicas[]>('/pago', pago)
                res();
            }catch(err){
                rej(err);
            }
        });
    }

    public static obtenerPagos = (TerceroId: string) : Promise<IPago[]> => {
        return new Promise(async(res, rej)=>{
            try{
                const pagos = await axios.get<IPago[]>(`/pago/lista?TerceroId=${TerceroId}`);
                pagos.forEach((c, i, a) => {
                    a[i].fecha = new Date(c.fecha);
                })
                pagos.sort((a, b) => b.fecha.getTime() - a.fecha.getTime());
                res(pagos);
            }catch(err){
                res([]);
            }
        });
    }

    public static obtenerComprobante = (pagoId: string) : Promise<string | null> => {
        return new Promise(async(res, rej)=>{
            try{
                const comprobante = await axios.get<string>(`/pago/comprobante?PagoId=${pagoId}`);
                res(comprobante);
            }catch(err){
                res(null);
            }
        });
    }

}