import { FC } from "react"

interface AsignadoProps {
    asignado: boolean
    className?: string
}
export const Asignado: FC<AsignadoProps> = ({
    asignado,
    className
}) => {
    return(
        asignado ? (
            <div data-tooltip-id="tooltip" data-tooltip-content={"Disponible"} style={{width: "25px", height: "25px"}} className={`rounded-circle bg-success fw-bolder ms-2 py-1 px-3 ${className}`}/>
        ) : (
            <div data-tooltip-id="tooltip" data-tooltip-content={"No Disponible"}  style={{width: "25px", height: "25px"}} className={`rounded-circle bg-danger fw-bolder ms-2 py-1 px-3 ${className}`}/>
        )
    )
}