import { FC, useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { InputDecimalProps } from "./InputDecimal";

export interface InputMonetarioProps extends InputDecimalProps {
    defaultValue?: string | number,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
    formato?: number
}

export const InputMonetario: FC<InputMonetarioProps> = ({
    props,
    nombre,
    placeholder,
    error,
    validProp,
    requerido,
    title,
    validate = true,
    setFieldValue,
    disabled = false,
    className = "",
    max,
    min
}) => {

    // valor formateado a tipo moneda
    const valorFormateado = useMemo(() => {
        if (props.value) {
            var number = parseFloat(props.value.toString());
            return (isNaN(number) ? 0 : number).formatoMoneda2(true);
        }else return "$ 0"
    }, [props.value]);

    const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
        var number = e.target.value.replace(/\D/g, '');
        if (number == "") number = "0";
        e.target.value = number;
        setFieldValue(props.name, parseFloat(number));
    }, []);
    
    const onBlur: React.FocusEventHandler<HTMLInputElement> = useCallback((e) => {
        var number = e.target.value.replace(/\D/g, '');
        if(number == "") number = "0";

        if (max && parseFloat(number) > max) {
            number = max.toString();
        }else if (min && parseFloat(number) < min) {
            number = min?.toString();
        }

        e.target.value = number;
        setFieldValue(props.name, parseFloat(number));
    }, []);

    return (
        <div className={`fv-row mb-2 p-1 ${className}`}>
            {nombre && <label className='form-label fs-6 fw-bolder text-dark' title={title}>{nombre} {(requerido) ? <span className="text-danger">*</span> : ""}</label>}
            <input
                disabled={disabled}
                type="text"
                onChange={onChange}
                onBlur={onBlur}
                name={props.name}
                value={valorFormateado}
                placeholder={placeholder}
                className={clsx(
                    'form-control form-control-sm bg-transparent',
                    { 'is-invalid': validate && validProp && error },
                    {
                        'is-valid': validate && validProp && !error,
                    }
                )}
            />
            {validate && validProp && error && (
                <div className='fv-plugins-message-container'>
                    <span className="text-danger" role='alert'>{error}</span>
                </div>
            )}
        </div>
    );
};


export interface InputMonetario2Props {
    nombre?: string
    placeholder?: string
    requerido?: boolean
    title?: string
    disabled?: boolean
    min?: number
    max?: number
    onChange: (value: number) => void
    className?: string
    style?: React.CSSProperties
    defaultValue?: number
}

export const InputMonetario2: FC<InputMonetario2Props> = ({
    nombre,
    placeholder,
    requerido,
    title,
    disabled, 
    min,
    max,
    onChange: onChangeEvent,
    style,
    className,
    defaultValue
}) => {

    const [set, setSet] = useState(false);
    const [value, setValue] = useState(1); 

    useEffect(() => {
        if(defaultValue && !set){
            setValue(defaultValue);
            setSet(true);
        }
    }, [defaultValue, set]);

    // valor formateado a tipo moneda
    const valorFormateado = useMemo(() => (value ?? 0).formatoMoneda2(true), [value]);

    const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
        var number = e.target.value.replace(/\D/g, '');
        if (number == "") number = "0";
        onChangeEvent(parseFloat(number));
        setValue(parseFloat(number));
    }, []);
    
    const onBlur: React.FocusEventHandler<HTMLInputElement> = useCallback((e) => {
        var number = e.target.value.replace(/\D/g, '');
        if(number == "") number = "0";

        if (max && parseFloat(number) > max) {
            number = max.toString();
        }else if (min && parseFloat(number) < min) {
            number = min?.toString();
        }
        onChangeEvent(parseFloat(number));
        setValue(parseFloat(number));
    }, []);

    return (
        <div className={`${className}`}>
            {nombre && <label className='form-label fs-6 fw-bolder text-dark' title={title}>{nombre} {(requerido) ? <span className="text-danger">*</span> : ""}</label>}
            <input
                style={style}
                disabled={disabled}
                type="text"
                onChange={onChange}
                onBlur={onBlur}
                value={valorFormateado}
                placeholder={placeholder}
                className="form-control form-control-sm"
            />
        </div>
    );
};