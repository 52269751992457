import { FC, MouseEventHandler } from "react";

interface BotonTerceroContactoProps {
    icon?: string
    tooltip?: string
    onClick: MouseEventHandler<HTMLButtonElement>,
    flip?: boolean
    disabled?: boolean
}

export const BotonIcon: FC<BotonTerceroContactoProps> = ({
    icon,
    onClick,
    tooltip = "",
    flip = false,
    disabled = false
}) => {
    return (
        <>
            <div className="row">
                <div className="col-6">
                    <button
                        disabled={disabled}
                        onClick={onClick}
                        type='button'
                        className='btn btn-secondary btn-sm'
                        data-tooltip-id="tooltip" data-tooltip-content={tooltip}
                    >
                        {
                            (flip) && (
                                <div className="spinner-border" role="status" style={{height: "15px", width: "15px"}}>
                                    <span className="sr-only"></span>
                                </div>
                            ) 
                        }{
                            (icon) && (
                                <i className={`fs-3 bi ${icon}`}></i>
                            )
                        }
                    </button>
                </div>
            </div>
        </>
    )
}