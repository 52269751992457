import { FC, useEffect, useState } from "react";
import { ListViewProvider } from "../../../shared/providers/ListView/ListView";
import { KTCard } from "../../../../_metronic/helpers";
import { InformeCuentaHeader } from "./components/InformeCuentaHeader";
import { InformeCuentaLista } from "./components/InformeCuentaLista";
import { InformeServicio } from "../../../../servicios/informeServicio";
import { IInformeCuenta } from "../../../../interfaces/informes/IInformeCuenta";

export const InformeCuenta: FC = () => {

    const [initalData, setInitialData] = useState<IInformeCuenta[]>([]);

    const obtenerInformeCuentaTercero = async () => {
        const informe = await InformeServicio.InformeCuenta(null, 0);
        setInitialData(informe);
    }

    useEffect(() => {
        obtenerInformeCuentaTercero();
    }, [])

    return (
        <KTCard>
            <ListViewProvider initialData={initalData} >
                <InformeCuentaHeader />
                <InformeCuentaLista />
            </ListViewProvider>
        </KTCard>
    )
}
