import { FC } from "react";
import { TerceroProvider } from "./provider/TerceroProvider";
import { TerceroCuentaRouter } from "./TerceroCuentaRouter";

export const TerceroCuenta: FC = () => {
    return (
        <TerceroProvider>
            <TerceroCuentaRouter />
        </TerceroProvider>
    )
}

