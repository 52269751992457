import { createRoot } from 'react-dom/client'
import {Chart, registerables} from 'chart.js'
import "./_metronic/assets/style.css"
import 'react-tooltip/dist/react-tooltip.css'
import "./utils/extenciones/dateExtencion"
import "./utils/extenciones/stringExtencion"
import "./utils/extenciones/arrayExtencion"
import "./utils/extenciones/numberExtencion"
import "./utils/Files"

import 'alertifyjs/build/css/themes/bootstrap.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import 'alertifyjs/build/css/alertify.css';


// Apps
import './_metronic/assets/fonticon/fonticon.css'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import { AppRoutes } from './app/routing/AppRoutes'
import { AuthProvider } from './app/modules/Auth'
Chart.register(...registerables)

const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <AuthProvider>
      <AppRoutes />
    </AuthProvider>
  )
}
