export { }

export interface Archivo {
    nombre: string
    base64: string
    tipo: string
    extension: string
}

declare global {
    interface File {
        toArchivo(): Promise<Archivo>
    }
}

File.prototype.toArchivo = function (this) {
    return new Promise((res, rej) => {
        const nombre = this.name;
        const mimeType = this.type;
        const extension = '.' + nombre.split('.').pop();
        if (!extension) return rej("No se pudo leer el archivo.");

        const reader = new FileReader();
        reader.onload = (e) => {
            const base64String = e.target?.result;
            if (typeof base64String === 'string') {
                // Eliminar el prefijo "data:[<tipo-de-archivo>];base64,"
                const base64Data = base64String.split(',')[1];
                res({
                    base64: base64Data,
                    extension,
                    nombre,
                    tipo: mimeType
                });
            } else {
                rej("No se pudo leer el archivo.");
            }
        };
        reader.onerror = (e) => {
            rej("No se pudo leer el archivo.");
        };
        reader.readAsDataURL(this);
    });
};

export const b64toBlob = (b64Data: string, contentType = 'application/octet-strea', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays: Uint8Array[] = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}