import { FC, useMemo, useState } from "react";
import { useListView } from "../../../../shared/providers/ListView/ListView";
import { PropiedadServicio } from "../../../../../servicios/propiedadServicio";
import { BotonActualizar } from "../../../../shared/misc/BotonActualizar";
import { Select } from "../../../../../_metronic/partials/formulario/Select";
import { Informacion } from "../../../../shared/datosFormularios/Informacion";
import { IPropiedadCaracteristicas } from "../../../../../interfaces/propiedades/IPropiedadCaracteristicas";
import { IInformePropiedad } from "../../../../../utils/generarExcel/Propiedades/InformePropiedadesTypes";
import { GenerarInformePropiedades } from "../../../../../utils/generarExcel/Propiedades/InformePropiedades";
import FileSaver from "file-saver";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";

const lotes: { [key: string]: [number, number] } = {
    "Tipo 1": [60, 69],
    "Tipo 2": [70, 79],
    "Tipo 3": [80, 89],
    "Tipo 4": [90, 109],
    "Tipo 5": [110, 119],
    "Tipo 6": [120, 129],
    "Tipo 7": [130, 139]
}

const getTipo = (area: number) => {
    for (const [key, [min, max]] of Object.entries(lotes)) {
        if (area >= min && area < max) return key;
    }
    return null;
}

const disponibleTipo: Informacion[] = [
    {
        nombre: "",
        valor: 0
    }, {
        nombre: "Disponible",
        valor: 1
    }, {
        nombre: "No disponible",
        valor: 2
    }
]

export const ListaBotonesComponent: FC = () => {

    const { data, addFnFilter, removeFnFilters } = useListView();

    const [disponible, setDisponible] = useState(0);

    const updateFilter = (disponible: number) => {
        addFnFilter("tercero", (tercero: string | null) => {
            if (disponible === 0) {
                // Si disponible es 0, acepta todos
                return true;
            } else {
                // Si tercero es null, acepta disponibles; de lo contrario, acepta no disponibles
                return tercero === null ? disponible === 1 : disponible === 2;
            }
        });
    }

    const descargarExcel = async () => {
        const informePropiedades: IInformePropiedad[] = (data as unknown as IPropiedadCaracteristicas[]).map(prop => ({
            area: prop.areaPropiedad,
            disponible: prop.tercero ? 1 : 0,
            interesAnual: prop.interesAnual,
            nombre: prop.descripcion,
            propietario: prop.tercero ?? "",
            valorTotal: prop.valorTotal
        }));

        const buffer = await GenerarInformePropiedades(informePropiedades);
        if (buffer) {
            FileSaver.saveAs(new Blob([buffer]), "Informe Propiedades.xlsx");
        }
    }

/*
    const tiposLotes: Informacion[] = useMemo(() => {
        let lotes = {
            "Tipo 1": 0,
            "Tipo 2": 0,
            "Tipo 3": 0,
            "Tipo 4": 0,
            "Tipo 5": 0,
            "Tipo 6": 0,
            "Tipo 7": 0
        }

        data.forEach((data: IPropiedadCaracteristicas) => {
            let tipo = getTipo(data.areaPropiedad);
            if (tipo) lotes[tipo] += 1;
        });

        let tiposLotes: Informacion[] = [{
            nombre: "",
            valor: ""
        }];

        Object.entries(lotes).forEach(([key, value]) => {
            tiposLotes.push({
                nombre: `${key} - (${value})`,
                valor: key,
            })
        });

        return tiposLotes;
    }, [data]);
*/

    return (
        <div className='d-flex justify-content-end align-items-center' data-kt-user-table-toolbar='base'>
            { /*<div className="m-0"><BotonActualizar onClick={obtenerUsuarios} /></div>*/}
            <div className='m-0'>
                <button
                    type="button"
                    style={{ width: "50px", height: "50px" }}
                    className="btn btn-light-info d-flex justify-content-center align-items-center mx-6"
                    onClick={descargarExcel}
                    data-tooltip-id="tooltip" data-tooltip-content="Descargar excel"
                >
                    <img style={{ maxWidth: "40px" }} src={toAbsoluteUrl("/media/svg/files/excel.svg")} alt='' />
                </button>
            </div>
            <div className='m-0'>
                <span className="fw-bold">Tipo Lote</span>
                <Select
                    options={disponibleTipo}
                    props={{
                        name: "",
                        onBlur: () => { },
                        onChange: (e) => {
                            const tipo = parseInt(e.target.value);
                            if (tipo === 0) {
                                removeFnFilters("tercero");
                            } else {
                                updateFilter(tipo);
                            }
                            setDisponible(tipo);
                        },
                        value: disponible
                    }}
                    tooltip="Agregar nueva propiedad"
                />
            </div>
        </div>
    )
}