import { FC } from "react";
import { FormikProps } from "formik";
import { ITercero } from "../../../../interfaces/ITercero";
import { useInformacion } from "../../providers/InformacionProvider";
import { useTercero } from "../../../modules/Terceros/perfil/provider/TerceroProvider";
import { DataList } from "../../../../_metronic/partials/formulario/DataList";
import { FormularioCampo } from "../../../../_metronic/partials/formulario/FormularioCampo";
import { Input } from "../../../../_metronic/partials/formulario/Input";
import { useAuth } from "../../../modules/Auth";
import { EnumRol } from "../../../../interfaces/enums/EnumRol";

interface TerceroDireccionProps {
    formik: FormikProps<ITercero>
}

export const TerceroDireccion: FC<TerceroDireccionProps> = ({
    formik
}) => {

    const { municipios } = useInformacion();
    const { tercero } = useTercero();
    const { currentUser } = useAuth();

    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer d-flex justify-content-center align-items-center'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_crear_tercero_direccion'
                aria-expanded='true'
                aria-controls='kt_crear_tercero_direccion'
                style={{minHeight: "50px"}}
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Direccion</h3>
                </div>
            </div>
            <div id='kt_crear_tercero_direccion' className='collapse show'>
                <div className='card-body border-top p-9'>
                    <div className="row row-cols-2">
                        
                        <FormularioCampo nombre="Municipio" requerido>
                            <div className="row p-0">
                                <DataList
                                    disabled={currentUser?.rolId != EnumRol.Administrador}
                                    placeholder="Municipio *"
                                    defaultValue={tercero ? municipios.find(m=>m.id==tercero.municipioId)?.nombreConjunto : ""}
                                    options={municipios.map((m) => ({ 
                                        nombre: m.nombreConjunto, valor: m.id, opcion: m.nombreConjunto
                                    }))}
                                    setFieldValue={formik.setFieldValue}
                                    props={formik.getFieldProps('municipioId')}
                                    validProp={formik.touched.municipioId}
                                    error={formik.errors.municipioId}
                                />
                            </div>
                        </FormularioCampo>

                        <FormularioCampo nombre="Barrio">
                            <div className="row p-0">
                                <Input
                                    disabled={currentUser?.rolId != EnumRol.Administrador}
                                    placeholder={"Barrio"}
                                    props={formik.getFieldProps('barrio')}
                                    validProp={formik.touched.barrio}
                                    error={formik.errors.barrio}
                                />
                            </div>
                        </FormularioCampo>

                        <FormularioCampo nombre="Dirección" requerido>
                            <div className="row p-0">
                                <Input
                                    disabled={currentUser?.rolId != EnumRol.Administrador}
                                    placeholder={"Dirección *"}
                                    props={formik.getFieldProps('direccion')}
                                    validProp={formik.touched.direccion}
                                    error={formik.errors.direccion}
                                />
                            </div>
                        </FormularioCampo>

                    </div>
                </div>
            </div>
        </div>

    )
}

