import { FC } from "react"
import { TerceroCuenta } from "../modules/Terceros/perfil/TerceroCuenta"


export const TerceroCuentaWrapper: FC = () => {
    return(
        <>
            <TerceroCuenta/>
        </>
    )
}