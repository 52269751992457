import { FC, useState, useEffect } from "react";
import { FormikProps } from "formik";
import { ITercero } from "../../../../interfaces/ITercero";
import { datosPersonalesIniciales } from "../../datosFormularios/DatosInicialesFormularios";
import { documentos, roles } from "../../datosFormularios/Informacion";
import { useInformacion } from "../../providers/InformacionProvider";
import { FormularioCampo } from "../../../../_metronic/partials/formulario/FormularioCampo";
import { Select } from "../../../../_metronic/partials/formulario/Select";
import { Input } from "../../../../_metronic/partials/formulario/Input";
import { InputEntero } from "../../../../_metronic/partials/formulario/InputEntero";
import { DataList } from "../../../../_metronic/partials/formulario/DataList";
import { InputNumerico } from "../../../../_metronic/partials/formulario/InputNumerico";
import { EnumRol } from "../../../../interfaces/enums/EnumRol";
import { useAuth } from "../../../modules/Auth";

interface TerceroInformacionParejaProps {
    formik: FormikProps<ITercero>
    tercero: ITercero | null | undefined
}

export const TerceroInformacionPareja: FC<TerceroInformacionParejaProps> = ({
    formik,
    tercero
}) => {

    const { currentUser } = useAuth();
    const [checked, setChecked] = useState(false);
    const { municipios, municipiosObject } = useInformacion();

    useEffect(() => {
        if (tercero?.pareja) setChecked(true);
    }, [tercero]);

    useEffect(() => {
        if (checked) formik.setFieldValue("pareja", tercero?.pareja ?? datosPersonalesIniciales);
        else formik.setFieldValue("pareja", null);
    }, [checked]);

    return (
        (tercero || tercero === undefined) ?
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer d-flex justify-content-center align-items-center'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_crear_tercero_pareja_info_basica'
                    aria-expanded='true'
                    aria-controls='kt_crear_tercero_pareja_info_basica'
                    style={{ minHeight: "50px" }}
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Datos personales del cónyuge</h3>
                    </div>
                </div>
                <div id='kt_crear_tercero_pareja_info_basica' className='collapse show'>
                    <div className='card-body border-top py-2 px-0'>
                        <div className="row">
                            <div className="col-12 pb-2 px-12">
                                <div className="form-check form-switch form-check-custom form-check-solid">
                                    <input
                                        disabled={!(currentUser?.rolId == EnumRol.Administrador || currentUser?.rolId == EnumRol.Comercial)}
                                        onClick={() => { setChecked(c => !c) }}
                                        className="form-check-input"
                                        defaultChecked={tercero?.pareja != null}
                                        type="checkbox"
                                        id="flexSwitchDefault"
                                        role='button'
                                        data-bs-toggle='collapse'
                                        data-bs-target='#kt_crear_tercero_pareja_info_basica_body'
                                        aria-expanded='true'
                                        aria-controls='kt_crear_tercero_pareja_info_basica_body'
                                    />
                                    <label className="form-check-label" htmlFor="flexSwitchDefault">
                                        Cónyuge
                                    </label>
                                </div>
                            </div>
                            <div id='kt_crear_tercero_pareja_info_basica_body' className={`collapse ${(tercero?.pareja) ? 'show' : ''}`}>
                                <div className='card-body border-top'>
                                    <div className="row row-cols-2">
                                        <FormularioCampo nombre="Documento" requerido>
                                            <div className="row row-cols-2 p-0">
                                                <div className="col-3 p-0">
                                                    <Select
                                                        disabled={!(currentUser?.rolId == EnumRol.Administrador || currentUser?.rolId == EnumRol.Comercial)}
                                                        options={documentos}
                                                        placeholder="Documento *"
                                                        props={formik.getFieldProps('pareja.tipoDocumentoId')}
                                                        validProp={formik.touched.pareja?.tipoDocumentoId}
                                                        error={formik.errors.pareja?.tipoDocumentoId}
                                                    />
                                                </div>
                                                <div className="col-9 p-0">
                                                    <Input
                                                        disabled={!(currentUser?.rolId == EnumRol.Administrador || currentUser?.rolId == EnumRol.Comercial)}
                                                        placeholder={"Identificacion *"}
                                                        props={formik.getFieldProps('pareja.identificacion')}
                                                        validProp={formik.touched.pareja?.identificacion}
                                                        error={formik.errors.pareja?.identificacion}
                                                    />
                                                </div>
                                            </div>
                                        </FormularioCampo>

                                        <FormularioCampo nombre="Nombres" requerido>
                                            <div className="row row-cols-2 p-0">
                                                <div className="col p-0">
                                                    <Input
                                                        disabled={!(currentUser?.rolId == EnumRol.Administrador || currentUser?.rolId == EnumRol.Comercial)}
                                                        autoCapitalLetter
                                                        deleteSpaces
                                                        placeholder={"Primer nombre *"}
                                                        props={formik.getFieldProps('pareja.primerNombre')}
                                                        validProp={formik.touched.pareja?.primerNombre}
                                                        error={formik.errors.pareja?.primerNombre}
                                                    />
                                                </div>
                                                <div className="col p-0">
                                                    <Input
                                                        disabled={!(currentUser?.rolId == EnumRol.Administrador || currentUser?.rolId == EnumRol.Comercial)}
                                                        autoCapitalLetter
                                                        placeholder={"Otros nombres"}
                                                        props={formik.getFieldProps('pareja.otrosNombres')}
                                                        validProp={formik.touched.pareja?.otrosNombres}
                                                        error={formik.errors.pareja?.otrosNombres}
                                                    />
                                                </div>
                                            </div>
                                        </FormularioCampo>

                                        <FormularioCampo nombre="Apellidos" requerido>
                                            <div className="row row-cols-2 p-0">
                                                <div className="col p-0">
                                                    <Input
                                                        disabled={!(currentUser?.rolId == EnumRol.Administrador || currentUser?.rolId == EnumRol.Comercial)}
                                                        autoCapitalLetter
                                                        deleteSpaces
                                                        placeholder={"Primer apellido *"}
                                                        props={formik.getFieldProps('pareja.primerApellido')}
                                                        validProp={formik.touched.pareja?.primerApellido}
                                                        error={formik.errors.pareja?.primerApellido}
                                                    />
                                                </div>
                                                <div className="col p-0">
                                                    <Input
                                                        disabled={!(currentUser?.rolId == EnumRol.Administrador || currentUser?.rolId == EnumRol.Comercial)}
                                                        autoCapitalLetter
                                                        deleteSpaces
                                                        placeholder={"Segundo apellido"}
                                                        props={formik.getFieldProps('pareja.segundoApellido')}
                                                        validProp={formik.touched.pareja?.segundoApellido}
                                                        error={formik.errors.pareja?.segundoApellido}
                                                    />
                                                </div>
                                            </div>
                                        </FormularioCampo>

                                        <FormularioCampo nombre="Profesión">
                                            <div className="row row-cols-2 p-0">
                                                <div className="col p-0">
                                                    <Input
                                                        disabled={!(currentUser?.rolId == EnumRol.Administrador || currentUser?.rolId == EnumRol.Comercial)}
                                                        autoCapitalLetter
                                                        placeholder={"Profesión"}
                                                        props={formik.getFieldProps('pareja.profesion')}
                                                        validProp={formik.touched.pareja?.profesion}
                                                        error={formik.errors.pareja?.profesion}
                                                    />
                                                </div>
                                                <div className="col p-0">
                                                    <Input
                                                        disabled={!(currentUser?.rolId == EnumRol.Administrador || currentUser?.rolId == EnumRol.Comercial)}
                                                        autoCapitalLetter
                                                        placeholder={"Nombre de la empresa"}
                                                        props={formik.getFieldProps('pareja.cargo')}
                                                        validProp={formik.touched.pareja?.cargo}
                                                        error={formik.errors.pareja?.cargo}
                                                    />
                                                </div>
                                            </div>
                                        </FormularioCampo>

                                        <FormularioCampo nombre="Celular" requerido>
                                            <div className="row p-0">
                                                <InputNumerico
                                                    disabled={!(currentUser?.rolId == EnumRol.Administrador || currentUser?.rolId == EnumRol.Comercial)}
                                                    validate
                                                    placeholder={"Celular *"}
                                                    props={formik.getFieldProps('pareja.contacto')}
                                                    validProp={formik.touched.pareja?.contacto}
                                                    error={formik.errors.pareja?.contacto}
                                                />
                                            </div>
                                        </FormularioCampo>

                                        <FormularioCampo nombre="Correo" requerido>
                                            <div className="row p-0">
                                                <Input
                                                    disabled={!(currentUser?.rolId == EnumRol.Administrador || currentUser?.rolId == EnumRol.Comercial)}
                                                    deleteSpaces
                                                    placeholder={"Correo *"}
                                                    props={formik.getFieldProps('pareja.correo')}
                                                    validProp={formik.touched.pareja?.correo}
                                                    error={formik.errors.pareja?.correo}
                                                />
                                            </div>
                                        </FormularioCampo>

                                        <FormularioCampo nombre="Municipio" requerido>
                                            <div className="row p-0">
                                                <DataList
                                                    disabled={!(currentUser?.rolId == EnumRol.Administrador || currentUser?.rolId == EnumRol.Comercial)}
                                                    placeholder="Municipio *"
                                                    defaultValue={
                                                        (tercero?.pareja && tercero.pareja.municipioId && tercero.pareja.municipioId != "")
                                                            ? municipiosObject[tercero.pareja.municipioId]?.nombreConjunto
                                                            : ""
                                                    }
                                                    options={municipios.map((m) => ({
                                                        nombre: m.nombreConjunto, valor: m.id, opcion: m.nombreConjunto
                                                    }))}
                                                    setFieldValue={formik.setFieldValue}
                                                    props={formik.getFieldProps('pareja.municipioId')}
                                                    validProp={formik.touched.pareja?.municipioId}
                                                    error={formik.errors.pareja?.municipioId}
                                                />
                                            </div>
                                        </FormularioCampo>

                                        <FormularioCampo nombre="Barrio">
                                            <div className="row p-0">
                                                <Input
                                                    disabled={!(currentUser?.rolId == EnumRol.Administrador || currentUser?.rolId == EnumRol.Comercial)}
                                                    placeholder={"Barrio"}
                                                    props={formik.getFieldProps('pareja.barrio')}
                                                    validProp={formik.touched.pareja?.barrio}
                                                    error={formik.errors.pareja?.barrio}
                                                />
                                            </div>
                                        </FormularioCampo>

                                        <FormularioCampo nombre="Dirección" requerido>
                                            <div className="row p-0">
                                                <Input
                                                    disabled={!(currentUser?.rolId == EnumRol.Administrador || currentUser?.rolId == EnumRol.Comercial)}
                                                    placeholder={"Dirección *"}
                                                    props={formik.getFieldProps('pareja.direccion')}
                                                    validProp={formik.touched.pareja?.direccion}
                                                    error={formik.errors.pareja?.direccion}
                                                />
                                            </div>
                                        </FormularioCampo>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            : <div></div>
    )
}