import { FC, useState, useEffect } from "react";
import { KTCard } from "../../../../_metronic/helpers";
import { AuditoriaServicio } from "../../../../servicios/auditoriaServicio";
import { ListViewProvider } from "../../../shared/providers/ListView/ListView";
import { AuditoriaUsuarioHeaderToolbar } from "./components/AuditoriaUsuarioHeader";
import { AuditoriaUsuarioLista } from "./components/AuditoriaUsuarioLista";
import { IAUsuario } from "../../../../interfaces/auditorias/IAUsuario";
import { ModalAuditoriaUProvider } from "./providers/ModalAuditoriaU";


export const AuditoriaUsuarios: FC = () => {

    const [aUsuarios, setAUsuarios] = useState<IAUsuario[]>([]);

    useEffect(() => {
        const obtenerAuditoriaUsuarios = async () => {
            const aUsuarios = await AuditoriaServicio.AuditoriaUsuario();
            setAUsuarios(aUsuarios);
        }
        obtenerAuditoriaUsuarios();
    }, []);

    return (
        <KTCard>
            <ListViewProvider initialData={aUsuarios} >
                <ModalAuditoriaUProvider>
                    <AuditoriaUsuarioHeaderToolbar />
                    <AuditoriaUsuarioLista />
                </ModalAuditoriaUProvider>
            </ListViewProvider>
        </KTCard>
    )
}
