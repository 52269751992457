import { FC } from "react"
import { InputInformativo } from "../../../../shared/misc/InputInformativo"
import { IAPropiedadTercero } from "../../../../../interfaces/auditorias/IAPropiedadTercero"

interface InfoAuditoriaPropiedadTProps {
    main?: IAPropiedadTercero | null
    target?: IAPropiedadTercero | null
    comparar?: boolean
}


export const InfoAuditoriaPropiedadT: FC<InfoAuditoriaPropiedadTProps> = ({
    main,
    target,
    comparar
}) => {

    return (
        <>
            <div className="col-12">
                <InputInformativo
                    nombre="Nombre de la propiedad"
                    icon="bi-building-fill"
                    text={main?.descripcion}
                    changed={(!comparar) ? false : main?.descripcion !== target?.descripcion}
                />
            </div>
            <InputInformativo
                nombre="Valor total"
                icon="bi-currency-dollar"
                text={main?.valorTotal ? main?.valorTotal.formatoMoneda2(true) : ""}
                changed={(!comparar) ? false : main?.valorTotal !== target?.valorTotal}
            />
            <InputInformativo
                nombre="Interés anual"
                icon="bi-graph-up"
                text={main?.interesAnual ? `${main?.interesAnual} %` : ""}
                changed={(!comparar) ? false : main?.interesAnual !== target?.interesAnual}
            />
            <InputInformativo
                nombre="Área m2"
                icon="bi-arrows-angle-expand"
                text={main?.areaPropiedad}
                changed={(!comparar) ? false : main?.areaPropiedad !== target?.areaPropiedad}
            />
            <InputInformativo
                nombre="Cantidad de pagos"
                icon="bi-cash-stack"
                text={main?.cantidadPagos ?? ""}
                changed={(!comparar) ? false : main?.cantidadPagos !== target?.cantidadPagos}
            />
        </>
    )
}