import { FC, MouseEventHandler } from "react"

interface BotonGuardarProps {
    onClick?: MouseEventHandler<HTMLButtonElement>
}

export const BotonGuardar: FC<BotonGuardarProps> = ({
    onClick
}) => {

    const style: React.CSSProperties = {
        width: "auto",
        position: "fixed",
        bottom: "15px", right: "15px", zIndex: "100"
    }

    return (
        <button onClick={onClick} type="button" className="btn btn-primary btn-guardar" style={style}>
            Guardar
        </button>
    )
} 
