import { FC, createContext, useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { WithChildren } from "../../../../_metronic/helpers";
import { IPropiedadCaracteristicas } from "../../../../interfaces/propiedades/IPropiedadCaracteristicas";
import { propiedadDatosIniciales } from "../../../shared/datosFormularios/DatosInicialesFormularios";
import { useFormik } from "formik";
import { propiedadConfiguracionSchema } from "../../../../validators/propiedadValidator";
import { PropiedadServicio } from "../../../../servicios/propiedadServicio";
import * as alertify from "alertifyjs";
import { useListView } from "../../../shared/providers/ListView/ListView";
import { ModalAsignarPropiedadProvider } from "./ModalAsignarPropiedad";
import { InputDecimal } from "../../../../_metronic/partials/formulario/InputDecimal";
import { InputMonetario } from "../../../../_metronic/partials/formulario/InputMonetario";
import { InputNoEditable } from "../../../../_metronic/partials/formulario/InputNoEditable";

type showModalType = (show: boolean, propiedad: IPropiedadCaracteristicas) => void
interface ModalPropiedadPros {
    showModal: showModalType
}

const ModalPropiedadIncial: ModalPropiedadPros = {
    showModal: () => { }
}

const ModalPropiedadContext = createContext<ModalPropiedadPros>(ModalPropiedadIncial)

const ModalPropiedadProvider: FC<WithChildren> = ({
    children
}) => {
    const [show, setShow] = useState(false);
    const [showModalAsignar, setShowModalAsignar] = useState(false);
    const [propiedad, setPropiedad] = useState<IPropiedadCaracteristicas>(propiedadDatosIniciales);


    const showModal: showModalType = (show, propiedad) => {
        setShow(show);
        setPropiedad(propiedad);
    }

    const { data, setData } = useListView();

    const onSave = (propiedad: Partial<IPropiedadCaracteristicas>) => {
        const index = data.findIndex((p: Partial<IPropiedadCaracteristicas>) => p.id == propiedad.id);
        if (index != -1) {
            data[index] = propiedad;
            setData([...data]);
        }
    }

    const formik = useFormik<IPropiedadCaracteristicas>({
        initialValues: propiedad,
        enableReinitialize: true,
        validationSchema: propiedadConfiguracionSchema,
        onSubmit: async (values) => {
            try {
                await PropiedadServicio.Actualizar(values);
                onSave(values);
                setShow(false);
                alertify.success("Propiedad guardada.");
            } catch (err) {
                alertify.error("No se pudo guardar la propiedad.");
            }
        }
    })

    useEffect(() => {
        formik.setValues({ ...propiedad });
    }, [propiedad]);

    const abrirModalAsignar = () => {
        setShow(false);
        setShowModalAsignar(true);
    }

    return (
        <ModalPropiedadContext.Provider value={{
            showModal
        }}>
            {children}
            {
                show &&
                <Modal show={show} className="fade modal">
                    <form
                        className="form"
                        onSubmit={formik.handleSubmit}
                        noValidate
                    >
                        <Modal.Header className="p-4">
                            <h2 className="modal-title">Propiedad</h2>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                aria-label="Close"
                                onClick={() => setShow(false)}
                            >
                                <i className="bi bi-x-lg"></i>
                            </div>
                        </Modal.Header>
                        <Modal.Body className="bg-gray-300 px-6 py-4">
                            {/* begin::modal */}
                            <div className="row g-2">
                                <div className="col-12">
                                    <div className="row p-4 row-cols-1 bg-light border rounded-3">
                                        <InputNoEditable
                                            nombre="Nombre propiedad"
                                            value={formik.values.descripcion}
                                            disabled
                                        />
                                        <InputDecimal
                                            requerido
                                            validate
                                            nombre="Área m2"
                                            placeholder="Área m2"
                                            setFieldValue={formik.setFieldValue}
                                            props={formik.getFieldProps("areaPropiedad")}
                                            validProp={formik?.touched.areaPropiedad}
                                            error={formik?.errors.areaPropiedad}
                                        />
                                        <InputMonetario
                                            requerido
                                            validate
                                            setFieldValue={formik.setFieldValue}
                                            nombre="Valor Total"
                                            placeholder="Valor Total"
                                            props={formik.getFieldProps("valorTotal")}
                                            validProp={formik?.touched.valorTotal}
                                            error={formik?.errors.valorTotal}
                                        />
                                        <InputNoEditable
                                            nombre="Valor m2"
                                            value={(formik.values.areaPropiedad == 0 ? 0 : formik.values.valorTotal / formik.values.areaPropiedad).formatoMoneda2(true)}
                                        />
                                        <InputDecimal
                                            requerido
                                            validate
                                            nombre="Interés anual %"
                                            placeholder="Interés anual %"
                                            setFieldValue={formik.setFieldValue}
                                            props={formik.getFieldProps("interesAnual")}
                                            validProp={formik?.touched.interesAnual}
                                            error={formik?.errors.interesAnual}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* end::modal */}
                        </Modal.Body>
                        <Modal.Footer className="p-4 d-flex">
                            {
                                propiedad.tercero == null && (
                                    <button type='button' className='btn btn-sm btn-light-primary me-auto' onClick={abrirModalAsignar}>
                                        <i className="fs-2 bi bi-person-fill-add"></i>
                                        Asignar propiedad
                                    </button>
                                )
                            }
                            <button
                                type="button"
                                className="btn btn-sm btn-light"
                                onClick={() => setShow(false)}
                            >
                                Cancelar
                            </button>
                            <button type="submit" className="btn btn-sm btn-primary">
                                Guardar
                            </button>
                        </Modal.Footer>
                    </form>
                </Modal >
            }
            
            <ModalAsignarPropiedadProvider
                propiedad={formik.values}
                show={showModalAsignar}
                setShow={setShowModalAsignar}
                onClose={() => {
                    setShow(true);
                    setShowModalAsignar(false);
                }}
                onSave={(propiedad: IPropiedadCaracteristicas) => {
                    const index = data.findIndex((p: Partial<IPropiedadCaracteristicas>) => p.id == propiedad.id);
                    if (index != -1) {
                        data[index] = propiedad;
                        setData([...data]);
                    }
                }}
            />
        </ModalPropiedadContext.Provider>
    )
}

const useModalPropiedad = () => useContext(ModalPropiedadContext);

export {
    ModalPropiedadProvider,
    useModalPropiedad
}
