import { FC, useEffect } from "react";

interface BotonEditarTerceroProps {
    onClick: ()=>void
    style?: React.CSSProperties
    disabled?: boolean
    icon: string,
    tooltip?: string,
    color?: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark" | "body" | "muted" | "white" | "black-50" | "white-50"
}

const btnStyle: React.CSSProperties = {
    width: "32px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
} 

export const BotonIcono: FC<BotonEditarTerceroProps> = ({
    onClick: eliminar,
    style,
    disabled=false,
    icon,
    tooltip,
    color = "danger"
}) => {

    return (
        <button 
            data-tooltip-id="tooltip" data-tooltip-content={tooltip}
            disabled={disabled}
            onClick={eliminar}
            type='button'
            className={`btn btn-sm btn-${color}`}
            style={{...btnStyle, ...style}}
        >
            <i className={`bi ${icon} p-0`}></i>
        </button>
    )
} 
