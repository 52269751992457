import { FC, createContext, useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { WithChildren } from "../../../../../_metronic/helpers";
import { IAPago } from "../../../../../interfaces/auditorias/IAPago";
import { InfoAuditoriaPago } from "../utils/InfoAuditoriaPago";

type showModalType = (show: boolean, auditoriaPago: IAPago) => void
interface ModalAuditoriaPagoPros {
    showModal: showModalType
}

const ModalAuditoriaPagoIncial: ModalAuditoriaPagoPros = {
    showModal: () => { }
}

const ModalAuditoriaPagoContext = createContext<ModalAuditoriaPagoPros>(ModalAuditoriaPagoIncial)

const ModalAuditoriaPagoProvider: FC<WithChildren> = ({
    children
}) => {
    const [show, setShow] = useState(false);
    const [aPago, setAPago] = useState<IAPago | null>(null);


    const showModal: showModalType = (show, auditoriaPago) => {
        setShow(show);
        setAPago(auditoriaPago);
    }

    return (
        <ModalAuditoriaPagoContext.Provider value={{
            showModal
        }}>
            {children}
            {
                show &&
                <Modal show={show} className={`fade modal`}>
                    <form
                        className="form"
                        noValidate
                    >
                        <Modal.Header className="p-4">
                            {aPago === null ? (
                                <div data-kt-indicator='on'>
                                    <span className='indicator-progress'>
                                        <span className='spinner-border spinner-border-sm align-middle'></span>
                                    </span>
                                </div>
                            ) : (
                                <h2 className="modal-title">
                                    {aPago.aNombreUsuario}
                                </h2>
                            )}
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                aria-label="Close"
                                onClick={() => setShow(false)}
                            >
                                <i className="fs-2 bi bi-x-lg" />
                            </div>
                        </Modal.Header>
                        <Modal.Body className="bg-gray-300 px-6 py-4">
                            <div className="row g-10">
                                <div className="col-12">
                                    <div className="row p-4 row-cols-1 bg-light border rounded-3">
                                        <div className="col-12 p-0 px-5 rounded mb-2">
                                            <h1 className="align-middle">Insertado</h1>
                                        </div>
                                        <div className="row row-cols-2">
                                            {aPago === null ? (
                                                <div data-kt-indicator='on'>
                                                    <span className='indicator-progress'>
                                                        <span className='spinner-border spinner-border-sm align-middle'></span>
                                                    </span>
                                                </div>
                                            ) : (
                                                <InfoAuditoriaPago
                                                    pago={aPago}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="p-4">

                        </Modal.Footer>
                    </form>
                </Modal >
            }
        </ModalAuditoriaPagoContext.Provider>
    )
}

const useModalAuditoriaPago = () => useContext(ModalAuditoriaPagoContext);

export {
    ModalAuditoriaPagoProvider,
    useModalAuditoriaPago
}
