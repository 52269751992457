import { FC, useEffect, useState } from "react";
import { ListViewProvider } from "../../../shared/providers/ListView/ListView";
import { KTCard } from "../../../../_metronic/helpers";
import { IUsuario } from "../../../../interfaces/IUsuario";
import { UsuarioServicios } from "../../../../servicios/usuarioServicio";
import { UsuariosManagamentBody } from "./components/body/UsuariosManagamentBody";
import { UsuariosManagamentHeader } from "./components/header/UsuariosManagamentHeader";

export const UsuariosManagament: FC = () => {

    const [usuarios, setUsuarios] = useState<IUsuario[]>([]);

    useEffect(() => {
        const obtenerTerceros = async () => {
            const _usuarios = await UsuarioServicios.ObtenerTodos();
            setUsuarios(_usuarios);
        }
        obtenerTerceros();
    }, []);

    return (
        <KTCard>
            <ListViewProvider initialData={usuarios} >
                <UsuariosManagamentHeader />
                <UsuariosManagamentBody />
            </ListViewProvider>
        </KTCard>
    )
}