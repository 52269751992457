import { FC, createContext, useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { WithChildren } from "../../../../../_metronic/helpers";
import { IAAnterior } from "../../../../../interfaces/auditorias/IAAnterior";
import { AuditoriaServicio } from "../../../../../servicios/auditoriaServicio";
import { InfoAuditoriaUsuario } from "../utils/InfoUsuario";
import { IAUsuario } from "../../../../../interfaces/auditorias/IAUsuario";

type showModalType = (show: boolean, idAutiroria: string) => void
interface ModalAuditoriaUPros {
    showModal: showModalType
}

const ModalAuditoriaUIncial: ModalAuditoriaUPros = {
    showModal: () => { }
}

const ModalAuditoriaUContext = createContext<ModalAuditoriaUPros>(ModalAuditoriaUIncial)

const ModalAuditoriaUProvider: FC<WithChildren> = ({
    children
}) => {
    const [show, setShow] = useState(false);
    const [aUsuario, setAUsuario] = useState<IAAnterior<IAUsuario> | null>(null);

    const obtenerAuditoria = async (idAutiroria: string) => {
        const auditoria = await AuditoriaServicio.AuditoriaUsuarioAnterior(idAutiroria);
        setAUsuario(auditoria);
    }

    const showModal: showModalType = (show, idAutiroria) => {
        setShow(show);
        obtenerAuditoria(idAutiroria);
    }

    return (
        <ModalAuditoriaUContext.Provider value={{
            showModal
        }}>
            {children}
            {
                show &&
                <Modal show={show} className={`fade modal ${aUsuario?.actual?.aTipoAccion === "Insertar" ? "" : "modal-xl"}`}>
                    <form
                        className="form"
                        noValidate
                    >
                        <Modal.Header className="p-4">
                            {aUsuario === null ? (
                                <div data-kt-indicator='on'>
                                    <span className='indicator-progress'>
                                        <span className='spinner-border spinner-border-sm align-middle'></span>
                                    </span>
                                </div>
                            ) : (
                                <h2 className="modal-title">
                                    {aUsuario.actual?.nombre}
                                </h2>
                            )}
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                aria-label="Close"
                                onClick={() => setShow(false)}
                            >
                                <i className="fs-2 bi bi-x-lg"/>
                            </div>
                        </Modal.Header>
                        <Modal.Body className="bg-gray-300 px-6 py-4">
                            {/* begin::modal */}
                            <div className="row g-10">
                                {
                                    (aUsuario?.actual?.aTipoAccion === "Insertar") ? (
                                        <div className="col-12">
                                            <div className="row p-4 row-cols-1 bg-light border rounded-3">
                                                <div className="col-12 p-0 px-5 rounded mb-2">
                                                    <h1 className="align-middle">Insertado</h1>
                                                </div>
                                                <div className="row row-cols-2">
                                                    <InfoAuditoriaUsuario
                                                        main={aUsuario?.actual}
                                                        comparar={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="col-6">
                                                <div className="row p-4 row-cols-1 bg-light border rounded-3">
                                                    <div className="col-12 p-0 px-5 rounded mb-2">
                                                        <h1 className="align-middle">Anterior</h1>
                                                    </div>
                                                    <div className="row row-cols-2">
                                                        <InfoAuditoriaUsuario
                                                            main={aUsuario?.anterior}
                                                            target={aUsuario?.actual}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-6">
                                                <div className="row p-4 row-cols-1 bg-light border rounded-3">
                                                    <div className="col-12 p-0 px-5 rounded mb-2">
                                                        <h1 className="align-middle">Nuevo</h1>
                                                    </div>
                                                    <div className="row row-cols-2">
                                                        <InfoAuditoriaUsuario
                                                            main={aUsuario?.actual}
                                                            target={aUsuario?.anterior}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>

                                    )
                                }
                            </div>
                            {/* end::modal */}
                        </Modal.Body>
                        <Modal.Footer className="p-4">

                        </Modal.Footer>
                    </form>
                </Modal >
            }
        </ModalAuditoriaUContext.Provider>
    )
}

const useModalAuditoriaU = () => useContext(ModalAuditoriaUContext);

export {
    ModalAuditoriaUProvider,
    useModalAuditoriaU
}
