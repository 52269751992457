import { saveAs } from 'file-saver';
import { b64toBlob } from '../Files';
export { }

declare global {
    interface String {
        formatoMoneda(currency?:boolean): string;
        formatoMoneda2(currency?:boolean): string;
        isNumeric(): boolean;
        descargarB64(nombre: string): boolean;
        normalizar(lower?: boolean): string;
    }
}

String.prototype.formatoMoneda = function (currency): string {
    const opciones = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    };
    return (parseFloat(this.toString()) || 0).toLocaleString("es-ES", opciones) + ((currency) ? "$" : "");
}

String.prototype.formatoMoneda2 = function (currency): string {
    const opciones = {
        maximumFractionDigits: 0,
    };
    return ((currency) ? "$ " : "") + (parseFloat(this.toString()) || 0).toLocaleString("es-ES", opciones);
}

String.prototype.isNumeric = function() {
    return /^\d+$/.test(this.toString());
}

String.prototype.descargarB64 = function (nombre: string) {
    try{
        saveAs(b64toBlob(this.toString()), nombre);
        return true;
    }catch(err){
        return false;
    }
}

String.prototype.normalizar = function (lower?: boolean): string {
    const n = this.normalize('NFD')
                    .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1")
                    .normalize();
    if(lower) return n.toLocaleLowerCase();
    else return n;
}