import { PageLink, PageTitle } from "../../_metronic/layout/core"
import { Propiedad } from "../modules/Cartera/Propiedad"

const usersBreadcrumbs: PageLink[] = []

export const PropiedadWrapper = () => {
    return(
        <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>Usuarios</PageTitle>
            <Propiedad />
        </>
    )
}
