import * as Yup from 'yup'

const datosPersonalesSchema = {
    tipoDocumentoId: Yup.number()
        .required('Campo requerido.'),
    identificacion: Yup.string()
        .max(50, 'Máximo 50 caracteres.')
        .required('Campo requerido.'),
    primerNombre: Yup.string()
        .min(3, 'Mínimo 3 caracteres.')
        .max(50, 'Máximo 50 caracteres.')
        .required('Campo requerido.'),
    otrosNombres: Yup.string()
        .min(3, 'Mínimo 3 caracteres.')
        .max(50, 'Máximo 50 caracteres.'),
    primerApellido: Yup.string()
        .min(3, 'Mínimo 3 caracteres.')
        .max(50, 'Máximo 50 caracteres.')
        .required('Campo requerido.'),
    segundoApellido: Yup.string()
        .min(3, 'Mínimo 3 caracteres.')
        .max(50, 'Máximo 50 caracteres.'),
    profesion: Yup.string()
        .max(50, 'Máximo 50 caracteres.')
        .nullable(),
    cargo: Yup.string()
        .max(50, 'Máximo 50 caracteres.')
        .nullable(),
    municipioId: Yup.string()
        .required('Campo requerido.'),
    barrio: Yup.string()
        .max(50, 'Máximo 50 caracteres.'),
    direccion: Yup.string()
        .max(50, 'Máximo 50 caracteres.')
        .required('Campo requerido.'),
    contacto: Yup.string()
        .matches(/^[0-9]+$/, "Numero celular invalido.")
        .required('Campo requerido.'),
    correo: Yup.string()
        .email('Correo invalido.')
        .min(3, 'Mínimo 3 caracteres.')
        .max(50, 'Máximo 50 caracteres.')
        .required('Campo requerido.'),
    estadoId: Yup.string(),
}

export const terceroCrearSchema = Yup.object().shape({
    ...datosPersonalesSchema,
    nombreComercial: Yup.string()
        .min(3, 'Mínimo 3 caracteres.')
        .max(50, 'Máximo 50 caracteres.'),
    estadoCivilId: Yup.number()
        .required('Campo requerido.'),
    codigoActividad: Yup.string(),
    contactoWhatsapp: Yup.string()
        .matches(/^[0-9]+$/, "Numero celular invalido.")
        .required('Campo requerido.'),
    alias: Yup.string()
        .min(3, 'Mínimo 3 caracteres.')
        .max(50, 'Máximo 50 caracteres.'),
    hijos: Yup.array()
        .of(Yup.object().shape({
            nombre: Yup.string()
                .max(50, 'Máximo 50 caracteres.')
                .required('Campo requerido.'),
            colegio: Yup.string()
                .max(50, 'Máximo 50 caracteres.')
                .required('Campo requerido.'),
            fechaNacimiento: Yup.date()
                .required('Campo requerido.'),
        })),
    mascotas: Yup.array()
        .of(Yup.object().shape({
            nombre: Yup.string()
                .max(50, 'Máximo 50 caracteres.')
                .required('Campo requerido.'),
            mascota: Yup.string()
                .max(50, 'Máximo 50 caracteres.')
                .required('Campo requerido.'),
            raza: Yup.string()
                .max(50, 'Máximo 50 caracteres.')
                .required('Campo requerido.')
        })),
    pareja: Yup.object().shape(datosPersonalesSchema).default(null).nullable()
});