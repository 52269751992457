import { FC } from "react"

interface RolProps {
    rolId: number
    className?: string
}

export const Rol: FC<RolProps> = ({
    rolId,
    className
}) => {
    switch (rolId) {
        case 1:
            return (
                <button className={`btn btn-sm nobtn-light-info fw-bolder ms-2 py-1 px-3 ${className}`}>
                    Administrador
                </button>
            )
        case 2:
            return (
                <button className={`btn btn-sm nobtn-light-warning fw-bolder ms-2 py-1 px-3 ${className}`}>
                    Visor
                </button>
            )
        case 3:
            return (
                <button className={`btn btn-sm nobtn-light-primary fw-bolder ms-2 py-1 px-3 ${className}`}>
                    Cliente
                </button>
            )
        case 4:
            return (
                <button className={`btn btn-sm nobtn-light-danger fw-bolder ms-2 py-1 px-3 ${className}`}>
                    Comercial
                </button>
            )
        case 5:
            return (
                <button className={`btn btn-sm nobtn-light-success fw-bolder ms-2 py-1 px-3 ${className}`}>
                    Super Admin
                </button>
            )
        default:
            return (
                <button className={`btn btn-sm nobtn-light-danger fw-bolder ms-2 py-1 px-3 ${className}`}>
                    N/A
                </button>
            )
    }
}