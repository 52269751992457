import { FC } from "react"
import { InputInformativo } from "../../../../shared/misc/InputInformativo"
import { IAPropiedadCaracteristica } from "../../../../../interfaces/auditorias/IAPropiedadCaracteristica"

interface InfoAuditoriaPropiedadCProps {
    main: IAPropiedadCaracteristica | null | undefined
    target: IAPropiedadCaracteristica | null | undefined
}

export const InfoAuditoriaPropiedadC: FC<InfoAuditoriaPropiedadCProps> = ({
    main: main,
    target: target
}) => {

    return (
        <>
            <div className="col-12">
                <InputInformativo
                    nombre="Nombre de la propiedad"
                    icon="bi-building-fill"
                    text={main?.descripcionPropiedad}
                    changed={main?.descripcionPropiedad !== target?.descripcionPropiedad}
                />
            </div>
            <InputInformativo
                nombre="Valor total"
                icon="bi-currency-dollar"
                text={main?.valorTotal ? main?.valorTotal.formatoMoneda2(true) : ""}
                changed={main?.valorTotal !== target?.valorTotal}
            />
            <InputInformativo
                nombre="Área m2"
                icon="bi-arrows-angle-expand"
                text={main?.areaPropiedad}
                changed={main?.areaPropiedad !== target?.areaPropiedad}
            />
            <InputInformativo
                nombre="Interés anual"
                icon="bi-graph-up"
                text={main?.interesAnual ? `${main?.interesAnual} %` : ""}
                changed={main?.interesAnual !== target?.interesAnual}
            />
        </>
    )
}