import { FC } from "react";
import { ListaUsuarios } from "./ListaUsuarios";

export const UsuariosManagamentBody: FC = () => {
    
    return(
        <>
            <ListaUsuarios/>
        </>
    )
}
