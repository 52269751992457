import { IInformeArea } from "../interfaces/informes/IInformeArea";
import { IInformeCuenta } from "../interfaces/informes/IInformeCuenta";
import { IInformeEdades } from "../interfaces/informes/IInformeEdades";
import { IInformeValoresTotales } from "../interfaces/informes/IInformeValoresTotales";
import { IPagoInforme } from "../interfaces/pago/IPagoInforme";
import { IPropiedadCaracteristicas } from "../interfaces/propiedades/IPropiedadCaracteristicas";
import { axios } from "./axiosInstance";

export type InformePagosProps = { 
    terceroId: string | null,
    fechaFinal: Date | string | null, 
    estadoPagoId: number | null
}

export class InformeServicio {

    public static InformePlanPagosPdf = (propiedadid: string): Promise<string> => {
        return new Promise(async (res, rej) => {
            try {
                var _res = await axios.get<string>(`informe/pdf?propiedadTerceroId=${propiedadid}`);
                res(_res);
            } catch (err) {
                rej(err);
            }
        });
    }

    public static Excel = (): Promise<void> => {
        return new Promise(async (res, rej) => {
            try {
                await axios.get<IPropiedadCaracteristicas[]>('/informe/excel')
                res();
            } catch (err) {
                rej(err);
            }
        });
    }

    public static InformeEdades = (terceroId: string, estadoPago: number): Promise<IInformeEdades[]> => {
        return new Promise(async (res, rej) => {
            try {
                var _res = await axios.get<IInformeEdades[]>(`/informe/informe-edades?TerceroId=${terceroId}&EstadoCuota=${estadoPago}`);
                _res.map((c, i, a) => {
                    a[i].fecha = new Date(c.fecha);
                });
                _res.sort((a, b) => b.dias - a.dias);
                res(_res);
            } catch (err) {
                res([]);
            }
        });
    }

    public static InformeCuenta = (fechaFinal: string | null, estadoPago: number): Promise<IInformeCuenta[]> => {
        return new Promise(async (res, rej) => {
            try {

                var query = `EstadoCuota=${estadoPago}`;

                if (fechaFinal) query += `&FechaFinal=${fechaFinal}`;

                var _res = await axios.get<IInformeCuenta[]>(`/informe/informe-cuenta?${query}`);
                _res.map((c, i, a) => {
                    a[i].fecha = new Date(c.fecha);
                });
                _res.sort((a, b) => a.nombre.localeCompare(b.nombre) || a.fecha.getTime() - b.fecha.getTime());
                res(_res);
            } catch (err) {
                res([]);
            }
        });
    }

    public static InformePagos = ({
        terceroId, fechaFinal, estadoPagoId
    }: InformePagosProps): Promise<IPagoInforme[]> => {
        return new Promise(async (res, rej) => {
            try {
                const query = [
                    (terceroId ? `TerceroId=${terceroId}` : ""),
                    (fechaFinal ? `FechaFinal=${fechaFinal.toString()}` : ""),
                    (estadoPagoId ? `EstadoPagoId=${estadoPagoId}` : "")
                ].filter(q => q != "").join("&");

                var _res = await axios.get<IPagoInforme[]>(`/informe/informe-pagos?${query}`);
                _res.map((c, i, a) => {
                    a[i].fecha = new Date(c.fecha);
                });
                //_res.sort((a, b) => a.nombre.localeCompare(b.nombre) || a.fecha.getTime() - b.fecha.getTime());
                res(_res);
            } catch (err) {
                res([]);
            }
        });
    }

    public static InformeGraficoTotales = (): Promise<IInformeValoresTotales | null> => {
        return new Promise(async (res) => {
            try {
                var _res = await axios.get<IInformeValoresTotales>('/informe/grafico-totales')
                res(_res);
            } catch (err) {
                res(null);
            }
        });
    }

    public static InformePorArea = (): Promise<IInformeArea[]> => {
        return new Promise(async (res) => {
            try {
                var _res = await axios.get<IInformeArea[]>('/informe/por-area')
                res(_res);
            } catch (err) {
                res([]);
            }
        });
    }

}