import { useEffect, useState } from "react"
import { InformeServicio } from "../../../../servicios/informeServicio";
import { totalInformeType } from "../../../../_metronic/partials/widgets/cards/CardsWidget17";
import { DataType } from "../../../../_metronic/partials/widgets/charts/ChartsWidget2";

type totalVendidosType = { total: number, vendidas: number };
const totalVendidosInicial: totalVendidosType = {
    total: 0,
    vendidas: 0
}

type totalVentasType = { total: number, data: totalInformeType[] }
const totalVentasInicial: totalVentasType = {
    total: 0,
    data: [
        { nombre: "Total Capital", valor: 0, color: "#D2FFAF" },
        { nombre: "Total Deuda", valor: 0, color: "#FFEFAF" },
        { nombre: "Total Mora", valor: 0, color: "#AFE2FF" },
        { nombre: "Total Recibdo", valor: 0, color: "#FFBCAF" },
    ]
}

type informeAreaType = { labels: string[], data: DataType[] };
const informeAreaInicial: informeAreaType = {
    labels: [],
    data: []
}

type useDashboardType = () => {
    status: {
        propiedades: statusDashboard;
        totalVentas: statusDashboard;
        informesArea: statusDashboard;
    },
    totalLotesVendidos: totalVendidosType,
    informeArea: informeAreaType,
    totalVentas: totalVentasType
}

export enum statusDashboard {
    cargando,
    cargado,
    fallo
}

export const useDashboard: useDashboardType = () => {

    const [status, setStatus] = useState({
        propiedades: statusDashboard.cargando,
        totalVentas: statusDashboard.cargando,
        informesArea: statusDashboard.cargando
    })

    const [totalLotesVendidos, setTotalLotesVendidos] = useState(totalVendidosInicial);
    const [totalVentas, setTotalVentas] = useState(totalVentasInicial);
    const [informeArea, setInformeArea] = useState(informeAreaInicial);

    useEffect(() => {

        InformeServicio.InformeGraficoTotales().then(totalVentas => {
            if (totalVentas) {
                var _totalVentas: { total: number, data: totalInformeType[] } = {
                    total: totalVentas.totalVentas,
                    data: [
                        { nombre: "Total Venta", valor: totalVentas.totalVentas, color: "#7A94FF" },
                        { nombre: "Total Deuda", valor: totalVentas.totalDeuda, color: "#FFE37A" },
                        { nombre: "Total Mora", valor: totalVentas.totalMora, color: "#FF7A7A" },
                        { nombre: "Total Recibido", valor: totalVentas.totalRecibido, color: "#7AFF94" },
                    ]
                }
                setTotalVentas({ ..._totalVentas });
                setStatus(s => ({ ...s, totalVentas: statusDashboard.cargado }));
            } else {
                setStatus(s => ({ ...s, totalVentas: statusDashboard.fallo }));
            }
        });

        InformeServicio.InformePorArea().then(areas => {
            const labels: string[] = [];
            const vendidos: number[] = [];
            const cantidad: number[] = [];

            areas.forEach(area => {
                labels.push(`${area.areaPropiedad.toString()} M2`);
                vendidos.push(area.vendidos);
                cantidad.push(area.cantidad);
            });
            
            const info: informeAreaType = {
                data: [
                    {
                        name: "Lotes vendidos",
                        data: vendidos
                    }, {
                        name: "Total Lotes",
                        data: cantidad
                    }
                ],
                labels
            }

            const total: totalVendidosType = {
                total: cantidad.reduce((p, c) => p + c, 0),
                vendidas: vendidos.reduce((p, c) => p + c, 0),
            }

            setInformeArea({...info});
            setTotalLotesVendidos({...total});
            setStatus(s => ({ ...s, informesArea: statusDashboard.cargado, propiedades: statusDashboard.cargado }));
        });

    }, []);

    return {
        status,
        totalLotesVendidos,
        totalVentas,
        informeArea
    };
}