import { FC, MouseEventHandler } from "react";
import { useLayout } from "../../../_metronic/layout/core";
import clsx from "clsx";

interface BotonActualizarProps {
    onClick?: MouseEventHandler<HTMLButtonElement>
}

export const BotonActualizar: FC<BotonActualizarProps> = ({
    onClick
}) => {

    const { config } = useLayout()
    const daterangepickerButtonClass = config.app?.toolbar?.fixed?.desktop
        ? 'btn-light'
        : 'bg-body btn-color-gray-700 btn-active-color-primary'

    return (
        <button type='button' className={clsx('btn btn-sm btn-flex fw-bold', daterangepickerButtonClass)} onClick={onClick}>
            <i className="fs-2 bi bi-arrow-counterclockwise"></i>
            Actualizar
        </button>
    )
}