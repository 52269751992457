/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef } from 'react'
import { getCSSVariableValue } from '../../../assets/ts/_utils'
import { KTIcon } from '../../../helpers'
import { useThemeMode } from '../../layout/theme-mode/ThemeModeProvider'

export type totalInformeType = { color: string, nombre: string, valor: number };

type Props = {
  className: string
  chartSize?: number
  chartLine?: number
  chartRotate?: number
  total?: number
  data?: totalInformeType[]
  title?: string
}



const CardsWidget17: FC<Props> = ({
  className,
  title,
  chartSize = 70,
  chartLine = 11,
  chartRotate = 145,
  data = [],
  total = 0
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode();

  useEffect(() => {
    refreshChart()
  }, [mode, total, data])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    setTimeout(() => {
      initChart(chartSize, chartLine, chartRotate, total, data)
    }, 10)
  }

  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <span className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>$</span>

            <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{total.formatoMoneda2()}</span>

          </div>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>{title}</span>
        </div>
      </div>

      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
        <div className='d-flex flex-center me-5 pt-2'>
          <div
            id='kt_card_widget_17_chart'
            ref={chartRef}
            style={{ minWidth: chartSize + 'px', minHeight: chartSize + 'px' }}
            data-kt-size={chartSize}
            data-kt-line={chartLine}
          ></div>
        </div>

        <div className='d-flex flex-column content-justify-center flex-row-fluid'>
          {
            data.map((data, i) => (
              <div key={i} className='d-flex fw-semibold align-items-center'>
                <div className={`bullet w-8px h-5px rounded-2 me-3`} style={{backgroundColor: data.color}}></div>
                <div className='text-gray-500 flex-grow-1 me-4'>{data.nombre}</div>
                <div className='fw-bolder text-gray-700 text-xxl-end'>{data.valor.formatoMoneda2(true)}</div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

const initChart = function (
  chartSize: number = 70,
  chartLine: number = 11,
  chartRotate: number = 145,
  total: number,
  data: totalInformeType[]
) {
  const el = document.getElementById('kt_card_widget_17_chart')
  if (!el) {
    return
  }
  el.innerHTML = ''

  var options = {
    size: chartSize,
    lineWidth: chartLine,
    rotate: chartRotate,
    //percent:  el.getAttribute('data-kt-percent') ,
  }

  const canvas = document.createElement('canvas')
  const span = document.createElement('span')

  // @ts-ignore
  if (typeof G_vmlCanvasManager !== 'undefined') {
    // @ts-ignore
    G_vmlCanvasManager.initElement(canvas)
  }

  const ctx = canvas.getContext('2d')
  canvas.width = canvas.height = options.size

  el.appendChild(span)
  el.appendChild(canvas)

  // @ts-ignore
  ctx.translate(options.size / 2, options.size / 2) // change center
  // @ts-ignore
  ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

  //imd = ctx.getImageData(0, 0, 240, 240);
  const radius = (options.size - options.lineWidth) / 2

  const drawCircle = function (color: string, lineWidth: number, percent: number) {
    percent = Math.min(Math.max(0, percent || 1), 1)
    if (!ctx) {
      return
    }

    ctx.beginPath()
    //ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
    //(x: number, y: number, radiusX: number, radiusY: number, rotation: number, startAngle: number, endAngle: number, counterclockwise?: boolean | undefined)
    ctx.ellipse(0 , 0, radius, radius, 0, 0, Math.PI * 2 * percent);
    ctx.strokeStyle = color
    ctx.lineCap = 'round' // butt, round or square
    ctx.lineWidth = lineWidth
    ctx.stroke()
  }

  // Init 2
  //console.log(total)

  const _total = data.reduce((p, c) => p + c.valor, 0);
  let porcentajeAcumulado = 1;
  data.map(data => {
    const porcentaje = ((100 * data.valor) / _total) / 100;
    drawCircle(data.color, options.lineWidth, porcentajeAcumulado);
    porcentajeAcumulado -= porcentaje; 
  });

/*
  drawCircle(getCSSVariableValue('--bs-danger'), options.lineWidth, 0.25);
  drawCircle(getCSSVariableValue('--bs-success'), options.lineWidth, 0.50);
  drawCircle(getCSSVariableValue('--bs-primary'), options.lineWidth, 0.75);*/
}

export { CardsWidget17 }
