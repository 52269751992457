
import clsx from "clsx";
import { FC, useState } from "react";
import { KTIcon } from "../../../../_metronic/helpers";
import { useLayout } from "../../../../_metronic/layout/core";
import { acciones } from "../../../shared/datosFormularios/Informacion";
import { useListView } from "../../../shared/providers/ListView/ListView";
import { Accion } from "../../../shared/misc/BotonesAttributos";

interface AuditoriaDropDownFilterProps {
    filtros?: string[]
    filtroNombre?: string
}

export const DropDownAccionFilter: FC<AuditoriaDropDownFilterProps> = ({
    filtros = [],
    filtroNombre
}) => {

    const { config } = useLayout()
    const daterangepickerButtonClass = config.app?.toolbar?.fixed?.desktop
        ? 'btn-light'
        : 'bg-body btn-color-gray-700 btn-active-color-primary'
        
    const { addFilter, removeFilters } = useListView();

    const [accion, setAccion] = useState("0");

    const cambiarFiltros = () => {
        if (accion === "0") removeFilters("aTipoAccion");
        else addFilter("aTipoAccion", accion);
    }

    const quitarFiltros = () => {
        setAccion("0");
    }

    return (
        <div className='menu menu-sub menu-sub-dropdown w-200px w-md-250px' data-kt-menu='true'>
            <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Filtros</div>
            </div>

            <div className='separator border-gray-200'></div>

            <div className='px-7 py-5'>
                <div className='mb-auto'>
                    <label className='form-label fw-bold'>{filtroNombre}:</label>

                    <div className='fv-row mb-2 col p-1'>
                        <div className="form-check">
                            {
                                acciones.filter(a => filtros.includes(a.nombre)).map((_rol, i) => (
                                    <div className="mb-2" key={i}>
                                        <input
                                            onChange={(e) => setAccion(e.target.value)}
                                            checked={accion == _rol.valor}
                                            name="filter_accion"
                                            className="form-check-input"
                                            type="radio"
                                            value={_rol.valor}
                                        />
                                        <Accion accion={_rol} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className='d-flex justify-content-end'>
                    <button type='button' className={clsx('btn btn-sm btn-light btn-active-light-primary me-2', daterangepickerButtonClass)} onClick={quitarFiltros}>
                        <KTIcon iconName='arrow-circle-right' className='fs-1' />
                        Limpiar
                    </button>
                    <button
                        type='submit'
                        className='btn btn-sm btn-primary'
                        data-kt-menu-dismiss='true'
                        onClick={cambiarFiltros}
                    >
                        Aplicar
                    </button>
                </div>
            </div>
        </div>
    )
}