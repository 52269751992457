import { FC, useEffect, useState } from "react";
import { ListViewProvider } from "../../../shared/providers/ListView/ListView";
import { KTCard } from "../../../../_metronic/helpers";
import { InformePagosHeader } from "./components/InformePagosHeader";
import { InformePagosLista } from "./components/InformePagosLista";
import { InformeServicio } from "../../../../servicios/informeServicio";
import { IPagoInforme } from "../../../../interfaces/pago/IPagoInforme";

export const InformePagos: FC = () => {

    const [initalData, setInitialData] = useState<IPagoInforme[]>([]);

    useEffect(() => {
        InformeServicio.InformePagos({
            estadoPagoId: null, fechaFinal: null, terceroId: null
        })
            .then((pagos) => setInitialData([...pagos]));
    }, [])

    return (
        <KTCard>
            <ListViewProvider initialData={initalData} >
                <InformePagosHeader />
                <InformePagosLista />
            </ListViewProvider>
        </KTCard>
    )
}
